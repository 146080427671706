import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { DatePipe } from '@angular/common';

import { AppConfig } from '../config/app.config';

import { AdrRespDTO } from '../dto/adr.resp.DTO';

@Injectable({
  providedIn: 'root'
})

export class AdrService {

  constructor(private http: HttpClient, private datePipe: DatePipe) { }

  get(propertyGuid: string, dateFrom: Date, dateTo: Date): Observable<AdrRespDTO> {
    debugger;
    let dateFromStr = this.datePipe.transform(dateFrom, 'yyyy-MM-dd');
    let dateToStr = this.datePipe.transform(dateTo, 'yyyy-MM-dd');

   // return this.http.get<AdrRespDTO>(`${AppConfig.apiUrl}/Statistics/ADR/${propertyGuid}/2021-01-10/2021-10-10`, { headers: reqHeader });
    return this.http.get<AdrRespDTO>(`${AppConfig.apiUrl}/Statistics/ADR/${propertyGuid}/${dateFromStr}/${dateToStr}`);
  }

  //get(propertyGuid: string, dateFrom: Date, dateTo: Date): Observable<AdrRespDTO> {
  //  debugger;
  //  let dateFromStr = this.datePipe.transform(dateFrom, 'yyyy-MM-dd');
  //  let dateToStr = this.datePipe.transform(dateTo, 'yyyy-MM-dd');

  //  // return this.http.get<AdrRespDTO>(`${AppConfig.apiUrl}/Statistics/ADR/${propertyGuid}/2021-01-10/2021-10-10`, { headers: reqHeader });
  //  return this.http.get<AdrRespDTO>(`${AppConfig.apiUrl}/Statistics/ADR/${propertyGuid}/${dateFromStr}/${dateToStr}`);
  //}
}
