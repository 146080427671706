import { Component, OnInit } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { DateHelper } from '../../../utils/date.helper';
import { Router } from '@angular/router';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';

import { UserService } from '../../../services/user.service';
import { ModalService } from '../../../services/modal.service';
import { AuthenticationStateService } from '../../../shared/authentication-state.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-base',
  template: `
        <div>
            
        </div>`
})
export class BaseComponent implements OnInit {

  public dateFromFilter = '';
  public dateToFilter = '';
  public currentDayName = '';

  protected dateFrom: Date = new Date();
  protected dateTo: Date = new Date();
  protected department = '';
  protected propertyGuid = '';

  constructor(protected router: Router, protected datePipe: DatePipe, protected userService: UserService, protected dateAdapter: DateAdapter<Date>,
    protected location: Location, protected modalService: ModalService, protected authenticationStateService: AuthenticationStateService) {
    this.dateAdapter.setLocale('pl-PL');
  }

  ngOnInit(): void {
   
    this.department = this.userService.getDepartment();
    //this.propertyGuid = this.userService.getProperty();
    this.authenticationStateService.propertyState.subscribe(res => {
      debugger;
      this.propertyGuid = res;
    });

    this.initDate(false);
    //this.setDate(this.dateFrom.getDate(), true);
    //this.setDateTo(this.dateFrom.getDate() + 1, true);
  }

  protected initDate(setRange: boolean) {
    //debugger;
    this.setDate(this.dateFrom.getDate(), true);
    if (setRange) {
      this.setDateTo(this.dateFrom.getDate() + 1, true);
    }
    else {
      this.setDateTo(this.dateFrom.getDate(), true);
    }
  }

  public changeDateFrom() {
    //debugger;
    //if (this.dateFromFilter != null && this.dateFromFilter !== '') {
    //  this.dateFrom = new Date(this.dateFromFilter);
    //}

    //if (this.dateToFilter != null && this.dateToFilter !== '') {
    //  this.dateTo = new Date(this.dateToFilter);
    //}

    this.setDate(this.dateFrom.getDate(), false);
    this.setDateTo(this.dateFrom.getDate() + 1);
  }

  public changeDateTo() {
    //debugger;
    if (this.dateToFilter != null && this.dateToFilter !== '') {
      this.dateTo = new Date(this.dateToFilter);

      this.setDateTo(this.dateTo.getDate());
    }
  }

  //backToMainPanel() {
  //  if (this.department === 'Manager') {
  //    this.router.navigate(['/main-panel-director']);
  //  }
  //  else if (this.department === 'HSK') {
  //    this.router.navigate(['/main-panel-hsk']);
  //  }
  //  else if (this.department === 'TECH') {
  //    this.router.navigate(['/main-panel-tech']);
  //  }
  //}
  goBack() {
    debugger;
    this.location.back();
  }

  showInfo(modalId: string) {
    debugger;
    if (modalId != null && modalId !== '') {
      this.modalService.open(modalId);
    }
  }

  closeModal(modalId: string) {
    this.modalService.close(modalId);
  }

  showCalendar() {

  }

  public formatPercentageValue(value: number) {

    if (value != null && value !== 0) {
      let percentageValue = Math.round((value + Number.EPSILON) * 10000) / 100;
      return percentageValue;
    }
    return 0;
  }

  public formatDoubleValue(value: number) {
    if (value != null && value !== 0) {
      let doubleValue = Math.round((value + Number.EPSILON) * 100) / 100;
      return doubleValue;
    }
    return 0;
  }

  protected setDate(day: number, updateSetDate: boolean = true) {
   // debugger;
    this.dateFrom.setDate(day);
    this.dateFrom.setHours(0);
    this.dateFrom.setMinutes(0);
    this.dateFrom.setSeconds(0);

    this.currentDayName = DateHelper.getDayName(this.dateFrom.getDay());

    //if (updateSetDate) {
    //  this.setDateFromStr();
    //}
  }

  protected setDateTo(day: number, updateSetDate: boolean = true) {
   // debugger;
    this.dateTo.setDate(day);
    this.dateTo.setHours(0);
    this.dateTo.setMinutes(0);
    this.dateTo.setSeconds(0);

    //if (updateSetDate) {
    //  this.setDateToStr();
    //}
  }

  protected setNextDay() {
  // debugger;
    

    //let currentDayIndex = this.dateFrom.getDay();
    //if (currentDayIndex === 6) {
    //  currentDayIndex = 0;
    //}
    //else {
    //  currentDayIndex++;
    //}

  //  let nextDay = this.dateFrom.getDate();
   // let nextDay = currentDayIndex + 1;

    this.setDate(this.dateFrom.getDate() + 1, true);
    this.setDateTo(this.dateTo.getDate() + 1, true);

    //this.setDateFromStr();
    //this.setDateToStr();
  }

  protected setPreviousDay() {
   // debugger;
    this.setDate(this.dateFrom.getDate() - 1, true);
    this.setDateTo(this.dateTo.getDate() - 1, true);
  }

  protected setDateFromStr() {
    //if (this.dateFrom != null) {
    //  this.dateFromFilter = this.datePipe.transform(this.dateFrom, 'yyyy-MM-dd');
    //}

  }
  protected setDateToStr() {
    //debugger;
    //if (this.dateTo != null) {
    //  this.dateToFilter = this.datePipe.transform(this.dateTo, 'yyyy-MM-dd');
    //}
  }
}
