export class LostFoundItemDTO {
  Guid: string;
  Status: string;
  ShortDescription: string;
  FullDescription: string;
  LossDate: Date;
  OwnerFirstName: string;
  OwnerLastName: string;
  FindingDate: string; // Date;
  FinderFirstName: string;
  FinderLastName: string;
  FindingRoomGuid: string;
  OtherFindingPlaceName: string;
  ReturnDate: Date;
  ReceiverFirstName: string;
  ReceiverLastName: string;
}
