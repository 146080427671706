<footer class="footer-mobile-down">

  <div class="container-mobile">

    <div class="footer-mobile-down-content">

      <div class="footer-mobile-down-button-a" (click)="showMainPanel()">
        <img src="assets/rez.png" alt="" title="">
        <span>Panel główny</span>
      </div>
      <div class="footer-mobile-down-button-a" (click)="showActivitLog()">
        <div class="footer-mobile-down-button-a">
          <img src="assets/calendar.png" alt="" title="">
          <span>Dziennik zadań</span>
        </div>
      </div>
      <!-- <div class="footer-mobile-down-button-a">
          <img src="assets/ud.png" alt="" title="">
          <span>Udogodnienia</span>
      </div> -->
      <div class="footer-mobile-down-button-a" (click)="showChatMessages()">
        <img src="assets/icon-chat.svg" alt="" title="">
        <span>Wiadomości</span>
      </div>
    </div>
  </div>
</footer>
