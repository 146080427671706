import { UserDTO } from "./user.DTO";

export class ChatMessageDTO {
  Guid: string;
  IsDeleted: boolean;
  CreationDateTime: Date;
  Text: string;
  Source: string;
  User: UserDTO;
  IsVisibleToGuest: boolean;
}
