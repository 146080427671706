<div class="reservation-form">
  <app-header></app-header>
  <div class="main-page">

    <section class="section-page-mobile">
      <div class="section-reservation-mobile-top">
        <div class="container-mobile-2">
          <div class="section-reservation-mobile-header-icon-1">
            <a (click)="goBack()"><img class="header-page-user" src="../../../../../assets/left.png" alt=""
                title=""></a>
          </div>
          <div class="section-reservation-mobile-header-icon">
            <h1 class="section-reservation-mobile-header-1">Lista usterek</h1>
          </div>
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showInfo('')"><img class="header-page-user" src="../../../../../assets/icon-info.svg" alt=""
                title=""></a>
          </div>
        </div>
      </div>

      <!-- top bar search box -->
      <div class="container-mobile">
        <div class="window-mobile-page-content-main">
          <div class="page-mobile-container">

            <div class="page-mobile-container-section-light searcher">
              <a>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.33 23.33">
                  <defs>
                    <style>
                      .cls-1 {
                        fill: none;
                        stroke: #bdb9c0;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-width: 2px;
                      }
                    </style>
                  </defs>
                  <g id="Warstwa_2" data-name="Warstwa 2">
                    <g id="Warstwa_1-2" data-name="Warstwa 1">
                      <g id="Group_164" data-name="Group 164">
                        <circle id="Ellipse_35" data-name="Ellipse 35" class="cls-1" cx="10.28" cy="10.28" r="9.28" />
                        <line id="Line_85" data-name="Line 85" class="cls-1" x1="22.33" y1="22.33" x2="16.83"
                          y2="16.83" />
                      </g>
                    </g>
                  </g>
                </svg>
              </a>
              <input type="text" [(ngModel)]="itemName" placeholder="Wyszukaj" #ctrl="ngModel"
                (keydown.enter)="filterItems()" />
              <a class="d-none" (click)="sortItems()">
                <img src="../../../../../assets/8_Goście_03.png">
              </a>
            </div>

          </div>
        </div>
      </div>
      <!-- END top bar search box -->

      <div class="section-reservation-mobile-top">
        <div class="container-mobile-2 day d-none">
          <!--<div class="section-reservation-mobile-header-icon-1">
            <a (click)="showCalendar()"><img class="header-page-user" src="../../../../../assets/icon_03.png" alt="" title=""></a>
          </div>-->
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showPreviousDay()"><img class="header-page-user" src="assets/prev.png" alt="" title=""></a>
          </div>

          <div class="section-reservation-mobile-header-icon">
            <!--<h1 class="section-reservation-mobile-header-3"><span>poniedziałek</span> 21.10.2021</h1>-->
            <!--<input type="date" class="section-reservation-mobile-header-3" [(ngModel)]="dateFromFilter" value="{{ dateFromFilter | date:'yyyy-MM-dd' }}" #ctrl="ngModel" required (ngModelChange)="changeDateFrom()" />-->
            <mat-form-field [formGroup]="rangeDate" (click)="picker.open()">
              <input matInput formControlName="start" [matDatepicker]="picker" (dateChange)="setRangeFrom()" disabled>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker disabled="false"></mat-datepicker>
              <mat-error *ngIf="rangeDate.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            </mat-form-field>
          </div>

          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showNextDay()"><img class="header-page-user" src="../../../../../assets/icon_06.png" alt=""
                title=""></a>
          </div>
        </div>
      </div>

      <div class="section-page-mobile-content" (scroll)="showTopBtn()">
        <div class="container-mobile">
          <div class="window-mobile-page-content-main">
            <div class="page-mobile-container" #top>

              <ul class="list-fault">
                <li *ngFor="let item of maintenances">
                  <p class="no"></p>
                  <p class="title">{{ item.Text }}</p>
                  <p class="more btn"><a class="d-flex justify-content-center"><img
                        src="../../../../../assets/icon-bullets.svg" alt="Placeholder"></a></p>
                </li>
              </ul>
              <!--<ul class="list-fault">
    <li *ngFor="let item of lostAndFoundItems">
      <p class="no"></p>
      <p class="title">{{ item.ShortDescription }}</p>
      <p class="more btn"><a><img src="../../../../../assets/icon-bullets.svg" alt="Placeholder"></a></p>
    </li>
  </ul>-->
            </div>
          </div>
        </div>
      </div>

    </section>

  </div>
  <app-footer></app-footer>
</div>
