<div class="reservation-form">
  <app-header></app-header>
  <div class="main-page">

    <section class="section-page-mobile">
      <div class="section-reservation-mobile-top">
        <div class="container-mobile-2">
          <div class="section-reservation-mobile-header-icon-1">
            <a (click)="goBack()"><img class="header-page-user" src="../../../../assets/left.png" alt="" title=""></a>
          </div>
          <div class="section-reservation-mobile-header-icon">
            <h1 class="section-reservation-mobile-header-1">Dziennik działań</h1>
          </div>
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showInfo('')"><img class="header-page-user" src="../../../../assets/icon-info.svg" alt=""
                title=""></a>
          </div>
        </div>
      </div>
      <div class="section-reservation-mobile-top">
        <div class="container-mobile-2 day">
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showPreviousDay()"><img class="header-page-user" src="assets/prev.png" alt="" title=""></a>
          </div>
          <!--<div class="section-reservation-mobile-header-icon-1">
            <a (click)="showCalendar()"><img class="header-page-user" src="../../../../assets/icon_03.png" alt="" title=""></a>
          </div>-->
          <div class="section-reservation-mobile-header-icon">
            <h1 class="section-reservation-mobile-header-1">
              <div class="d-flex flex-column">
                <span class="d-block">{{ currentDayName}}, </span>
                <div class="d-flex">
                  <mat-form-field [formGroup]="rangeDate" (click)="picker.open()">
                    <input matInput formControlName="start" [matDatepicker]="picker" (dateChange)="setRangeFrom()"
                      disabled>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                    <mat-error *ngIf="rangeDate.controls.start.hasError('matStartDateInvalid')">Invalid start date
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>


              <!-- <input type="date" class="section-reservation-mobile-input" [(ngModel)]="dateFromFilter" value="{{ dateFromFilter | date:'yyyy-MM-dd' }}" #ctrl="ngModel" required (ngModelChange)="changeDateFrom()" /> -->
            </h1>
          </div>
          <div class="section-reservation-mobile-header-icon-2">
            <!--<a href=""><img class="header-page-user" src="assets/icon_06.png" alt="" title=""></a>-->
            <a (click)="showNextDay()"><img class="header-page-user" src="../../../../assets/icon_06.png" alt=""
                title=""></a>
          </div>
        </div>
      </div>

      <div class="section-page-mobile-content">
        <div class="container-mobile">
          <div class="window-mobile-page-content-main">
            <div class="page-mobile-container">

              <div class="page-mobile-container-section-dark-rev flex on-focus-opacity-90"
                (click)="openItem('cleaning_report')">
                <div class="col30">
                  <img src="../../../../assets/19_Dziennik-działań_03.png">
                </div>
                <div class="col70">Raport sprzątania</div>
              </div>

              <div class="page-mobile-container-section-dark-rev flex on-focus-opacity-90"
                (click)="openItem('adds_on_report')">
                <div class="col30">
                  <img src="../../../../assets/19_Dziennik-działań_03.png">
                </div>
                <div class="col70">Raport dodatków</div>
              </div>

              <div class="page-mobile-container-section-dark-rev flex on-focus-opacity-90"
                (click)="openItem('items_found_report')">
                <div class="col30">
                  <img src="../../../../assets/19_Dziennik-działań_03.png">
                </div>
                <div class="col70">Raport rzeczy znalezionych</div>
              </div>

              <div class="page-mobile-container-section-dark-rev flex on-focus-opacity-90"
                (click)="openItem('fault_report')">
                <div class="col30">
                  <img src="../../../../assets/19_Dziennik-działań_03.png">
                </div>
                <div class="col70">Raport usterek</div>
              </div>

              <div class="page-mobile-container-section-dark-rev flex on-focus-opacity-90"
                (click)="openItem('list_items_found')">
                <div class="col30">
                  <img src="../../../../assets/19_Dziennik-działań_06.png">
                </div>
                <div class="col70">Lista rzeczy znalezionych</div>
              </div>

              <div class="page-mobile-container-section-dark-rev flex on-focus-opacity-90"
                (click)="openItem('fault_list')">
                <div class="col30">
                  <img src="../../../../assets/19_Dziennik-działań_06.png">
                </div>
                <div class="col70">Lista usterek</div>
              </div>

              <div class="page-mobile-container-section-dark-rev flex on-focus-opacity-90"
                (click)="openItem('adds_on_list')">
                <div class="col30">
                  <img src="../../../../assets/19_Dziennik-działań_06.png">
                </div>
                <div class="col70">Lista dodatków</div>
              </div>

              <div class="page-mobile-container-section-dark-rev flex on-focus-opacity-90">
                <div class="col30">
                  <img src="../../../../assets/19_Dziennik-działań_03.png">
                </div>
                <div class="col70">Raport 1</div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </section>
  </div>
  <app-footer></app-footer>
</div>
