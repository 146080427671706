<div class="reservation-form">
  <app-header></app-header>
  <div class="main-page">

    <section class="section-page-mobile">
      <div class="section-reservation-mobile-top">
        <div class="container-mobile-2">
          <div class="section-reservation-mobile-header-icon-1">
            <a (click)="goBack()"><img class="header-page-user" src="../../../../assets/left.png" alt="" title=""></a>
          </div>
          <div class="section-reservation-mobile-header-icon">
            <h1 class="section-reservation-mobile-header-1">Status pokoi</h1>
          </div>
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showInfo('')"><img class="header-page-user" src="../../../../assets/icon-info.svg" alt=""
                title=""></a>
          </div>
        </div>
      </div>
      <div class="section-page-mobile-content">
        <div class="container-mobile">
          <div class="window-mobile-page-content-main">
            <div class="page-mobile-container">

              <div style="display: flex; width: 100%;">
                <!--<input value="Pokój 007">-->
                <!--<input type="text" [(ngModel)]="roomNo" #ctrl="ngModel" />-->
                <!--<select [(ngModel)]="selectedHouseKeeping">
                  <option></option>
                  <option *ngFor="let item of houseKeepingData" [ngValue]="item">
                  </option>
                </select>-->
                <div style="width: 100%;" [ngClass]="{
                   'room-status-clean': houseKeepingDataDTO?.RoomStatusKind === 'Clean',
                   'room-status-clean-oc': houseKeepingDataDTO?.RoomStatusKind === 'OccupiedClean',
                   'room-status-service': houseKeepingDataDTO?.RoomStatusKind === 'OutOfService',
                   'room-status-active': houseKeepingDataDTO?.RoomStatusKind === 'CleanUnchecked',
                   'room-status-neutral': houseKeepingDataDTO?.RoomStatusKind === 'Deleted',
                   'room-status-deactive': houseKeepingDataDTO?.RoomStatusKind === 'Dirty' || houseKeepingDataDTO?.RoomStatusKind === 'OccupiedDirty'
                     || houseKeepingDataDTO?.RoomStatusKind === 'OccupiedForceCleaning' || houseKeepingDataDTO?.RoomStatusKind === 'OccupiedToCheck'
                   }">
                  <div class="col20 number">{{ houseKeepingDataDTO?.Room?.Number }}</div>
                  <div class="col20">{{ houseKeepingDataDTO?.Room?.Kind?.Name }}</div>
                  <div class="col50 status">{{ houseKeepingDataDTO?.RoomStatusDisplayText }}</div>
                  <div class="col10 dots">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                      <path
                        d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                    </svg>
                  </div>
                </div>
              </div>


              <!--<h4>Dodaj</h4>-->
              <!--<div *ngFor="let item of serviceKindDTOs">
                <div class="col90">{{ item.Name }}</div>
                <div class="col10">
                  <input type="checkbox" [checked]="item.Selected" (change)="item.Selected = !item.Selected" />
                </div>
              </div>-->
              <div class="room-status-checkbox-off" *ngFor="let item of preparationDispositions; let i = index">
                <div class="col90">{{ item }}</div>
                <div class="col10">
                  <input type="checkbox" class="cc" id="checkbox{{i}}" [checked]="item.Selected"
                    (change)="item.Selected = !item.Selected" />
                  <label class="d-none" for="checkbox{{i}}"></label>
                </div>
              </div>
              <!--<div id="checkbox2_box" class="room-status-checkbox-off">
                <div class="col90">Kieliszki</div>
                <div class="col10">
                  <input type="checkbox" name="custom1" id="checkbox2" class="cc"> <label id="checkbox2l" for="checkbox2" onclick="check()"></label>
                </div>
              </div>
              <div id="checkbox3_box" class="room-status-checkbox-off">
                <div class="col90">Poduszka z pierzem</div>
                <div class="col10">
                  <input type="checkbox" name="custom1" id="checkbox3" class="cc"> <label id="checkbox3l" for="checkbox3" onclick="check()"></label>
                </div>
              </div>
              <div id="checkbox4_box" class="room-status-checkbox-off">
                <div class="col90">Kieliszki</div>
                <div class="col10">
                  <input type="checkbox" name="custom1" id="checkbox4" class="cc"> <label id="checkbox4l" for="checkbox4" onclick="check()"></label>
                </div>
              </div>-->

            </div>


            <div id="housekeeping-btns-section" class="container page-mobile-container flex housekeeping-buttons">

              <div class="row w-100">

                <!--  Zgłoś usterkę -->
                <div (click)="addFault()" class="col housekeeping-buttons--item on-focus-opacity-90">
                  <img alt="icon" src="../../../../assets/img/house-keeping/hk-icon-1@0.5x.png">
                  Zgłoś usterkę
                </div>

                <!--   Rzeczy znalezione -->
                <div (click)="showLostAndFound()" class="col housekeeping-buttons--item on-focus-opacity-90">
                  <img alt="icon" src="../../../../assets/img/house-keeping/hk-icon-2@0.5x.png">
                  Rzeczy znalezione
                </div>

                <div class="w-100"></div>

                <!--  Uzupełnij minibar -->
                <div (click)="showMinibar()" class="col housekeeping-buttons--item on-focus-opacity-90">
                  <img alt="icon" src="../../../../assets/img/house-keeping/hk-icon-3@0.5x.png">
                  Uzupełnij minibar
                </div>

                <!--  Przygotuj -->
                <div class="col housekeeping-buttons--item on-focus-opacity-90">
                  <img alt="icon" src="../../../../assets/img/house-keeping/hk-icon-4@0.5x.png">
                  Przygotuj
                </div>

                <div class="w-100">
                </div>

                <h2 class="pl-4 ml-5 mt-3">Zgłoś status: </h2>

                <div class="row d-flex">
                  <div (click)="setStatus('CleanUnchecked')"
                    class="col-12 housekeeping-buttons--item on-focus-opacity-90">
                    <img alt="icon" src="../../../../assets/img/house-keeping/hk-icon-5@0.5x.png">
                    Posprzątany
                  </div>

                  <div (click)="setStatus('CleanChecked')"
                    class="col-12 housekeeping-buttons--item on-focus-opacity-90">
                    <img alt="icon" src="../../../../assets/img/house-keeping/hk-icon-5@0.5x.png">
                    Sprawdzony
                  </div>

                  <div (click)="setStatus('Dirty')"
                    class="col-12 housekeeping-buttons--item on-focus-opacity-90">
                    <img alt="icon" src="../../../../assets/img/house-keeping/hk-icon-5@0.5x.png">
                    Brudny
                  </div>
                </div>



              </div>

            </div>

          </div>
        </div>
      </div>

    </section>
  </div>
  <app-footer></app-footer>
</div>
