import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

import { AppConfig } from '../config/app.config';

import { TokenAuthService } from '../shared/token-auth.service';

import { CompaniesRespDTO } from '../dto/companies-resp.DTO';


@Injectable({
  providedIn: 'root'
})

export class CompanyService {

  constructor(private http: HttpClient, private datePipe: DatePipe, private tokenAuthService: TokenAuthService) { }

  getById(propertyGuid: string, companyGuid: string): Observable<CompaniesRespDTO> {
    debugger;
    //let jwtJson = this.tokenAuthService.getJwtToken();
    //let jwt = JSON.parse(jwtJson);

    //var reqHeader = new HttpHeaders({
    //  'Content-Type': 'application/json',
    //  'Authorization': jwt.access_token
    //});

    return this.http.get<CompaniesRespDTO>(`${AppConfig.apiUrl}/Company/${propertyGuid}/${companyGuid}`);
  }

  get(propertyGuid: string, guids: string[], name: string, nIP: string, addressStreet: string,
    addressCountryCode: string, addressCountryName: string, addressPostalCode: string, addressCity: string,
    emailAddresses: string[], phoneNumbers: string[], segmentGuid: string, dateFrom: Date, dateTo: Date): Observable<CompaniesRespDTO> {
    

    let querySearchParams = '';

    if (guids !== null && guids.length > 0) {
      for (let i = 0; i < guids.length; i++) {
        querySearchParams += '&searchFilters.guids=' + guids[i];
      }
    }

    if (name !== null && name !== '') {
      querySearchParams += '&searchFilters.anyField=' + name;
    }

    if (nIP !== null && nIP !== '') {
      querySearchParams += '&searchFilters.nIP=' + nIP;
    }

    if (addressStreet !== null && addressStreet !== '') {
      querySearchParams += '&searchFilters.addressStreet=' + addressStreet;
    }

    if (addressCountryCode !== null && addressCountryCode !== '') {
      querySearchParams += '&searchFilters.addressCountryCode=' + addressCountryCode;
    }

    if (addressCountryName !== null && addressCountryName !== '') {
      querySearchParams += '&searchFilters.addressCountryName=' + addressCountryName;
    }

    if (addressPostalCode !== null && addressPostalCode !== '') {
      querySearchParams += '&searchFilters.addressPostalCode=' + addressPostalCode;
    }

    if (addressCity !== null && addressCity !== '') {
      querySearchParams += '&searchFilters.addressCity=' + addressCity;
    }

    if (emailAddresses !== null && emailAddresses.length > 0) {
      for (let i = 0; i < emailAddresses.length; i++) {
        querySearchParams += '&searchFilters.emailAddresses=' + emailAddresses[i];
      }
    }

    if (phoneNumbers !== null && phoneNumbers.length > 0) {
      for (let i = 0; i < phoneNumbers.length; i++) {
        querySearchParams += '&searchFilters.phoneNumbers=' + phoneNumbers[i];
      }
    }

    if (segmentGuid !== null && segmentGuid !== '') {
      querySearchParams += '&searchFilters.segmentGuid=' + segmentGuid;
    }

    //if (dateFrom != null) {
    //  let dateFromStr = this.datePipe.transform(dateFrom, 'yyyy-MM-dd');
    //  querySearchParams += '&searchFilters.dateFrom=' + dateFromStr;
    //}

    //if (dateTo != null) {
    //  let dateToStr = this.datePipe.transform(dateTo, 'yyyy-MM-dd');
    //  querySearchParams += '&searchFilters.dateTo=' + dateToStr;
    //}

    if (querySearchParams !== '') {
      querySearchParams = '?' + querySearchParams.substring(1);

      return this.http.get<CompaniesRespDTO>(`${AppConfig.apiUrl}/Company/${propertyGuid}` + querySearchParams);
    }
    else {
      return this.http.get<CompaniesRespDTO>(`${AppConfig.apiUrl}/Company/${propertyGuid}`);
    }
    
  }
}
