import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { DateAdapter } from '@angular/material/core';

import { UserService } from '../../services/user.service';
import { HousekeepingService } from '../../services/housekeeping.service';;
import { BaseComponent } from '../shared/base/base.component';
import { RoomService } from '../../services/room.service';
import { DepartmentDTO } from '../../dto/department.DTO';
import { RoomDTO } from '../../dto/room.DTO';
import { HousekeepingDataDTO } from '../../dto/housekeeping-data.DTO';
import { ServiceKindDTO } from '../../dto/service-kind.DTO';
import { ServiceDTO } from '../../dto/service.DTO';
import { MinibarDTO } from '../../dto/minibar.DTO';
import { min } from 'moment';
import { ModalService } from '../../services/modal.service';
import { AuthenticationStateService } from '../../shared/authentication-state.service';

@Component({
  selector: 'app-minibar',
  templateUrl: './minibar.component.html',
  styleUrls: ['./minibar.component.scss']
})
export class MinibarComponent extends BaseComponent implements OnInit {
  @ViewChild('top') top: ElementRef;
  @ViewChild('topBtn') topBtn: ElementRef;

  public departments: DepartmentDTO[] = [];
  public houseKeepingDataDTO: HousekeepingDataDTO = null;
  public serviceKindDTOs: ServiceKindDTO[] = [];
  public rooms: RoomDTO[] = [];
  public roomId = '';
  public department = null;
  public itemName: string = null;
  public countList: number[] = [0,1,2,3,4,5,6,7,8,9,10];

  constructor(private route: ActivatedRoute, protected router: Router, protected datePipe: DatePipe,
    protected authenticationStateService: AuthenticationStateService, protected userService: UserService,
    protected dateAdapter: DateAdapter<Date>, private roomService: RoomService,
    protected location: Location,
    private houseKeepingService: HousekeepingService, protected modalService: ModalService) {
    super(router, datePipe, userService, dateAdapter, location, modalService, authenticationStateService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    let roomIdParam = this.route.snapshot.queryParamMap.get("id");
    this.roomId = roomIdParam;
    this.loadData(roomIdParam);
    this.departments = this.userService.getDepartments();
    //this.loadData();
    if (this.departments != null && this.departments.length > 0) {

      this.department = this.departments.filter(item => {
        return item.name === this.department;
      })[0];
    }

  }

  createArrayFromCount(count): number[] {
    return [...Array(count).keys()];
  }

  inputCheckedHandler(currentItemIndex, currentCountIndex, currentCount): void {
    document.getElementById(currentCountIndex === 0 ? 'ia' + currentItemIndex : 'ia' + currentItemIndex + '-' + currentCount).click();
  }

  scrollToTop() {
    this.top.nativeElement.scrollIntoView();
  }

  showTopBtn() {
    const rect = this.top.nativeElement.getBoundingClientRect();

    if (rect.top < 120) {
      this.topBtn.nativeElement.classList.remove('inactive');
    } else {
      this.topBtn.nativeElement.classList.add('inactive');
    }
  }


  loadData(roomIdParam: string) {
    //let userDTO = this.authenticationStateService.getUser();

    //let propertyGuid = 'fa11a7b5-8f20-41be-a400-c472ce0dd56f';
    //let propertyGuid = '92b94e93-339f-4f0a-b4f8-26a54c3e4d24';

   // this.houseKeepingService.get(this.propertyGuid).subscribe(res => {
    this.houseKeepingService.getByRoom(this.propertyGuid, this.roomId).subscribe(res => {
      if (res.Success) {
        //this.companies = res.Companies;
        //this.housekeepingStatusResponseDTO = res;
        if (res.HousekeepingData != null && res.HousekeepingData.length > 0) {

          this.houseKeepingDataDTO = res.HousekeepingData.filter(item => {
            return item.Room.Guid === roomIdParam;
          })[0];

          this.houseKeepingService.getMinibar(this.propertyGuid).subscribe(res => {
            if (res.Success) {

              console.log(res.ServiceKinds)
              this.serviceKindDTOs = res.ServiceKinds;
            }
          }, error => {
            console.error(error);
          });
        }

      }
    });

    //this.roomService.get(this.propertyGuid).subscribe(res => {
    //  debugger;
    //  if (res.Success) {
    //    this.rooms = res.Rooms;

    //    this.roomId = roomIdParam;

    //    this.houseKeepingService.getMinibar(this.propertyGuid).subscribe(res => {
    //      debugger;
    //      if (res.Success) {

    //      }
    //    });
    //  }
    //});

    //this.maintenanceService.get(propertyGuid).subscribe(res => {
    //  debugger;
    //  if (res.Success) {
    //    this.maintenances = res.Maintenances;
    //  }

    //});

  }

  filterItems() {

  }

  save() {

    //let maintenanceReqDTO: MaintenanceReqDTO = new MaintenanceReqDTO();
    //maintenanceReqDTO.Maintenance = new MaintenanceDTO();
    //maintenanceReqDTO.Maintenance.Room = new RoomDTO();
    //maintenanceReqDTO.Maintenance.Room.Guid = this.roomId !== '' ? this.roomId : null; // "b15bf80b-218c-4bf3-b362-dcdfd9854914";
    //// maintenanceReqDTO.Maintenance.Room.Number = this.roomNo;
    //maintenanceReqDTO.Maintenance.Text = this.content;

    //this.maintenanceService.add(this.propertyGuid, maintenanceReqDTO).subscribe(res => {
    //  debugger;
    //  if (res.Success) {
    //    this.content = '';
    //    // this.roomId = '';
    //  }
    //});
    debugger;
    for (let i = 0; i < this.serviceKindDTOs.length; i++) {
      if (this.serviceKindDTOs[i].Count != null) {

        let minibarDTO: MinibarDTO = new MinibarDTO();
        minibarDTO.RoomGuid = this.roomId;
        minibarDTO.ServiceKindGuid = this.serviceKindDTOs[i].Guid;
        minibarDTO.Quantity = this.serviceKindDTOs[i].Count;

        this.houseKeepingService.addMinibar(this.propertyGuid, minibarDTO).subscribe(res => {
          if (res.Success) {

          }
        });
      }
    }
  }

  cancel() {
    let mainPanelUrl = this.userService.getMainPanelUrl();
    this.router.navigate([mainPanelUrl]);
  }
}
