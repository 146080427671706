import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  //isShowing = false;
  //modal = new Subject();

  constructor() {}

  //getModal() {
  //  return this.modal;
  //}

  //open() {
  //  this.isShowing = true;
  //  this.modal.next(true);
  //}

  //private close() {
  //  this.isShowing = false;
  //  this.modal.next(false);
  //}

  //closeModal() {
  //  this.close();
  //}

  private modals: any[] = [];

  add(modal: any) {
    // add modal to array of active modals
    debugger;
    const item = this.modals.find((x) => x.id === modal.id);
    if (item != null) {
      const index: number = this.modals.indexOf(item);
      this.modals.splice(index, 1);
    }
    this.modals.push(modal);
  }

  remove(id: string) {
    // remove modal from array of active modals
    this.modals = this.modals.filter((x) => x.id !== id);
  }

  open(id: string) {
    // open modal specified by id
    debugger;
    const modal = this.modals.find((x) => x.id === id);
    modal.openModal();

    //let modalsPopup = this.modals.filter(item => {
    //  return item.id === id;
    //});

    //for (let i = 0; i < modalsPopup.length; i++) {
    //  modalsPopup[i].openModal();
    //}
  }

  close(id: string) {
    // close modal specified by id
    const modal = this.modals.find((x) => x.id === id);
    if (modal != null) {
      //const index: number = this.modals.indexOf(modal);
      modal.closeModal();
      //if (index !== -1) {
      //  this.modals.splice(index, 1);
      //}
    }
  }
}
