import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { RouterModule } from "@angular/router";
import { AuthGuard } from "./shared/auth.guard";
import { AuthHeaderInterceptor } from "./shared/auth-header.interceptor";
import { DatePipe } from "@angular/common";
import { NgChartsModule } from "ng2-charts";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSliderModule } from "@angular/material/slider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatNativeDateModule } from "@angular/material/core";
import {
  MatMomentDateModule,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import { MatCalendar } from "@angular/material/datepicker";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats,
  MAT_DATE_LOCALE,
} from "@angular/material/core";

import { IntegerNumberDirective } from "./components/shared/directives/integer-number.directive";
import { TwoDigitDecimalNumberDirective } from "./components/shared/directives/two-digit-decimal-number.directive";

import { AppComponent } from "./app.component";
import { LoginComponent } from "./components/login/login.component";
import { AdrComponent } from "./components/adr/adr.component";
import { OccupancyComponent } from "./components/occupancy/occupancy.component";
import { CompaniesComponent } from "./components/company/companies/companies.component";
import { CompanyDetailsComponent } from "./components/company/company-details/company-details.component";
import { LostAndFoundAddComponent } from "./components/lost-and-found/lost-and-found-add/lost-and-found-add.component";
import { LostAndFoundListComponent } from "./components/lost-and-found/lost-and-found-list/lost-and-found-list.component";
import { GuestsComponent } from "./components/guest/guests/guests.component";
import { GuestInfoComponent } from "./components/guest/guest-info/guest-info.component";
import { HousekeepingComponent } from "./components/housekeeping/housekeeping/housekeeping.component";
import { HousekeepingExtComponent } from "./components/housekeeping/housekeeping-ext/housekeeping-ext.component";
import { MaintenanceAddComponent } from "./components/activity-log/maintenance/add/maintenance-add.component";
import { RevenueComponent } from "./components/revenue/revenue.component";
import { ConferencesComponent } from "./components/conference/conferences.component";
import { MainPanelDirectorComponent } from "./components/main-panel/director/main-panel-director.component";
import { MainPanelHskComponent } from "./components/main-panel/hsk/main-panel-hsk.component";
import { MainPanelTechComponent } from "./components/main-panel/tech/main-panel-tech.component";
import { ActivityLogComponent } from "./components/activity-log/activity-log/activity-log.component";
import { ActivityLogMaintenanceComponent } from "./components/activity-log/maintenance/fault-list/activity-log-maintenance.component";
import { ChatMessagesComponent } from "./components/chat/messages/chat-messages.component";
import { ChatComponent } from "./components/chat/chat/chat.component";
import { FooterComponent } from "./components/shared/footer/footer.component";
import { HeaderComponent } from "./components/shared/header/header.component";
import { ReportComponent } from "./components/report/report.component";
import { BaseComponent } from "./components/shared/base/base.component";
import { ModalComponent } from "./components/shared/modal/modal.component";

import { ModalService } from "./services/modal.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MinibarComponent } from "./components/minibar/minibar.component";
import { SpinnerComponent } from "./components/spinner/spinner.component";

export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    LoginComponent,
    AdrComponent,
    OccupancyComponent,
    CompaniesComponent,
    LostAndFoundAddComponent,
    GuestsComponent,
    GuestInfoComponent,
    HousekeepingComponent,
    HousekeepingExtComponent,
    MaintenanceAddComponent,
    RevenueComponent,
    ConferencesComponent,
    MainPanelDirectorComponent,
    MainPanelHskComponent,
    MainPanelTechComponent,
    ActivityLogComponent,
    ActivityLogMaintenanceComponent,
    ChatMessagesComponent,
    ChatComponent,
    ReportComponent,
    BaseComponent,
    ModalComponent,
    MinibarComponent,
    IntegerNumberDirective,
    TwoDigitDecimalNumberDirective,
    LostAndFoundListComponent,
    SpinnerComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgChartsModule,
    MatSliderModule,
    MatDatepickerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatMomentDateModule,
    RouterModule.forRoot(
      [
        //{ path: '', component: HomeComponent, pathMatch: 'full' },
        { path: "", redirectTo: "/login", pathMatch: "full" },
        { path: "login", component: LoginComponent },
        { path: "adr", component: AdrComponent, canActivate: [AuthGuard] },
        {
          path: "occupancy",
          component: OccupancyComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "companies",
          component: CompaniesComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "company-details",
          component: CompanyDetailsComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "revenue",
          component: RevenueComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "lost-and-found-add",
          component: LostAndFoundAddComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "lost-and-found-list",
          component: LostAndFoundListComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "guests",
          component: GuestsComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "guest-info",
          component: GuestInfoComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "housekeeping",
          component: HousekeepingComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "housekeeping-ext",
          component: HousekeepingExtComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "maintenance-add",
          component: MaintenanceAddComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "conferences",
          component: ConferencesComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "main-panel-director",
          component: MainPanelDirectorComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "main-panel-hsk",
          component: MainPanelHskComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "main-panel-tech",
          component: MainPanelTechComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "activity-log",
          component: ActivityLogComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "report",
          component: ReportComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "fault-list",
          component: ActivityLogMaintenanceComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "chat-messages",
          component: ChatMessagesComponent,
          canActivate: [AuthGuard],
        },
        { path: "chat", component: ChatComponent, canActivate: [AuthGuard] },
        {
          path: "minibar",
          component: MinibarComponent,
          canActivate: [AuthGuard],
        },
      ],
      { useHash: true }
    ),
    BrowserAnimationsModule, // { relativeLinkResolution: 'legacy' })
  ],
  providers: [
    DatePipe,
    ModalService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeaderInterceptor,
      multi: true,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
