import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationStateService } from './authentication-state.service';

import { UserDTO } from '../dto/user.DTO';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationStateService: AuthenticationStateService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //debugger;
    if (this.authenticationStateService.getUserAuthState()) {
      return true;
    }
    //const currentUser = this.authService.currentUserValue instanceof UserDTO;
    //if (currentUser) {
    //  // logged in so return true
    //  return true;
    //}

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
