import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe, Location } from "@angular/common";
import { DateAdapter } from "@angular/material/core";

import { BaseComponent } from "../../shared/base/base.component";

import { GuestService } from "../../../services/guest.service";
import { UserService } from "../../../services/user.service";
import { ModalService } from "../../../services/modal.service";

import { GuestRespDTO } from "../../../dto/guest-resp.DTO";
import { ServiceService } from "../../../services/service.service";
import { GuestDTO } from "../../../dto/guest.DTO";
import { FormControl } from "@angular/forms";
import { DescriptionService } from "../../../services/description.service";
import { AuthenticationStateService } from "../../../shared/authentication-state.service";

@Component({
  selector: "app-guests",
  templateUrl: "./guests.component.html",
  styleUrls: ["./guests.component.scss"],
})
export class GuestsComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild("top") top: ElementRef;
  @ViewChild("topBtn") topBtn: ElementRef;

  public guestRespDTO: GuestRespDTO = null;
  public guests: GuestDTO[] = [];
  public guestsAll: GuestDTO[] = [];

  public guestName: string = null;
  public loading: boolean = false;
  private loadingDelay = null;

  public guestFirstLastName = "";
  public roomNo = "";
  public startDate = "";
  public startTime = "";
  public endDate = "";
  public endTime = "";
  public adultGuestCount = 0;
  public amount = 0;
  public serviceKind = "";

  public description: any = null;

  public rangeDate = new FormGroup({
    start: new FormControl(),
  });

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    protected datePipe: DatePipe,
    protected authenticationStateService: AuthenticationStateService,
    protected userService: UserService,
    private guestService: GuestService,
    protected modalService: ModalService,
    private serviceService: ServiceService,
    protected dateAdapter: DateAdapter<Date>,
    protected location: Location,
    protected descriptionService: DescriptionService
  ) {
    super(router, datePipe, userService, dateAdapter, location, modalService, authenticationStateService);
  }

  ngOnInit() {
    let dateFromParam = this.route.snapshot.queryParamMap.get("dateFrom");
    if (dateFromParam != null && dateFromParam !== "") {
      this.dateFrom = new Date(parseInt(dateFromParam));
      this.dateTo = new Date(parseInt(dateFromParam));
    }

    super.ngOnInit();
    this.setDateControl();
    this.loadData();

    this.descriptionService.get().subscribe((res) => {
      this.description = res.Views.ConferencesInfo;
    });
  }

  ngOnDestroy() {
    this.modalService.remove('guest-info-modal');
  }

  loadData() {
    // błąd jak się podało name z filtra
    this.guestService
      .get(this.propertyGuid, this.dateFrom, "")
      .subscribe((res) => {
        debugger;
        if (res.Success) {
          this.guestRespDTO = res;
          this.guests = res.Guests;

          for (let i = 0; i < this.guests.length; i++) {
            debugger;
            if (this.guests[i].Person?.hasOwnProperty("BirthDate")) {
              this.guests[i].Person.HasBirthDate = this.hasBirthday(
                this.guests[i].Person?.BirthDate
              );
            }
          }

          this.guestsAll = res.Guests;
        }
      });
  }

  loadDataDelayed() {
    if (this.loadingDelay != null) {
      clearTimeout(this.loadingDelay);
    }

    this.loadingDelay = setTimeout(() => {
      this.loadData();
    }, 1000);
  }

  showReservation(id) {
    this.router.navigate(["/guest-info"], { queryParams: { id: id } });
  }

  showNextDay() {
    this.setNextDay();
    this.setDateControl();
    this.loadDataDelayed();
  }

  showPreviousDay() {
    debugger;
    super.setPreviousDay();
    this.setDateControl();
    this.loadDataDelayed();
  }

  scrollToTop() {
    this.top.nativeElement.scrollIntoView();
  }

  showTopBtn() {
    const rect = this.top.nativeElement.getBoundingClientRect();

    if (rect.top < 120) {
      this.topBtn.nativeElement.classList.remove("inactive");
    } else {
      this.topBtn.nativeElement.classList.add("inactive");
    }
  }

  dateTimeFormatToTime(dateTime: Date) {
    if (dateTime != null) {
      let result = this.datePipe.transform(dateTime, 'HH:mm');
      return result;
    }
    else {
      return '';
    }
  }

  public setRangeFrom() {
    debugger;
    let startDate = this.rangeDate.value.start;
    if (startDate != null) {
      this.dateFrom = new Date(startDate);
      this.dateTo = new Date(startDate);
      this.dateTo.setDate(this.dateTo.getDate() + 1);
      this.loadData();
    }
  }

  private setDateControl() {
    this.rangeDate.patchValue({ start: new Date(this.dateFrom.toISOString()) });
  }

  //showNextDay() {
  //  this.setNextDay();
  //  setTimeout(() => {
  //    this.loadData();
  //  }, 1000);
  //}

  //showPreviousDay() {
  //  super.setPreviousDay();
  //  setTimeout(() => {
  //    this.loadData();
  //  }, 1000);
  //}

  //public changeDateFrom() {
  //  super.changeDateFrom();
  //  this.loadData();
  //}

  filterGuests() {
    debugger;
    this.guests = this.guestsAll.filter((item) => {
      if (
        item.Person?.FirstName != null &&
        item.Person?.FirstName !== "" &&
        item.Person.FirstName?.indexOf(this.guestName) !== -1
      ) {
        return true;
      } else if (
        item.Person?.LastName != null &&
        item.Person?.FirstName !== "" &&
        item.Person.LastName?.indexOf(this.guestName) !== -1
      ) {
        return true;
      }
    });
    //this.loadData();
  }

  closeModal(modalId: string) {
    this.modalService.close(modalId);
  }

  showGuestInfo(modalId: string, guestGuid: string, serviceGuid: string) {
    debugger;
    let guest = this.guestRespDTO.Guests.filter((item) => {
      return item.Guid === guestGuid;
    });

    if (guest != null && guest.length > 0) {
      this.guestFirstLastName =
        guest[0].Person.FirstName + " " + guest[0].Person.LastName;
    }

    if (serviceGuid !== "") {
      this.serviceService
        .getById(this.propertyGuid, serviceGuid)
        .subscribe((res) => {
          debugger;
          if (res.Success && res.Services !== null && res.Services.length > 0) {
            this.roomNo = res.Services[0].Room?.Number;

            if (res.Services[0].StartDateTime != null) {
              let startDateTime = new Date(res.Services[0].StartDateTime);
              this.startDate =
                startDateTime.getDate() +
                "/" +
                startDateTime.getMonth() +
                "/" +
                startDateTime.getFullYear();
              this.startTime = this.dateTimeFormatToTime(startDateTime);
            }

            if (res.Services[0].EndDateTime != null) {
              let endDateTime = new Date(res.Services[0].EndDateTime);
              this.endDate =
                endDateTime.getDate() +
                "/" +
                endDateTime.getMonth() +
                "/" +
                endDateTime.getFullYear();
              this.endTime = this.dateTimeFormatToTime(endDateTime);
            }

            this.adultGuestCount =
              res.Services[0].AdultGuestCount +
              res.Services[0].SchoolChildGuestCount;
            this.amount = res.Services[0].OriginalAmount =
              res.Services[0].DiscountAmount;
            //this.serviceKind = res.Services[0].AdditionalServices[].ServiceKind.Name;
            let additionalServices = res.Services[0].AdditionalServices.map(
              (item) => {
                return item.ServiceKind.Name;
              }
            );
            this.serviceKind = additionalServices.join();
            //Pobyt: Services[].AdditionalServices[].ServiceKind.Name(należy złączyć, oddzielając przecinkami)

            this.modalService.open(modalId);
          }
        });
    }
  }

  hasBirthday(birthDay: string) {
    // debugger;
    //console.log(birthDay);
    if (birthDay != null && birthDay !== "") {
      let birthDayTmp = new Date(birthDay);
      birthDayTmp.setHours(0, 0, 0, 0);

      const dateToday = new Date();
      dateToday.setHours(0, 0, 0, 0);

      if (birthDayTmp === dateToday) {
        return true;
      }
    }

    return false;
  }
}
