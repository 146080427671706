import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

import { AppConfig } from '../config/app.config';

import { CompaniesRespDTO } from '../dto/companies-resp.DTO';
import { GuestRespDTO } from '../dto/guest-resp.DTO';


@Injectable({
  providedIn: 'root'
})

export class GuestService {

  constructor(private http: HttpClient, private datePipe: DatePipe) { }

  get(propertyGuid: string, dateFrom: Date, name: string): Observable<GuestRespDTO> {

    let querySearchParams = '';

    if (name !== null && name !== '') {
      querySearchParams += '&searchFilters.name=' + name;
    }

    let dateFromStr = this.datePipe.transform(dateFrom, 'yyyy-MM-dd');

    if (querySearchParams !== '') {
      return this.http.get<GuestRespDTO>(`${AppConfig.apiUrl}/Guest/ForDate/${propertyGuid}/${dateFromStr}` + querySearchParams);
    }
    else {
      return this.http.get<GuestRespDTO>(`${AppConfig.apiUrl}/Guest/ForDate/${propertyGuid}/${dateFromStr}`);
    }
  }
}
