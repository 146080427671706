import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";

//import { AuthenticationStateService } from '../../../shared/authentication-state.service';

import { ChatDTO } from "../../../dto/chat.DTO";
import { UserService } from "../../../services/user.service";
import { ChatService } from "../../../services/chat.service";

@Component({
  selector: "app-chat-messages",
  templateUrl: "./chat-messages.component.html",
  styleUrls: ["./chat-messages.component.scss"],
})
export class ChatMessagesComponent implements OnInit {
  //public userDTO: UserDTO;
  public chats: ChatDTO[] = [];
  public companyName: string = null;
  public userRole2 = "";
  public loading: boolean = false;
  private propertyGuid = "";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private chatService: ChatService
  ) {
    // private authenticationStateService: AuthenticationStateService) {
    //this.campaigns = [];
    //this.userDTO = null;
  }

  ngOnInit() {
    this.propertyGuid = this.userService.getProperty();
    //let sellerIdParam = parseInt(this.route.snapshot.queryParamMap.get("id"));
    this.loadData();
  }

  loadData() {
    //let userDTO = this.authenticationStateService.getUser();

    this.chatService.getChatsList(this.propertyGuid).subscribe(
      (res) => {
        if (res.Success) {
          this.chats = res.Chats;

          for (let i = 0; i < this.chats.length; i++) {
            if (
              this.chats[i].Messages !== null &&
              this.chats[i].Messages.length > 0
            ) {
              this.chats[i].MessagePrev = this.chats[
                i
              ].Messages[0].Text.substring(0, 30);
              break;
            }
          }
        }
      },
      (error) => {}
    );
    //if (userDTO != null) {

    //  this.loading = true;

    //  this.campaignService.getBySellerId(userDTO.id, this.currentPage, this.pageSize).subscribe(res => {

    //    if (res.status === 'Ok') {
    //      this.campaigns = res.items;
    //      this.totalItems = res.allItemsCount;
    //      this.calculatePagesCount();
    //    }
    //    else {
    //      this.toastr.error(res.message, 'Error', { timeOut: 2000, positionClass: 'toast-center-center' })
    //    }

    //    this.loading = false;
    //  },
    //    error => {
    //      this.loading = false;

    //    });
    //}
  }

  refreshData() {
    this.loadData();
  }

  showChat(guid) {
    debugger;
    this.router.navigate(["/chat"], { queryParams: { id: guid } });
  }

  selectChatRole(userRole2: string) {
    this.userRole2 = userRole2;

    this.createNewChat();
  }

  createNewChat() {
    // jak utworzyć nowy chat?
    // this.router.navigate(['/chat'], { queryParams: { id: guid } });
    //let userRole1 = 'Manager';
    debugger;
    //let userRole2 = 'Housekeeper';
    //let user = this.userService.getUser();
    this.userService.get(this.userService.getProperty()).subscribe((res) => {
      debugger;
      if (res.Success) {
        let userRole1 = res.UserRoles;

        this.chatService
          .getChatUserRoles(this.propertyGuid, userRole1, this.userRole2)
          .subscribe((resChat) => {
            debugger;
            if (resChat.Success) {
              if (resChat.Chats != null && resChat.Chats.length > 0) {
                let newChatGuid = resChat.Chats[0].Guid;
                this.router.navigate(["/chat"], {
                  queryParams: { id: newChatGuid, userRole2: this.userRole2 },
                });
              }
            }
          });
      }
    });
  }
}
