import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe, Location } from "@angular/common";
import { DateAdapter } from "@angular/material/core";

import { BaseComponent } from "../../shared/base/base.component";

import { HousekeepingService } from "../../../services/housekeeping.service";
import { UserService } from "../../../services/user.service";

import { GetHousekeepingStatusResponseDTO } from "../../../dto/get-housekeeping-status-response.DTO";
import { HousekeepingDataDTO } from "../../../dto/housekeeping-data.DTO";
import { RoomDTO } from "../../../dto/room.DTO";
import { RoomService } from "../../../services/room.service";
import { ServiceKindDTO } from "../../../dto/service-kind.DTO";
import { ModalService } from "../../../services/modal.service";
import { AuthenticationStateService } from "../../../shared/authentication-state.service";

/*
 * Widok 14
 */

@Component({
  selector: "app-housekeeping-ext",
  templateUrl: "./housekeeping-ext.component.html",
  styleUrls: ["./housekeeping-ext.component.scss"],
})
export class HousekeepingExtComponent extends BaseComponent implements OnInit {
  //public userDTO: UserDTO;
  public housekeepingStatusResponseDTO: GetHousekeepingStatusResponseDTO = null;
  // public houseKeepingDataDTOs: HousekeepingDataDTO[] = [];
  public houseKeepingDataDTO: HousekeepingDataDTO = null;
  public rooms: RoomDTO[] = [];
  private roomId = "";
  public selectedHouseKeeping: HousekeepingDataDTO = null;
  public preparationDispositions: string[];
  public companyName: string = null;
  public dateFilter: Date = null;
  public loading: boolean = false;
  public roomDTO: RoomDTO = null;
  public serviceKindDTOs: ServiceKindDTO[] = [];

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    private housekeepingService: HousekeepingService,
    protected datePipe: DatePipe,
    protected authenticationStateService: AuthenticationStateService,
    protected userService: UserService,
    protected dateAdapter: DateAdapter<Date>,
    protected location: Location,
    private roomService: RoomService,
    protected modalService: ModalService
  ) {
    super(router, datePipe, userService, dateAdapter, location, modalService, authenticationStateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.dateFilter = new Date();
    debugger;
    let roomIdParam = this.route.snapshot.queryParamMap.get("id");

    if (roomIdParam !== "") {
      this.roomId = roomIdParam;
      this.loadData();
    }
  }

  loadData() {
    //this.roomService.get(this.propertyGuid).subscribe(res => {
    //  debugger;
    //  if (res.Success) {
    //    this.rooms = res.Rooms;
    //  }
    //});
    let roomGuid = this.roomId;

    this.housekeepingService
      .getByRoom(this.propertyGuid, this.roomId)
      .subscribe((res) => {
        debugger;
        if (res.Success) {
          //this.companies = res.Companies;
          //this.housekeepingStatusResponseDTO = res;
          if (res.HousekeepingData != null && res.HousekeepingData.length > 0) {
            this.houseKeepingDataDTO = res.HousekeepingData.filter((item) => {
              return item.Room.Guid === roomGuid;
            })[0];

            this.preparationDispositions =
              this.houseKeepingDataDTO.PreparationDispositions;

            //this.housekeepingService.getMinibar(this.propertyGuid).subscribe(res => {
            //  debugger;
            //  if (res.Success) {
            //    this.serviceKindDTOs = res.ServiceKinds;
            //  }
            //}, error => {
            //  console.error(error);
            //});
          }
        }
      });

    //this.housekeepingService.get(this.propertyGuid).subscribe(res => {
    //  debugger;
    //  if (res.Success) {
    //    //this.companies = res.Companies;
    //    //this.housekeepingStatusResponseDTO = res;
    //    this.roomDTO = res.HousekeepingData[0].Room[0];
    //    this.preparationDispositions = res.HousekeepingData[0].PreparationDispositions;
    //  }

    //});

    //this.housekeepingService.getCleaning(this.propertyGuid).subscribe(res => {
    // // debugger;
    //  if (res.Success) {
    //    //this.companies = res.Companies;
    //  }

    //});

    //this.housekeepingService.getCleaningForUser(this.propertyGuid).subscribe(res => {
    // // debugger;
    //  if (res.Success) {
    //    //this.companies = res.Companies;
    //  }

    //});
    //if (userDTO != null) {

    //  this.loading = true;

    //  this.campaignService.getBySellerId(userDTO.id, this.currentPage, this.pageSize).subscribe(res => {

    //    if (res.status === 'Ok') {
    //      this.campaigns = res.items;
    //      this.totalItems = res.allItemsCount;
    //      this.calculatePagesCount();
    //    }
    //    else {
    //      this.toastr.error(res.message, 'Error', { timeOut: 2000, positionClass: 'toast-center-center' })
    //    }

    //    this.loading = false;
    //  },
    //    error => {
    //      this.loading = false;

    //    });
    //}
  }

  changeRoom() {
    if (this.selectedHouseKeeping != null) {
    }
  }

  refreshData() {
    this.loadData();
  }

  showCompany(id) {
    this.router.navigate(["/company"], { queryParams: { id: id } });
  }

  nextDay() {
    this.dateFilter.setDate(this.dateFilter.getDate() + 1);
  }

  addFault() {
    debugger;
    this.router.navigate(["/maintenance-add"], {
      queryParams: { id: this.houseKeepingDataDTO.Room?.Guid },
    });
  }

  showLostAndFound() {
    debugger;
    this.router.navigate(["/lost-and-found-add"], {
      queryParams: { id: this.houseKeepingDataDTO.Room?.Guid },
    });
  }

  showMinibar() {
    this.router.navigate(["/minibar"], {
      queryParams: { id: this.houseKeepingDataDTO.Room?.Guid },
    });
  }

  setStatus(status) {
    this.housekeepingService
      .setHouseKeepingStatus(this.propertyGuid, this.roomId, status)
      .subscribe((res) => {
        debugger;
        this.loadData();
      });
  }
}
