<div class="reservation-form">
  <app-header></app-header>
  <div class="main-page">


    <section class="section-page-mobile">
      <div class="section-reservation-mobile-top">
        <div class="container-mobile-2">
          <div class="section-reservation-mobile-header-icon-1">
            <a (click)="goBack()"><img class="header-page-user" src="assets/left.png" alt="" title=""></a>
          </div>
          <div class="section-reservation-mobile-header-icon">
            <h1 class="section-reservation-mobile-header-1">Status pokoi</h1>
          </div>
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showInfo('')"><img class="header-page-user" src="assets/icon-info.svg" alt="" title=""></a>
          </div>
        </div>
      </div>
      <div class="section-reservation-mobile-top">

        <!-- <div class="container-mobile">
          <div class="window-mobile-page-content-main">
            <div class="page-mobile-container" #top> -->

        <div class="container-mobile">
          <div class="window-mobile-page-content-main">
            <div class="page-mobile-container">
              <!-- wyszukaj -->
              <div class="page-mobile-container-section-light searcher">
                <a>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.33 23.33">
                    <defs>
                      <style>
                        .cls-1 {
                          fill: none;
                          stroke: #bdb9c0;
                          stroke-linecap: round;
                          stroke-linejoin: round;
                          stroke-width: 2px;
                        }
                      </style>
                    </defs>
                    <g id="Warstwa_2" data-name="Warstwa 2">
                      <g id="Warstwa_1-2" data-name="Warstwa 1">
                        <g id="Group_164" data-name="Group 164">
                          <circle id="Ellipse_35" data-name="Ellipse 35" class="cls-1" cx="10.28" cy="10.28" r="9.28" />
                          <line id="Line_85" data-name="Line 85" class="cls-1" x1="22.33" y1="22.33" x2="16.83"
                            y2="16.83" />
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
                <input type="text" [(ngModel)]="roomName" placeholder="Wyszukaj" #ctrl="ngModel"
                  (keydown.enter)="filterRooms()" />
                <a (click)="sortRooms()">
                  <img src="assets/8_Goście_03.png">
                </a>
              </div>
              <!-- wyszukaj END -->
            </div>
          </div>
        </div>


        <div class="container-mobile-2 day d-none">
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showPreviousDay()"><img class="header-page-user" src="assets/prev.png" alt="" title=""></a>
          </div>
          <!--<div class="section-reservation-mobile-header-icon-1">
            <a (click)="showCalendar()"><img class="header-page-user" src="assets/icon_03.png" alt="" title=""></a>
          </div>-->
          <div class="section-reservation-mobile-header-icon">
            <h1 class="section-reservation-mobile-header-1">
              <div class="d-flex flex-column">
                <span class="d-block">{{ currentDayName
                  }}, </span>
                <!-- <input type="date" [(ngModel)]="dateFromFilter" class="section-reservation-mobile-input" value="{{ dateFromFilter | date:'yyyy-MM-dd' }}" #ctrl="ngModel" required (ngModelChange)="changeDateFrom()" /> -->
                <div class="d-flex">
                  <mat-form-field [formGroup]="rangeDate" (click)="picker.open()">
                    <input matInput formControlName="start" [matDatepicker]="picker" (dateChange)="setRangeFrom()"
                      disabled>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                    <mat-error *ngIf="rangeDate.controls.start.hasError('matStartDateInvalid')">Invalid start date
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </h1>
          </div>
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showNextDay()"><img class="header-page-user" src="assets/icon_06.png" alt="" title=""></a>
          </div>
        </div>

      </div>
      <div class="section-page-mobile-content" (scroll)="showTopBtn()">
        <div class="container-mobile">
          <div class="window-mobile-page-content-main">
            <div class="page-mobile-container" #top>
              <div [ngClass]="{
                   'room-status-clean': item.RoomStatusKind === 'Clean',
                   'room-status-clean-oc': item.RoomStatusKind === 'OccupiedClean',
                   'room-status-service': item.RoomStatusKind === 'OutOfService',
                   'room-status-active': item.RoomStatusKind === 'CleanUnchecked',
                   'room-status-neutral': item.RoomStatusKind === 'Deleted',
                   'room-status-deactive': item.RoomStatusKind === 'Dirty' || item.RoomStatusKind === 'OccupiedDirty' || item.RoomStatusKind === 'OccupiedForceCleaning' || item.RoomStatusKind === 'OccupiedToCheck'
                   }" (click)="showRoomStatus(item.Room?.Guid)" *ngFor="let item of houseKeepingData">
                <div class="col20">{{ item.Room?.Number }}</div>
                <div class="col40 number">{{ item?.RoomStatusDisplayText }}</div>
                <div class="col20">{{ item.Room?.Kind?.Name }}</div>

                <!--<div class="col30 status">CHECKED IN</div>-->
                <div class="col10 dots">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                    <path
                      d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                  </svg>
                </div>
              </div>
              <div style="height: 50px;"></div>

              <!--<div class="room-status-neutral">
                              <div class="col20 number">001</div>
                              <div class="col20">TWIN</div>
                              <div class="col50 status">CHECKED IN</div>
                              <div class="col10 dots">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                  <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                </svg>
                              </div>
                            </div>
                            <div class="room-status-active">
                              <div class="col20 number">001</div>
                              <div class="col20">TWIN</div>
                              <div class="col50 status">CHECKED IN</div>
                              <div class="col10 dots">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                  <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                </svg>
                              </div>
                            </div>
                            <div class="room-status-neutral">
                              <div class="col20 number">001</div>
                              <div class="col20">TWIN</div>
                              <div class="col50 status">CHECKED IN</div>
                              <div class="col10 dots">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                  <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                </svg>
              =======

                            <div *ngFor="let item of houseKeepingData">
                              <div class="room-status-neutral" *ngIf="item.RoomStatusKind === 'OccupiedDirty'"
                                   (click)="showRoomStatus(item.id)">
                                <div class="col20 number">{{ item.RoomStatusKind }}</div>
                                <div class="col20">{{ item.Room?.Kind?.Name }}</div>
                                <div class="col50 status">CHECKED IN</div>
                                <div class="col10 dots">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                  </svg>
                                </div>
              >>>>>>> 2be76cb96cf1d41d9a2e1fe50a9293428ef07e38
                              </div>
                              <div class="room-status-active" *ngIf="item.RoomStatusKind === 'OccupiedClean'"
                                   (click)="showRoomStatus(item.id)" >
                                <div class="col20 number">{{ item.RoomStatusKind }}</div>
                                <div class="col20">{{ item.Room?.Kind?.Name }}</div>
                                <div class="col50 status">CHECKED IN</div>
                                <div class="col10 dots">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                  </svg>
                                </div>
                              </div>

                              <div class="room-status-deactive" *ngIf="item.RoomStatusKind === 'OccupiedClean'"
                                   (click)="showRoomStatus(item.id)" >
                                <div class="col20 number">{{ item.RoomStatusKind }}</div>
                                <div class="col20">{{ item.Room?.Kind?.Name }}</div>
                                <div class="col50 status">CHECKED IN</div>
                                <div class="col10 dots">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                  </svg>
                                </div>
                              </div>
                            </div>
                            <!--<div class="room-status-neutral">
                  <div class="col20 number">001</div>
                  <div class="col20">TWIN</div>
                  <div class="col50 status">CHECKED IN</div>
                  <div class="col10 dots">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                    </svg>
                  </div>
                </div>
                <div class="room-status-active">
                  <div class="col20 number">001</div>
                  <div class="col20">TWIN</div>
                  <div class="col50 status">CHECKED IN</div>
                  <div class="col10 dots">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                    </svg>
                  </div>
                </div>
                <div class="room-status-neutral">
                  <div class="col20 number">001</div>
                  <div class="col20">TWIN</div>
                  <div class="col50 status">CHECKED IN</div>
                  <div class="col10 dots">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                    </svg>
                  </div>
                </div>
                <div class="room-status-neutral">
                  <div class="col20 number">001</div>
                  <div class="col20">TWIN</div>
                  <div class="col50 status">CHECKED IN</div>
                  <div class="col10 dots">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                    </svg>
                  </div>
                </div>
                <div class="room-status-deactive">
                  <div class="col20 number">001</div>
                  <div class="col20">TWIN</div>
                  <div class="col50 status">CHECKED IN</div>
                  <div class="col10 dots">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                    </svg>
                  </div>
                </div>
                <div class="room-status-deactive">
                  <div class="col20 number">001</div>
                  <div class="col20">TWIN</div>
                  <div class="col50 status">CHECKED IN</div>
                  <div class="col10 dots">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                    </svg>
                  </div>
                </div>-->

            </div>
          </div>
        </div>
      </div>

    </section>
  </div>
  <app-footer></app-footer>
</div>
