<div class="reservation-form">
  <app-header></app-header>
  <div class="main-page">

    <section class="section-page-mobile">
      <div class="section-page-mobile-content">
        <div class="container-mobile">
          <div class="window-mobile-page-content-main">
            <div class="page-mobile-container">

              <div class="module-chat">
                <p class="close"><a (click)="closeChat()"><img src="../../../../assets/icon-close-3.svg"
                      alt="Placeholder"></a></p>
                <div class="header">
                  <h2>
                    {{this.userGroupName}}<span (click)="showInfo(null)">
                      <img src="../../../../assets/icon-info.svg"
                           alt="Placeholder">
                    </span>
                  </h2>
                </div>
                <ul class="list">

                  <li *ngFor="let item of chatMessages">
                    <h3>{{ item.User.LastName }} {{ item.User.FirstName }}</h3>
                    <p>{{ item.Text }}</p>
                    <p class="date">{{ item.CreationDateTime }}</p>
                  </li>

                </ul>


              </div>

            </div>
          </div>
        </div>
      </div>

    </section>

  </div>
  <footer class="footer-mobile-down">

    <div class="container-mobile">

      <div class="footer-mobile-down-content">

        <div class="form-chat">
          <p>
            <label for="fa">Treść wiadomości</label>
            <input type="text" name="fa" id="fa" [(ngModel)]="newMessage" #ctrl="ngModel"
              placeholder="Treść wiadomości">
          </p>
          <p><button (click)="send()">Wyślij</button></p>
        </div>
      </div>
    </div>

  </footer>
  <!-- <app-footer></app-footer> -->
</div>
