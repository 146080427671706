import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe, Location } from '@angular/common';
import { DateAdapter } from '@angular/material/core';

import { DateHelper } from '../../../utils/date.helper';

import { UserService } from '../../../services/user.service';
import { OccupancyService } from '../../../services/occupancy.service';

import { OccupancyReportDayDTO } from '../../../dto/occupancy-report-day.DTO';
import { AdrService } from '../../../services/adr.service';
import { ReservationService } from '../../../services/reservation.service';
import { ConferenceDTO } from '../../../dto/conference.DTO';
import { BaseComponent } from '../../shared/base/base.component';
import { FormControl } from '@angular/forms';
import { ModalService } from '../../../services/modal.service';
import { AuthenticationStateService } from '../../../shared/authentication-state.service';

@Component({
  selector: 'app-main-panel',
  templateUrl: './main-panel-director.component.html',
  styleUrls: ['./main-panel-director.component.scss']
})

export class MainPanelDirectorComponent extends BaseComponent implements OnInit {

/*  public propertyGuid = '';*/
  public occupancyReportDays: OccupancyReportDayDTO[] = [];
 // public occupancyReportDay: OccupancyReportDayDTO = null;
  public loading: boolean = false;
  private loadingDelay = null;

  public adrForPeriod: number = 0;
  public conferences: ConferenceDTO[] = [];

  public occupiedPercentage = 0;
  public checkins = 0;
  public checkouts = 0;
  public inHotelGuestsCount = 0;

  public rangeDate = new FormGroup({
    start: new FormControl()
  });

  constructor(private route: ActivatedRoute, protected router: Router,
    protected authenticationStateService: AuthenticationStateService, private occupancyService: OccupancyService,
    protected userService: UserService, private adrService: AdrService, private reservationService: ReservationService,
    protected datepipe: DatePipe, protected dateAdapter: DateAdapter<Date>, protected location: Location,
    protected modalService: ModalService) {
    super(router, datepipe, userService, dateAdapter, location, modalService, authenticationStateService);
  }

  ngOnInit() {
    debugger;
    let dateFromParam = this.route.snapshot.queryParamMap.get("dateFrom");
    if (dateFromParam != null && dateFromParam !== '') {
      this.dateFrom = new Date(parseInt(dateFromParam));
      this.dateTo = new Date(parseInt(dateFromParam));
    }

    super.ngOnInit();
    this.setDateControl();

    this.loadData();
  }

  initDate() {

    this.setDate(this.dateFrom.getDate(), true);
    this.setDateTo(this.dateFrom.getDate(), true);
  }

  loadData() {
 
    this.occupancyReportDays = [];
  //  this.occupancyReportDay = null;
    this.occupiedPercentage = 0;
    this.checkins = 0;
    this.checkouts = 0;
    this.inHotelGuestsCount = 0;

    this.occupancyService.get(this.propertyGuid, this.dateFrom, this.dateTo).subscribe(res => {
      debugger;
      if (res.Success) {
        this.occupancyReportDays = res.OccupancyReportDays;

        if (this.occupancyReportDays != null && this.occupancyReportDays.length > 0) {
          /*this.occupancyReportDay = this.occupancyReportDays[0];*/
          for (let i = 0; i < this.occupancyReportDays.length; i++) {
            this.occupiedPercentage += this.occupancyReportDays[i].OccupiedPercentage;
            this.checkins += this.occupancyReportDays[i].Checkins;
            this.checkouts += this.occupancyReportDays[i].Checkouts;
            this.inHotelGuestsCount += this.occupancyReportDays[i].InHotelGuestsCount;
          }
        }
      }
    }, error => {
     // debugger;
      console.error(error);
    });

    this.adrService.get(this.propertyGuid, this.dateFrom, this.dateTo).subscribe(res => {
     // debugger;
      if (res.Success) {
        this.adrForPeriod = res.ADRForPeriod;
      }
    })

    this.reservationService.getConferenceRoom(this.propertyGuid, this.dateFrom, this.dateTo).subscribe(res => {
     // debugger;
      if (res.Success && res.Reservations != null) {
        this.conferences = [];
        let has3Items = false;

        for (let i = 0; i < res.Reservations.length; i++) {
          for (let j = 0; j < res.Reservations[i].Services.length; j++) {

            let conferenceDTO: ConferenceDTO = new ConferenceDTO();
            conferenceDTO.ConferenceRoom = res.Reservations[i].Services[j].ConferenceRoom?.Name + ' ' + res.Reservations[i].Services[j].ConferenceRoom?.VariantName;
            conferenceDTO.ReservingCompany = '';
            conferenceDTO.ReservingPerson = '';

            conferenceDTO.ReservingCompany = res.Reservations[i].ReservingCompany?.Name;
            
            if (conferenceDTO.ReservingCompany === '' || conferenceDTO.ReservingCompany == null) {
              conferenceDTO.ReservingPerson = res.Reservations[i].ReservingPerson?.FirstName + ' ' + res.Reservations[i].ReservingPerson?.LastName;
            }
            conferenceDTO.AdultGuestCount = res.Reservations[i].Services[j].AdultGuestCount;
       
            this.conferences.push(conferenceDTO);
            if (this.conferences.length > 2) {
              has3Items = true;
              break;
            }
          }
          if (has3Items) {
            break;
          }
        }
      }
    });
  }

  loadDataDelayed() {
    if (this.loadingDelay != null) {
      clearTimeout(this.loadingDelay);
    }

    this.loadingDelay = setTimeout(() => {
      this.router.navigate(['/main-panel-director'], { queryParams: { dateFrom: Date.parse(this.dateFrom.toISOString()) } });
      this.loadData();
    }, 1000);
  }

  showNextDay() {
    this.setNextDay();
    this.setDateControl();
    this.loadDataDelayed();
  }

  showPreviousDay() {
   // debugger;
    super.setPreviousDay();
    this.setDateControl();
    this.loadDataDelayed();
  }

  //public changeDateFrom() {
  //  super.changeDateFrom();

  //  this.loadData();
  //}

  //public changeDateTo() {
  //  super.changeDateTo();

  //  this.loadData();
  //}
  public setRangeFrom() {
   //  debugger;
    let startDate = this.rangeDate.value.start;
    if (startDate != null) {
      this.dateFrom = new Date(startDate);
      this.dateTo = new Date(startDate);
     // this.dateTo.setDate(this.dateTo.getDate() + 1);
      this.router.navigate(['/main-panel-director'], { queryParams: { dateFrom: Date.parse(this.dateFrom.toISOString()) }});
      this.loadData();
    }
  }

  goToCompanies() {
  //  debugger;
    let dateFrom = this.dateFrom.toISOString();
    this.router.navigate(['/companies'], { queryParams: { dateFrom: Date.parse(this.dateFrom.toISOString()) } });
  }

  goToGuests() {
    this.router.navigate(['/guests'], { queryParams: { dateFrom: Date.parse(this.dateFrom.toISOString()) } });
  }

  showAdr() {
    this.router.navigate(['/adr'], { queryParams: { dateFrom: Date.parse(this.dateFrom.toISOString()) } });
  }

  showConferences() {
    this.router.navigate(['/conferences'], { queryParams: { dateFrom: Date.parse(this.dateFrom.toISOString()) } });
  }

  showRevenue() {
    this.router.navigate(['/revenue'], { queryParams: { dateFrom: Date.parse(this.dateFrom.toISOString()) } });
  }

  showOccupancy() {
    this.router.navigate(['/occupancy'], { queryParams: { dateFrom: Date.parse(this.dateFrom.toISOString()) } });
  }

  private setDateControl() {
    this.rangeDate.patchValue({ start: new Date(this.dateFrom.toISOString()) });
  }

  private setConferenceName(name: string) {
    if (name != null && name !== '' && name.length > 30) {
      return name.substring(0, 30) + '...';
    }
    else {
      return name;
    }
  }
}
