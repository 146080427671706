import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe, Location } from '@angular/common';
import { DateAdapter } from '@angular/material/core';

import { GuestService } from '../../../services/guest.service';
import { UserService } from '../../../services/user.service';
import { LostAndFoundService } from '../../../services/lost-and-found.service';

import { GetHousekeepingStatusResponseDTO } from '../../../dto/get-housekeeping-status-response.DTO';
import { BaseComponent } from '../../shared/base/base.component';
import { FormControl } from '@angular/forms';
import { LostFoundItemDTO } from '../../../dto/lost-found-item.DTO';
import { ModalService } from '../../../services/modal.service';
import { AuthenticationStateService } from '../../../shared/authentication-state.service';


/*
 * 19_Dziennik działań
 */
@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})

export class ActivityLogComponent extends BaseComponent implements OnInit {

  public housekeepingStatusResponseDTO: GetHousekeepingStatusResponseDTO = null;
  public dateFilter: Date = null;
  

  public loading: boolean = false;
  private loadingDelay = null;

  public rangeDate = new FormGroup({
    start: new FormControl()
  });

  constructor(private route: ActivatedRoute, protected router: Router, protected datePipe: DatePipe,
    protected authenticationStateService: AuthenticationStateService,
    protected userService: UserService, private guestService: GuestService, private lostAndFoundService: LostAndFoundService,
    protected dateAdapter: DateAdapter<Date>,
    protected location: Location, protected modalService: ModalService) {
    super(router, datePipe, userService, dateAdapter, location, modalService, authenticationStateService);
  }

  ngOnInit() {

    let dateFromParam = this.route.snapshot.queryParamMap.get("dateFrom");
    if (dateFromParam != null && dateFromParam !== '') {
      this.dateFrom = new Date(parseInt(dateFromParam));
      this.dateTo = new Date(parseInt(dateFromParam));
    }

    super.ngOnInit();
    this.setDateControl();
    this.loadData();
  }

  loadData() {
    //this.lostAndFoundService.get(this.propertyGuid).subscribe(res => {
    //  if (res.Success) {
    //    this.lostAndFoundItems = res.LostFoundItems;
    //  }
    //});
  }

  loadDataDelayed() {
    if (this.loadingDelay != null) {
      clearTimeout(this.loadingDelay);
    }

    this.loadingDelay = setTimeout(() => {
      this.loadData();
    }, 1000);
  }

  showNextDay() {
    this.setNextDay();
    this.setDateControl();
    this.loadDataDelayed();
  }

  showPreviousDay() {
    debugger;
    super.setPreviousDay();
    this.setDateControl();
    this.loadDataDelayed();
  }

  public setRangeFrom() {
     debugger;
    let startDate = this.rangeDate.value.start;
    if (startDate != null) {
      this.dateFrom = new Date(startDate);
      this.dateTo = new Date(startDate);
      this.dateTo.setDate(this.dateTo.getDate() + 1);
      this.loadData();
    }
  }

  private setDateControl() {
    this.rangeDate.patchValue({ start: new Date(this.dateFrom.toISOString()) });
  }

  //showNextDay() {
  //  this.setNextDay();
  //  setTimeout(() => {
  //    this.loadData();
  //  }, 1000);
  //}

  //showPreviousDay() {
  //  super.setPreviousDay();
  //  setTimeout(() => {
  //    this.loadData();
  //  }, 1000);
  //}

  //public changeDateFrom() {
  //  super.changeDateFrom();
  //  this.loadData();
  //}

  openItem(itemName) {
    debugger;

    switch (itemName) {
      case 'cleaning_report': {
        //this.router.navigate(['/'])
        break;
      }
      case 'adds_on_report': {
        //statements; 
        break;
      }
      case 'items_found_report': {
        
        break;
      }
      case 'fault_report': {
        //statements; 
        break;
      }
      case 'list_items_found': {
        this.router.navigate(['/lost-and-found-list'], { queryParams: { dateFrom: Date.parse(this.dateFrom.toISOString()) } });
        break;
      }
      case 'fault_list': {
        this.router.navigate(['/fault-list'], { queryParams: { dateFrom: Date.parse(this.dateFrom.toISOString()) } });
        break;
      }
      case 'adds_on_list': {
        //statements; 
        break;
      }
      default: {
        //statements; 
        break;
      }
    }
  }
}
