import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

import { AppConfig } from '../config/app.config';

import { TokenAuthService } from '../shared/token-auth.service';

import { LostAndFoundRespDTO } from '../dto/lost-and-found.resp.DTO';
import { ChatMessageDTO } from '../dto/chat-message.DTO';
import { RespDTO } from '../dto/resp.DTO';
import { ChatsListRespDTO } from '../dto/chats-list-resp.DTO';
import { Guid } from 'guid-typescript';
import { ChatMessagesRespDTO } from '../dto/chat-messages.resp.DTO';
import { ChatRespDTO } from '../dto/chat-resp.DTO';


@Injectable({
  providedIn: 'root'
})

export class ChatService {

  constructor(private http: HttpClient, private tokenAuthService: TokenAuthService, private datePipe: DatePipe) { }

  getChatsList(propertyGuid: string): Observable<ChatsListRespDTO> {
    debugger;
    //let jwtJson = this.tokenAuthService.getJwtToken();
    //let jwt = JSON.parse(jwtJson);

    //var reqHeader = new HttpHeaders({
    //  'Content-Type': 'application/json',
    //  'Authorization': jwt.access_token
    //});
    return this.http.get<ChatsListRespDTO>(`${AppConfig.apiUrl}/Chat/${propertyGuid}`);
  }

  getMessagesForChat(chatGuid: string, dateFrom: Date, dateTo: Date): Observable<ChatMessagesRespDTO> {
    debugger;
    const dateFromStr = this.datePipe.transform(dateFrom, 'yyyy-MM-dd');
    const dateToStr = this.datePipe.transform(dateTo, 'yyyy-MM-dd');
    //let jwtJson = this.tokenAuthService.getJwtToken();
    //let jwt = JSON.parse(jwtJson);

    //var reqHeader = new HttpHeaders({
    //  'Content-Type': 'application/json',
    //  'Authorization': jwt.access_token
    //});

    return this.http.get<ChatMessagesRespDTO>(`${AppConfig.apiUrl}/Chat/${chatGuid}/Message/${dateFromStr}/${dateToStr}`);
  }

  add(chatGuid: string, chatMessageDTO: ChatMessageDTO) {

    //let jwtJson = this.tokenAuthService.getJwtToken();
    //let jwt = JSON.parse(jwtJson);

    //var reqHeader = new HttpHeaders({
    //  'Content-Type': 'application/json',
    //  'Authorization': jwt.access_token
    //});

    return this.http.post<RespDTO>(`${AppConfig.apiUrl}/Chat/${chatGuid}/Message`, chatMessageDTO);
  }

  /*
   * /v1/Chat/{propertyGuid}/{userRole1}/{userRole2}
   * Jeśli danej pary ról użytkowników komunikujących się ze sobą nie ma w bazie, zostaje ona utworzona i zwrócony zostaje utworzony czat.
   */
  getChatUserRoles(propertyGuid: string, userRole1: string, userRole2: string): Observable<ChatRespDTO> {
    return this.http.get<ChatRespDTO>(`${AppConfig.apiUrl}/Chat/${propertyGuid}/${userRole1}/${userRole2}`);
  }
}
