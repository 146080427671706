import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe, Location } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import { empty } from 'rxjs';

import { BaseComponent } from '../../../shared/base/base.component';

import { RoomService } from '../../../../services/room.service';
import { MaintenanceService } from '../../../../services/maintenance.service';
import { UserService } from '../../../../services/user.service';

import { MaintenanceDTO } from '../../../../dto/maintenance.DTO';
import { RoomDTO } from '../../../../dto/room.DTO';
import { DepartmentDTO } from '../../../../dto/department.DTO';
import { MaintenanceReqDTO } from '../../../../dto/maintenane.req.DTO';
import { HousekeepingDataDTO } from '../../../../dto/housekeeping-data.DTO';
import { ModalService } from '../../../../services/modal.service';
import { AuthenticationStateService } from '../../../../shared/authentication-state.service';


/*
 * Zgłoszenie usterki
 */
@Component({
  selector: 'app-maintenance-add',
  templateUrl: './maintenance-add.component.html',
  styleUrls: ['./maintenance-add.component.scss']
})
/*
 * 15. Zgłaszanie usterki
 */
export class MaintenanceAddComponent extends BaseComponent implements OnInit {

  public departments: DepartmentDTO[] = [];
  public rooms: RoomDTO[] = [];
  public houseKeepingDataDTO: HousekeepingDataDTO = null;
  public roomId = '';
  public content = '';
  public department = null;
  public loading: boolean = false;

  constructor(private route: ActivatedRoute, protected router: Router, protected datePipe: DatePipe,
    protected authenticationStateService: AuthenticationStateService, protected userService: UserService,
    private maintenanceService: MaintenanceService, protected dateAdapter: DateAdapter<Date>, private roomService: RoomService,
    protected location: Location, protected modalService: ModalService) {
    super(router, datePipe, userService, dateAdapter, location, modalService, authenticationStateService);
  }

  ngOnInit() {
    super.ngOnInit();
    debugger;
    let roomIdParam = this.route.snapshot.queryParamMap.get("id");

    this.loadData(roomIdParam);

    debugger;
    this.departments = this.userService.getDepartments();
    //this.loadData();
    if (this.departments != null && this.departments.length > 0) {

      this.department = this.departments.filter(item => {
        return item.name === this.department;
      })[0];
    }

  }

  loadData(roomIdParam: string) {
    //let userDTO = this.authenticationStateService.getUser();

    //let propertyGuid = 'fa11a7b5-8f20-41be-a400-c472ce0dd56f';
    //let propertyGuid = '92b94e93-339f-4f0a-b4f8-26a54c3e4d24';

    this.roomService.get(this.propertyGuid).subscribe(res => {
     // debugger;
      if (res.Success) {
        this.rooms = res.Rooms;

        this.roomId = roomIdParam;
        //const emptyItem: RoomDTO = new RoomDTO();
        //emptyItem.Guid = '';
        
        //this.rooms.push(emptyItem);
        //for (let i = 0; i < res.Rooms.length; i++) {
        //  this.rooms.push(res.Rooms[i]);
        //}
      }
    });

    //this.maintenanceService.get(propertyGuid).subscribe(res => {
    //  debugger;
    //  if (res.Success) {
    //    this.maintenances = res.Maintenances;
    //  }

    //});

  }

  send() {
    debugger;
   
    let maintenanceReqDTO: MaintenanceReqDTO = new MaintenanceReqDTO();
    maintenanceReqDTO.Maintenance = new MaintenanceDTO();
    maintenanceReqDTO.Maintenance.Room = new RoomDTO();
    maintenanceReqDTO.Maintenance.Room.Guid = this.roomId !== '' ? this.roomId : null; // "b15bf80b-218c-4bf3-b362-dcdfd9854914";
   // maintenanceReqDTO.Maintenance.Room.Number = this.roomNo;
    maintenanceReqDTO.Maintenance.Text = this.content;

    this.maintenanceService.add(this.propertyGuid, maintenanceReqDTO).subscribe(res => {
      debugger;
      if (res.Success) {
        this.content = '';
       // this.roomId = '';
      }
    });

  }

  cancel() {
    //let mainPanelUrl = this.userService.getMainPanelUrl();
    //this.router.navigate([mainPanelUrl]);
    super.goBack();
  }
}
