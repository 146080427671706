import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConfig } from '../config/app.config';

import { TokenAuthService } from '../shared/token-auth.service';

import { CompanyDTO } from '../dto/company.DTO';
import { RespGetItemsDTO } from '../dto/responses/resp-get-items.DTO';
import { PropertyRespDTO } from '../dto/property-resp.DTO';


@Injectable({
  providedIn: 'root'
})

export class PropertyService {

  constructor(private http: HttpClient, private tokenAuthService: TokenAuthService) { }

  setPropertyId(id: string) {
    this.tokenAuthService.setProperty(id);
  }

  getPropertyId() {
    const propertyId = this.tokenAuthService.getPropertyId();
    return propertyId;
  }

  get(): Observable<PropertyRespDTO> {
    debugger;
    //let jwtJson = this.tokenAuthService.getJwtToken();
    //let jwt = JSON.parse(jwtJson);

    //var reqHeader = new HttpHeaders({
    //  'Content-Type': 'application/json',
    //  'Authorization': jwt.access_token
    //});

    return this.http.get<PropertyRespDTO>(`${AppConfig.apiUrl}/Property`);
  }
}
