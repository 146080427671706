<!--<body class="reservation-form">-->
<!--<app-nav-menu></app-nav-menu>-->
<!--<div class="container">-->
<!--<main class="main-page">-->
<!--<router-outlet></router-outlet>-->
<!--</main>-->
<!--</body>-->
<!--<div class="reservation-form">
  <app-header></app-header>
  <div class="main-page">-->
<router-outlet></router-outlet>
<!--</div>
</div>-->
