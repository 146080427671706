import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConfig } from '../config/app.config';

import { RoomRespDTO } from '../dto/room-resp.DTO';

@Injectable({
  providedIn: 'root'
})
export class RoomService {

  constructor(private http: HttpClient) { }

  get(propertyGuid: string): Observable<RoomRespDTO> {
 
    return this.http.get<RoomRespDTO>(`${AppConfig.apiUrl}/Room/${propertyGuid}`);
  }
}
