import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

import { AppConfig } from '../config/app.config';

import { TokenAuthService } from '../shared/token-auth.service';

import { CompanyDTO } from '../dto/company.DTO';
import { AdrRespDTO } from '../dto/adr.resp.DTO';
import { ReservationRespDTO } from '../dto/reservation-resp.DTO';
import { ReportsRespDTO } from '../dto/reports.resp.DTO';
import { ReportParameterValueDTO } from '../dto/report-parameter-value.DTO';


@Injectable({
  providedIn: 'root'
})

export class ReportService {

  constructor(private http: HttpClient, private datePipe: DatePipe, private tokenAuthService: TokenAuthService) { }

  get(propertyGuid: string): Observable<ReportsRespDTO> {

    return this.http.get<ReportsRespDTO>(`${AppConfig.apiUrl}/Report/${propertyGuid}`);
  }

  getReport(propertyGuid: string, reportGuid: string): Observable<ReportsRespDTO> {

    

    return this.http.get<ReportsRespDTO>(`${AppConfig.apiUrl}/Report/${propertyGuid}/${reportGuid}`);
  }

  generatePdf(propertyGuid: string, reportGuid: string, reportParameterValues: ReportParameterValueDTO[]) {

    let parameters = '';
    debugger;
    for (let i = 0; i < reportParameterValues.length; i++) {

      if (reportParameterValues[i].ParamValue != null) {
        if (parameters === '') {

          //parameters = '?';
        }
        else {
          parameters += '&';
        }

        if (reportParameterValues[i].ParameterDTO.Kind === 'ListMultiselect') {

          parameters += reportParameterValues[i].ParameterDTO.Code + '=';

          for (let k = 0; k < reportParameterValues[i].ParamValue.length; k++) {
            if (k === 0) {
              parameters += reportParameterValues[i].ParamValue[k];
            }
            else {
              parameters += ';' + reportParameterValues[i].ParamValue[k];
            }
          }
        }
        else {
          parameters += reportParameterValues[i].ParameterDTO.Code + '=' + reportParameterValues[i].ParamValue;
        }

      }
    }

    //const headers = new HttpHeaders()
    // // .set("Content-Type", "application/json")
    //  .set("Accept", "application/pdf")
    //  .set("responseType", "blob");
   // const mediaType = 'application/pdf';

    return this.http.get(`${AppConfig.apiUrl}/Report/GeneratePdf/${propertyGuid}/${reportGuid}?${parameters}`, { responseType: 'blob' });
  }

  //getConferenceRoom(propertyGuid: string, dateFrom: Date, dateTo: Date): Observable<ReservationRespDTO> {
  //  debugger;
  //  let dateFromStr = this.datePipe.transform(dateFrom, 'yyyy-MM-dd');
  //  let dateToStr = this.datePipe.transform(dateTo, 'yyyy-MM-dd');

  //  return this.http.get<ReservationRespDTO>(`${AppConfig.apiUrl}/Reservation/Service/ConferenceRoom/${propertyGuid}?searchFilters.serviceMinDateTime=${dateFromStr}&searchFilters.serviceMaxDateTime=${dateToStr}`);
  //}

}
