import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";

import { UserService } from "../../../services/user.service";
import { AuthenticationStateService } from "../../../shared/authentication-state.service";
import { LoginService } from "../../../services/login.service";

import { ModalService } from "../../../services/modal.service";
import { PropertyService } from '../../../services/property.service';
import { PropertyDTO } from "../../../dto/property.DTO";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @ViewChild("divMenu") divMenu: ElementRef;

  department = "";
  properties: PropertyDTO[] = [];
  propertyId = '';

  isManager = false;
  isHsk = false;
  isTech = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private authenticationStateService: AuthenticationStateService,
    private loginService: LoginService,
    protected modalService: ModalService,
    private propertyService: PropertyService
  ) {}

  ngOnInit() {
   
    this.department = this.userService.getDepartment();
    this.propertyService.get().subscribe(res => {
      if (res.Success) {
        this.properties = res.Properties;
      }
    });

    this.authenticationStateService.getPropertyState().subscribe(res => {
      debugger;
        this.propertyId = res;
    });

    this.authenticationStateService
      .getUserAuthStateObservable()
      .subscribe((res) => {
        // debugger;
        if (res) {
          let department = this.userService.getDepartment();

          if (department === "Manager") {
            this.isManager = true;
          } else if (department === "HSK") {
            this.isHsk = true;
          } else if (department === "TECH") {
            this.isTech = true;
          }
        }
      });
  }

  showMainPanel() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';

    if (this.department === "Manager") {
      this.router.navigate(["/main-panel-director"]);
    } else if (this.department === "HSK") {
      this.router.navigate(["/main-panel-hsk"]);
    } else if (this.department === "TECH") {
      this.router.navigate(["/main-panel-tech"]);
    }
  }

  showActivitLog() {
    this.router.navigate(["/activit-log"]);
  }

  showChatMessages() {
    this.router.navigate(["/chat-messages"]);
  }

  closeMenu() {
    if (this.divMenu != null) {
      //"open", "animate__animated", "animate__fadeInRight", "animate__fast"
      this.divMenu.nativeElement.classList.remove("open");
      this.divMenu.nativeElement.classList.remove("animate__animated");
      this.divMenu.nativeElement.classList.remove("animate__fadeInRight");
      this.divMenu.nativeElement.classList.remove("animate__fast");
      this.divMenu.nativeElement.classList.add("close");
    }
  }

  showMenu() {
    if (this.divMenu != null) {
      this.divMenu.nativeElement.classList.add("open");
      this.divMenu.nativeElement.classList.remove("close");
      this.divMenu.nativeElement.classList.add("animate__animated");
      this.divMenu.nativeElement.classList.add("animate__fadeInRight");
      this.divMenu.nativeElement.classList.add("animate__fast");
    }
  }

  logout() {
    this.loginService.logout().subscribe((res) => {
      //debugger;
      /*    if (res != null && res !== 'OK') {*/
      this.authenticationStateService.setAuthState(false);

      this.router.navigate(["/login"]);
      /*}*/
    });
  }

  onPropertyChange() {
    debugger;
    this.propertyService.setPropertyId(this.propertyId);
    this.authenticationStateService.setPropertyState(this.propertyId);

    this.showMainPanel();
  }
}
