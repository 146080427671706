<div class="reservation-form">
  <app-header></app-header>
  <div class="main-page">

    <section class="section-page-mobile">
      <div class="section-reservation-mobile-top">
        <div class="container-mobile-2 day">
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showPreviousDay()"><img class="header-page-user" src="assets/prev.png" alt="" title=""></a>
          </div>
          <!--<div class="section-reservation-mobile-header-icon-1">
            <img class="header-page-user" src="assets/icon_03.png" alt="" title="">
          </div>-->
          <div class="section-reservation-mobile-header-icon">
            <h1 class="section-reservation-mobile-header-1">
              <div class="d-flex flex-column">
                <span class="d-block">{{ currentDayName}},</span>
                <div class="d-flex">
                  <mat-form-field [formGroup]="rangeDate" (click)="picker.open()">
                    <input matInput formControlName="start" [matDatepicker]="picker" (dateChange)="setRangeFrom()"
                      disabled>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                    <mat-error *ngIf="rangeDate.controls.start.hasError('matStartDateInvalid')">Invalid start date
                    </mat-error>
                  </mat-form-field>
                </div>

              </div>

            </h1>
          </div>
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showNextDay()"><img class="header-page-user" src="assets/icon_06.png" alt="" title=""></a>
          </div>
        </div>
      </div>


      <div class="section-page-mobile-content">
        <div class="container-mobile">
          <div class="window-mobile-page-content-main">
            <div class="page-mobile-container flex">
              <div class="page-mobile-container-col2 ">
                <div class="page-mobile-container page-mobile-container-section-dark my-tile" (click)="showAdr()">
                  <div class="page-mobile-container-col">
                    <img src="assets/icon_11.png">
                  </div>
                  <div>
                    <h2 class="page-mobile-container-section-dark-header">ADR</h2>
                    <p>{{ formatDoubleValue(adrForPeriod) }} PLN</p>
                  </div>
                </div>
              </div>
              <div class="page-mobile-container-col2 ">
                <div class="page-mobile-container page-mobile-container-section-dark my-tile" (click)="showOccupancy()">
                  <div class="page-mobile-container-col">
                    <img src="assets/icon_13.png">
                  </div>
                  <div>
                    <h2 class="page-mobile-container-section-dark-header">Obłożenie</h2>
                    <p> {{ formatPercentageValue(occupiedPercentage) }} % </p>

                  </div>
                </div>
              </div>
            </div>

            <div class="page-mobile-container flex">
              <div class="page-mobile-container-col2 ">
                <div class="page-mobile-container">
                  <h2 class="section-reservation-mobile-header">Hotel status</h2>
                </div>

              </div>
              <div class="page-mobile-container-col2 ">
                <div class="page-mobile-container">
                  <h2 class="section-reservation-mobile-header">Raporty</h2>
                </div>

              </div>
            </div>
            <div class="page-mobile-container flex">
              <div class="page-mobile-container-col2 ">
                <div class="page-mobile-container-section-light">
                  <div class="col70">Przyjazdy</div>
                  <div class="col30">{{ checkins }}</div>
                </div>
                <div class="page-mobile-container-section-light">
                  <div class="col70">Wyjazdy</div>
                  <div class="col30">{{ checkouts }}</div>
                </div>
                <div class="page-mobile-container-section-light">
                  <div class="col70">Pobyty</div>
                  <div class="col30">{{ inHotelGuestsCount }}</div>
                </div>
              </div>
              <div class="page-mobile-container-col2 ">
                <div class="page-mobile-container-section-light">
                  <div class="col70">
                    Przychod total<br>
                    Pokoje<br>
                    F&B<br>
                    Konferencje<br>
                    SPA
                  </div>
                  <div class="col30 bottom" (click)="showRevenue()"><img src="assets/icon_18.png"></div>
                </div>
              </div>
            </div>

            <div class="container mt-3">

              <div class="row mt-2 d-flex align-items-center mb-4">
                <div class="col">
                  <h2 class="m-0 p-0">Konferencje</h2>
                </div>
                <div class="col">
                  <button class="show-more-btn mt-1" (click)="showConferences()">
                    Pokaż więcej
                  </button>
                </div>
              </div>



              <div class="row">

                <!-- single konf -->
                <div class="col-12 p-3 mb-3" *ngFor="let item of conferences"
                  style="background-color:#f6f6f6; border-radius: 5px;">
                  <div style="display: flex; width: 100%; ">
                    <p style="padding-right: 20px;">{{ item.ConferenceRoom }}</p><br />
                  </div>

                  <p style="font-weight: 600;" *ngIf="item.ReservingCompany !== ''">{{ item.ReservingCompany }}<br></p>
                  <p *ngIf="item.ReservingPerson !== ''">{{ item.ReservingPerson }}</p>

                  <div class="col20">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18" style="height: 12px; color: grey ">
                      <defs>
                        <style>
                          .cls-1 {
                            fill: none;
                            stroke: #414140;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 2px;
                          }
                        </style>
                      </defs>
                      <g id="Warstwa_2" data-name="Warstwa 2">
                        <g id="Warstwa_1-2" data-name="Warstwa 1">
                          <g id="Group_172" data-name="Group 172">
                            <circle id="Ellipse_25" data-name="Ellipse 25" class="cls-1" cx="8.18" cy="5.42" r="4.42" />
                            <path id="Path_110" data-name="Path 110" class="cls-1"
                              d="M1,17.58c0-4.42,1.1-7.74,5.68-7.74h3c4.57,0,5.68,3.32,5.68,7.74" />
                          </g>
                        </g>
                      </g>
                    </svg> {{ item.AdultGuestCount }}
                  </div>

                </div>
                <!-- single konf -->

              </div>
            </div>

            <div class="container mt-3">
              <div class="row files-buttons">

                <div class="files-buttons--btn col-6 border d-flex align-items-center p-3 bg-main text-white"
                  (click)="goToCompanies()">
                  <img src="assets/icon_21.png">
                  <p>Kartoteka firm</p>
                </div>

                <div class="files-buttons--btn col-6 border d-flex align-items-center p-3 bg-main text-white"
                  (click)="goToGuests()">
                  <img src="assets/icon_21.png">
                  <p>Kartoteka gości</p>
                </div>

              </div>
            </div>

            <div class="page-mobile-container mt-4 section-social-media">
              <div class="row d-flex section-social-media--wrapper">

                <div class="col-6 p-2 rounded-default border border-4 border-light bg-e9">
                  <div class="pb-2">Opinie</div>
                  <div class="social-media-items-wrapper">
                    <img alt="trip-advisor-logo" src="assets/img/social-media/trip-advisor-logo@0.5x.png">
                    <img alt="booking-logo" src="assets/img/social-media/booking-logo@0.5x.png">
                    <img alt="google-logo" src="assets/img/social-media/google-logo@0.5x.png">
                  </div>
                </div>

                <div class="col-6 p-2 rounded-default border border-4 border-light bg-e9">
                  <div class="pb-2">Social media</div>
                  <div class="social-media-items-wrapper">
                    <img alt="fb-logo" src="assets/img/social-media/fb-logo@0.5x.png">
                    <img alt="twitter-logo" src="assets/img/social-media/twitter-logo@0.5x.png">
                    <img alt="mess-logo" src="assets/img/social-media/mess-logo@0.5x.png">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>

  </div>
  <app-footer></app-footer>
</div>