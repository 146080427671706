import { Component, ElementRef, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe, Location } from '@angular/common';
import { DateAdapter } from '@angular/material/core';

import { BaseComponent } from '../../shared/base/base.component';

import { CompanyService } from '../../../services/company.service';

import { CompanyDTO } from '../../../dto/company.DTO';
import { UserService } from '../../../services/user.service';
import { ModalService } from '../../../services/modal.service';
import { ServiceService } from '../../../services/service.service';
import { FormControl } from '@angular/forms';
import { DescriptionService } from '../../../services/description.service';
import { AuthenticationStateService } from '../../../shared/authentication-state.service';


@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})

export class CompaniesComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('top') top: ElementRef;
  @ViewChild('topBtn') topBtn: ElementRef;

  public companies: CompanyDTO[] = [];
  public companiesAll: CompanyDTO[] = [];
  public company: CompanyDTO = null;
  public companyName: string = null;
  public loading: boolean = false;
  private loadingDelay = null;
  public amount = 0;
  public description: any = null;

  public rangeDate = new FormGroup({
    start: new FormControl()
  });

  constructor(private route: ActivatedRoute, protected router: Router,
    protected authenticationStateService: AuthenticationStateService, protected userService: UserService,
    private companyService: CompanyService, protected datePipe: DatePipe, protected modalService: ModalService,
    private serviceService: ServiceService, protected dateAdapter: DateAdapter<Date>, protected location: Location,
    protected descriptionService: DescriptionService) {
    super(router, datePipe, userService, dateAdapter, location, modalService, authenticationStateService);
  }


  ngOnInit() {

    let dateFromParam = this.route.snapshot.queryParamMap.get("dateFrom");
    if (dateFromParam != null && dateFromParam !== '') {
      this.dateFrom = new Date(parseInt(dateFromParam));
      this.dateTo = new Date(parseInt(dateFromParam));
    }

    super.ngOnInit();
    this.setDateControl();
    this.loadData();

    this.descriptionService.get().subscribe(res => {
      this.description = res.Views.CompanyInfo;
    });
  }

  ngOnDestroy() {
    this.modalService.remove('company-info-modal');
  }

  loadData() {
    //NIP '6771019615'
    this.companyService.get(this.propertyGuid, null, this.companyName, null, null, null, null, null, null, null, null, null, this.dateFrom, this.dateTo).subscribe(res => {
      debugger;
      if (res.Success) {
        this.companies = res.Companies;
        this.companiesAll = res.Companies;
      }

    });
  }

  loadDataDelayed() {
    if (this.loadingDelay != null) {
      clearTimeout(this.loadingDelay);
    }

    this.loadingDelay = setTimeout(() => {
      this.loadData();
    }, 1000);
  }

  showNextDay() {
    this.setNextDay();
    this.setDateControl();
    this.loadDataDelayed();
  }

  showPreviousDay() {
    debugger;
    super.setPreviousDay();
    this.setDateControl();
    this.loadDataDelayed();
  }

  scrollToTop() {
    this.top.nativeElement.scrollIntoView();
  }

  showTopBtn() {
    const rect = this.top.nativeElement.getBoundingClientRect();

    if (rect.top < 120) {
      this.topBtn.nativeElement.classList.remove('inactive');
    } else {
      this.topBtn.nativeElement.classList.add('inactive');
    }
  }

  public setRangeFrom() {
    // debugger;
    let startDate = this.rangeDate.value.start;
    if (startDate != null) {
      this.dateFrom = new Date(startDate);
      this.dateTo = new Date(startDate);
      this.dateTo.setDate(this.dateTo.getDate() + 1);
      this.loadData();
    }
  }

  private setDateControl() {
    this.rangeDate.patchValue({ start: new Date(this.dateFrom.toISOString()) });
  }

  //showNextDay() {
  //  this.setNextDay();
  //  setTimeout(() => {
  //    this.loadData();
  //  }, 1000);
  //}

  //showPreviousDay() {
  //  super.setPreviousDay();
  //  setTimeout(() => {
  //    this.loadData();
  //  }, 1000);
  //}

  filterCompanies() {
    //this.companies = this.companiesAll.filter(item => {
    //  return item.Name.indexOf(this.companyName) === -1;
    //})
    this.loadData();
  }

  //showCompany(id) {

  //  this.router.navigate(['/company-details'], { queryParams: { id: id } });
  //}

  public changeDateFrom() {
    super.changeDateFrom();

    this.loadData();
  }

  //closeModal(modalId: string) {
  //  this.modalService.close(modalId);
  //}

  showCompanyInfo(modalId: string, guid: string) {

    this.company = this.companies.filter(item => {
      return item.Guid === guid;
    })[0];

    //this.companyService.getById(this.propertyGuid, guid).subscribe(res => {
    //  debugger;
    //  if (res.Success) {

    //    if (res.Companies != null && res.Companies.length > 0) {

    //      this.company = res.Companies[0];
    //      debugger;
    //      let serviceIdParam = this.route.snapshot.queryParamMap.get("id");
    //    //  serviceIdParam = 'ddb5415c-1724-4792-ac83-85252736dfa2';

    //      if (serviceIdParam !== '') {
    //        this.serviceService.getById(this.propertyGuid, serviceIdParam).subscribe(res => {
    //          debugger;
    //          if (res.Success && res.Services !== null && res.Services.length > 0) {
    //            //this.roomNo = res.Services[0].Room.Number;
    //            //this.startDateTime = res.Services[0].StartDateTime;
    //            //this.endDateTime = res.Services[0].EndDateTime;
    //            //this.adultGuestCount = res.Services[0].AdultGuestCount + res.Services[0].SchoolChildGuestCount;
    //            this.amount = res.Services[0].OriginalAmount = res.Services[0].DiscountAmount;
    //            //this.serviceKind = res.Services[0].AdditionalServices[].ServiceKind.Name;
    //            let additionalServices = res.Services[0].AdditionalServices.map(item => {
    //              return item.ServiceKind.Name;
    //            });
    //           // this.serviceKind = additionalServices.join();
    //            //Pobyt: Services[].AdditionalServices[].ServiceKind.Name(należy złączyć, oddzielając przecinkami)
    //          }
    //        })
    //      }

    //      this.modalService.open(modalId);
    //    }

    //  }

    //});
    this.modalService.open(modalId);

  }
}
