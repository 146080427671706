<div class="login">
  <header class="header-page-login">
    <div class="container-mobile main-screen-logo-wrapper">
      <div class="header-page-login-start-text">Witamy w Hotelu</div>
      <a><img class="header-page-login-logo" src="assets/img/hotelion-logo-white.svg" alt="hotelion logo" title=""></a>
      <div class="error">
        <div class="message">
          <p> {{ loginErr }}</p>
        </div>
      </div>
    </div>
  </header>
  <div>
    <section class="section-login-mobile">
      <div class="container-mobile">
        <!-- <h1 class="section-login-mobile-header">Zaloguj się, aby zarządzać<br> swoją rezerwacją.</h1> -->
        <form [formGroup]="signinForm" (ngSubmit)="onSubmit()">
          <div class="section-login-mobile-form-input">
            <input type="text" name="name" id="login" placeholder="" formControlName="name" (keyup)="onKeyUp($event)">
            <label for="login">
              <span class="section-login-mobile-form-input-text">login</span>
              <img class="section-login-mobile-form-input-info" src="assets/icon-info.svg" alt="" title="">
            </label>
          </div>
          <div class="section-login-mobile-form-input">
            <input type="password" name="password" id="password" placeholder="" formControlName="password"
              (keyup)="onKeyUp($event)">
            <label for="pass">
              <span class="section-login-mobile-form-input-text">hasło</span>
              <img class="section-login-mobile-form-input-info" src="assets/icon-info.svg" alt="" title="">
            </label>
          </div>

          <div class="section-login-mobile-form-input-button">
            <button type="submit">ZALOGUJ</button>
          </div>
        </form>
      </div>

    </section>
  </div>
</div>