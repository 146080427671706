import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe, Location } from '@angular/common';

import { CompanyService } from '../../../services/company.service';
//import { AuthenticationStateService } from '../../../shared/authentication-state.service';

import { GetHousekeepingStatusResponseDTO } from '../../../dto/get-housekeeping-status-response.DTO';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})

export class CompanyDetailsComponent implements OnInit {

  //public userDTO: UserDTO;
  public housekeepingStatusResponseDTO: GetHousekeepingStatusResponseDTO = null;
  public companyName: string = null;
  public loading: boolean = false;

  public pageSize: number = 25;
  private currentPage: number = 1;
  private totalPages: number = 0;
  public totalItems: number = 0;

  constructor(private route: ActivatedRoute, private router: Router, private companyService: CompanyService, protected location: Location) {
    // private authenticationStateService: AuthenticationStateService) {
    //this.campaigns = [];
    //this.userDTO = null;
  }

  ngOnInit() {

    let companyIdParam = this.route.snapshot.queryParamMap.get("id");
   // this.loadData();
    let propertyGuid = '92b94e93-339f-4f0a-b4f8-26a54c3e4d24';

    this.companyService.getById(propertyGuid, companyIdParam).subscribe(res => {
      debugger;
    });
  }
}
