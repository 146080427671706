import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

import { AppConfig } from '../config/app.config';

import { TokenAuthService } from '../shared/token-auth.service';

import { PropertyRespDTO } from '../dto/property-resp.DTO';
import { OccupancyRespDTO } from '../dto/occupancy.resp.DTO';


@Injectable({
  providedIn: 'root'
})

export class OccupancyService {

  constructor(private http: HttpClient, private datePipe: DatePipe, private tokenAuthService: TokenAuthService) { }

  get(propertyGuid: string, dateFrom: Date, dateTo: Date): Observable<OccupancyRespDTO> {
    debugger;

    //v1/Statistics/Occupancy/{propertyGuid}?occupancyReportFilters.startDateTime={date}&occupancyReportFilters.endDateTime={date}
    let dateFromStr = this.datePipe.transform(dateFrom, 'yyyy-MM-dd');
    let dateToStr = this.datePipe.transform(dateTo, 'yyyy-MM-dd');

    return this.http.get<OccupancyRespDTO>(`${AppConfig.apiUrl}/Statistics/Occupancy/${propertyGuid}?occupancyReportFilters.startDateTime=${dateFromStr}&occupancyReportFilters.endDateTime=${dateToStr}`);
  }
}
