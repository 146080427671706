import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConfig } from '../config/app.config';

import { AuthRespDTO } from '../dto/auth.resp.DTO';
import { AuthReqDTO } from '../dto/auth.req.DTO';


@Injectable({
  providedIn: 'root'
})

export class LoginService {

  constructor(private http: HttpClient) { }

  login(authReqDTO: AuthReqDTO): Observable<AuthRespDTO> {
    return this.http.post<AuthRespDTO>(`${AppConfig.apiUrl}/Authentication/Token`, authReqDTO);
  }

  logout(): Observable<any> {
    return this.http.delete<any>(`${AppConfig.apiUrl}/Authentication/Token`);
  }
}
