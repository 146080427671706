import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http";
import { TokenAuthService } from "../shared/token-auth.service";

@Injectable()

export class AuthHeaderInterceptor implements HttpInterceptor {

  constructor(private tokenAuthService: TokenAuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const jwtHeaderToken = this.tokenAuthService.getJwtToken();
 //  debugger;
    if (jwtHeaderToken !== null) {
      req = req.clone({
        setHeaders: {
          //Authorization: "Bearer " + jwtHeaderToken
          Authorization: jwtHeaderToken?.access_token
        }
      });
    }
    
    return next.handle(req);
  }
}
