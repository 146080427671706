import { InjectionToken } from "@angular/core";

export let APP_CONFIG = new InjectionToken('app.config');
import { environment } from 'src/environments/environment';

export const AppConfig: any = {
  routes: {
    error404: '404'
  },

  apiUrl: environment.apiUrl,
  clientId: 'a0a845dfe810bd1ffff18a5f31d3119c',
  clientSecret: 'UwU68t5U3DWA1hdG7nh54dDhTtGENR517jfKu1anSbfkMafK'
}
