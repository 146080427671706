import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConfig } from '../config/app.config';

import { TokenAuthService } from '../shared/token-auth.service';
import { PropertyService } from '../services/property.service';


import { UserRolesRespDTO } from '../dto/user-roles.resp.DTO';
import { RoleDTO } from '../dto/role.DTO';
import { DepartmentDTO } from '../dto/department.DTO';
import { UserDTO } from '../dto/user.DTO';


@Injectable({
  providedIn: 'root'
})

export class UserService {

  private departments: DepartmentDTO[] = [];

  constructor(private http: HttpClient, private tokenAuthService: TokenAuthService, private propertyService: PropertyService) {

    let departmentManager: DepartmentDTO = new DepartmentDTO();
    departmentManager.name = 'Manager';
    this.departments.push(departmentManager);

    let departmentHSK: DepartmentDTO = new DepartmentDTO();
    departmentHSK.name = 'HSK';
    this.departments.push(departmentHSK);

    let departmentTech: DepartmentDTO = new DepartmentDTO();
    departmentTech.name = 'TECH';
    this.departments.push(departmentTech);

  }

  get(propertyGuid: string): Observable<UserRolesRespDTO> {
  //  debugger;
    //let jwtJson = this.tokenAuthService.getJwtToken();
    //let jwt = JSON.parse(jwtJson);

    //var reqHeader = new HttpHeaders({
    //  'Content-Type': 'application/json',
    //  'Authorization': jwt.access_token
    //});

    return this.http.get<UserRolesRespDTO>(`${AppConfig.apiUrl}/User/Roles/${propertyGuid}`);
  }

  getDepartments(): DepartmentDTO[] {
    return this.departments;
  }

  getProperty(): string {
   // debugger;
    const propertyId = this.propertyService.getPropertyId();
    return propertyId;
    //let userRolesToken = this.tokenAuthService.getUserRoles();
    //if (userRolesToken !== null) {
    //  return userRolesToken.PropertyGuid;
    //}
    //return null;
  }

  getUser(): UserDTO {
    let jwt = this.tokenAuthService.getJwtUser();
    if (jwt != null) {
      return jwt.User;
    }
    return null;
  }

  getDepartment(): string {
    let userRolesToken = this.tokenAuthService.getUserRoles();
    if (userRolesToken !== null) {
      return userRolesToken.DepartmentName;
    }
    return null;
  }

  getMainPanelUrl() {

    let department = this.getDepartment();

    if (department === 'Manager') {
      return '/main-panel-director';
    }
    else if (department === 'HSK') {
      return '/main-panel-hsk';
    }
    else if (department === 'TECH') {
      return '/main-panel-tech';
    }
  }
}
