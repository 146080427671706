import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConfig } from '../config/app.config';

import { TokenAuthService } from '../shared/token-auth.service';

import { ServiceRespDTO } from '../dto/service.resp.DTO';


@Injectable({
  providedIn: 'root'
})

export class ServiceService {

  constructor(private http: HttpClient, private tokenAuthService: TokenAuthService) { }

  getById(propertyGuid: string, serviceGuid: string): Observable<ServiceRespDTO> {
    debugger;

    return this.http.get<ServiceRespDTO>(`${AppConfig.apiUrl}/Service/${propertyGuid}?searchFilters.guids=${serviceGuid}`);
  }

  //get(propertyGuid: string, guids: string[], name: string, nIP: string, addressStreet: string,
  //  addressCountryCode: string, addressCountryName: string, addressPostalCode: string, addressCity: string,
  //  emailAddresses: string[], phoneNumbers: string[], segmentGuid: string): Observable<CompaniesRespDTO> {

  //}
}
