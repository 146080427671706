import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from "@angular/forms";

import { AppConfig } from '../../config/app.config';

import { TokenAuthService } from '../../shared/token-auth.service';
import { PropertyService } from '../../services/property.service';
import { LostAndFoundService } from '../../services/lost-and-found.service';
import { AuthenticationStateService } from '../../shared/authentication-state.service';
import { LoginService } from '../../services/login.service';
import { UserService } from '../../services/user.service';

import { AuthReqDTO } from '../../dto/auth.req.DTO';
import { StorageUserRolesDTO } from '../../dto/storage.user-roles.DTO';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  signinForm: FormGroup;
  err = null;
  loginErr = '';

  constructor(
    public router: Router,
    public fb: FormBuilder,
    public loginService: LoginService,
    private authenticationStateService: AuthenticationStateService,
    private tokenAuthService: TokenAuthService,
    private propertyService: PropertyService,
    private lostAndFoundService: LostAndFoundService,
    private userService: UserService
    //  private authenticationStateService: AuthenticationStateService,
  ) {
    this.signinForm = this.fb.group({
      name: [],
      password: []
    })
  }

  ngOnInit() {
    this.onSubmit();
  }

  onSubmit() {
    //  debugger;
    if (this.signinForm.invalid) {
      this.err = this.signinForm.errors;
      return;
    }

    let authReqDTO: AuthReqDTO = new AuthReqDTO();
    authReqDTO.client_id = AppConfig.clientId;
    authReqDTO.client_secret = AppConfig.clientSecret;
    authReqDTO.username = "Manager";
    authReqDTO.password = "a";

    this.loginService.login(authReqDTO).subscribe(
      res => {
       // debugger;
        if (res !== null && res.Messages != null && res.Messages.length > 0) {
          if (res.Messages[0].Kind === 'Error') {
            this.loginErr = res.Messages[0].Text;
          }
        }
        else {

          this.tokenAuthService.setTokenStorage(res);

          //let propertyGuid: string = "92b94e93-339f-4f0a-b4f8-26a54c3e4d24";
          let propertyId = '';

          this.propertyService.get().subscribe(resProperty => {
            if (resProperty.Success && resProperty.Properties.length > 0) {

              propertyId = resProperty.Properties[0].Guid;
              debugger;
              this.propertyService.setPropertyId(propertyId);
              this.authenticationStateService.setPropertyState(propertyId);

              this.userService.get(propertyId).subscribe(resUserRoles => {
                // debugger;
                if (resUserRoles.Success) {
                  //res.UserRoles
                  let storageUserRolesDTO: StorageUserRolesDTO = new StorageUserRolesDTO();

                  storageUserRolesDTO.UserRoles = resUserRoles.UserRoles;

                  //  if (resUserRoles.UserRoles.includes())
                  if (resUserRoles.UserRoles.indexOf("Manager") !== -1) {
                    storageUserRolesDTO.DepartmentName = "Manager";
                  }
                  else if (resUserRoles.UserRoles.indexOf("Housekeeper") !== -1) {
                    storageUserRolesDTO.DepartmentName = "HSK";
                  }
                  else if (resUserRoles.UserRoles.indexOf("Technician") !== -1) {
                    storageUserRolesDTO.DepartmentName = "TECH";
                  }

                  //storageUserRolesDTO.PropertyGuid = propertyId;
                  this.tokenAuthService.setProperty(propertyId);
                  this.authenticationStateService.setPropertyState(propertyId);

                  this.tokenAuthService.setUserRolesStorage(storageUserRolesDTO, res.expires_in);
                  this.authenticationStateService.setAuthState(true);

                  //this.propertyService.get().subscribe(res => {
                  //  debugger;
                  //});

                  if (storageUserRolesDTO.DepartmentName === 'Manager') {
                    this.router.navigate(['/main-panel-director']);
                  }
                  else if (storageUserRolesDTO.DepartmentName === 'HSK') {
                    this.router.navigate(['/main-panel-hsk']);
                  }
                  else if (storageUserRolesDTO.DepartmentName === 'TECH') {
                    this.router.navigate(['/main-panel-tech']);
                  }
                }
              });
            }
          });

          //this.propertyService.get().subscribe(res => {
          //  debugger;
          //});

          // test lost and found
          //this.lostAndFoundService.get('fa11a7b5-8f20-41be-a400-c472ce0dd56f').subscribe(res => {
          //  debugger;
          //});
        }
      },
      error => {
        // debugger;
        if (error.status === 401) {
          this.loginErr = 'Błędny login lub hasło';
        }
        else if (error.status !== 401 && error.status !== 200) {
          this.loginErr = 'Błąd logowania';
        }
        else {
          this.loginErr = error;
        }
      }, () => {
        //  debugger;
        //  this.authenticationStateService.setAuthState(true);
        //this.router.navigate(['users']);
      }
    );
  }

  onKeyUp(event: any) {
    //debugger;
    //console.log(event.target.value);
   // this.loginErr = '';
    if (event.key !== "Enter") {
      this.loginErr = '';
    }
  }
}
