<div class="reservation-form">
  <app-header></app-header>
  <div class="main-page">

    <section class="section-page-mobile">
      <div class="section-reservation-mobile-top">
        <div class="container-mobile-2">
          <div class="section-reservation-mobile-header-icon-1">

          </div>
          <div class="section-reservation-mobile-header-icon">
            <h1 class="section-reservation-mobile-header-1">Wiadomości</h1>
          </div>
          <div class="section-reservation-mobile-header-icon-2">
            <a href=""><img class="header-page-user" src="../../../../assets/icon-info.svg" alt="" title=""></a>
          </div>
        </div>
      </div>
      <div class="section-page-mobile-content">
        <div class="container-mobile">
          <div class="window-mobile-page-content-main">
            <div class="page-mobile-container">

              <ul class="list-menu">
                <li (click)="selectChatRole('Technician')"><a>Technicy</a></li>
                <li class="new" (click)="selectChatRole('Housekeeper')"><a>Pokojowe</a></li>
                <li (click)="selectChatRole('Manager')"><a>Manager</a></li>
                <li class="new" (click)="selectChatRole('Receptionist')"><a>Recepcja</a></li>
              </ul>
              <!--<h4>POZOSTAŁE ROZMOWY</h4>
              <ul class="list-chats">
                <li *ngFor="let item of chats">
                  <a (click)="showChat(item.Guid)">{{ item.Person?.FirstName }} {{ item.Person?.LastName }} / p. 121 <span>{{ item.MessagePrev }}</span></a>
                </li>
              </ul>-->
              <!--<div class="module-chat">
                <p class="submit-btn a"><button (click)="createNewChat()" [disabled]="userRole2 === ''"><img src="../../../../assets/icon-plus.svg" alt="Placeholder"> Rozpocznij nową rozmowę</button></p>
              </div>-->
            </div>
          </div>
        </div>
      </div>

    </section>

  </div>
  <app-footer></app-footer>
</div>
