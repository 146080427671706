<div class="reservation-form">
        <app-header></app-header>
        <div class="main-page">
            <section class="section-page-mobile">
                <div class="section-reservation-mobile-top">
                    <div class="container-mobile-2">
                        <div class="section-reservation-mobile-header-icon-1">
                            <a (click)="goBack()"><img class="header-page-user" src="../../../assets/left.png" alt="" title=""></a>
                        </div>
                        <div class="section-reservation-mobile-header-icon">
                            <h1 class="section-reservation-mobile-header-1">ADR</h1>
                        </div>
                        <div class="section-reservation-mobile-header-icon-2">
                            <a (click)="showInfo(null)"><img class="header-page-user" src="../../../assets/icon-info.svg" alt="" title=""></a>
                        </div>
                    </div>
                </div>
                <div class="section-reservation-mobile-top">
                    <div class="container-mobile-2 day">
                        <div class="section-reservation-mobile-header-icon-2">
                            <a (click)="showPreviousDay()"><img class="header-page-user" src="assets/prev.png" alt="" title=""></a>
                          </div>
                        <!--<div class="section-reservation-mobile-header-icon-1">
                          <a (click)="showCalendar()"><img class="header-page-user" src="../../../assets/icon_03.png" alt="" title=""></a>

                        </div>-->
                        <div class="section-reservation-mobile-header-icon">
                          <mat-form-field>
                            <mat-date-range-input [formGroup]="rangeDate" [rangePicker]="picker" (click)="picker.open()">
                              <input matStartDate formControlName="start" (dateChange)="setRangeFrom()" disabled>
                              <input matEndDate formControlName="end" (dateChange)="setRangeTo()" disabled>
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
                            <mat-error *ngIf="rangeDate.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                            <mat-error *ngIf="rangeDate.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                          </mat-form-field>
                          <!--<h1 class="section-reservation-mobile-header-3">
  <span>od </span>21.10.2021-->
                          <!-- <input type="date" class="section-reservation-mobile-input" [(ngModel)]="dateFromFilter" value="{{ dateFromFilter | date:'yyyy-MM-dd' }}" #ctrl="ngModel" required (ngModelChange)="changeDateFrom()" /> -->
                          <!--<span>do </span>21.10.2021-->
                          <!-- <input type="date" class="section-reservation-mobile-input" [(ngModel)]="dateToFilter" value="{{ dateToFilter | date:'yyyy-MM-dd' }}" #ctrl="ngModel" required (ngModelChange)="changeDateTo()" /> -->
                          <!--<p>Selected range: {{range.value | json}}</p>
  </h1>-->
                          <h1 class="section-reservation-mobile-header-2">{{ formatDoubleValue(adrRespDTO?.ADRForPeriod) }} <span>PLN</span></h1>
                        </div>
                        <div class="section-reservation-mobile-header-icon-2">
                            <a (click)="showNextDay()"><img class="header-page-user" src="../../../assets/icon_06.png" alt="" title=""></a>
                        </div>
                    </div>
                </div>

                <div class="section-page-mobile-content">
                    <div class="container-mobile">
                        <div class="window-mobile-page-content-main">
                          <div class="page-mobile-container">

                            <div *ngFor="let item of adrRespDTO?.ADR" class="page-mobile-container-section-light-adr flex">
                              <div class="col40">{{ item.Day | date:'yyyy-MM-dd' }}</div>
                              <div class="col30">{{ parseValueKind(item.ValueKind) }}</div>
                              <div class="col30r">{{ item.Value }} PLN</div>
                            </div>

                          </div>
                        </div>
                    </div>
                </div>

            </section>

        </div>
        <app-footer></app-footer>

    </div>
