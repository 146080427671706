<div class="reservation-form">
  <app-header></app-header>
  <div class="main-page">

    <section class="section-page-mobile">
      <div class="section-reservation-mobile-top">
        <div class="container-mobile-2 day">
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showPreviousDay()"><img class="header-page-user" src="assets/prev.png" alt="prev-icon"></a>
          </div>

          <div class="section-reservation-mobile-header-icon">
            <h1 class="section-reservation-mobile-header-1">

              <div class="d-flex flex-column">
                <span class="d-block">{{ currentDayName }}, </span>
                <div class="d-flex">
                  <mat-form-field [formGroup]="rangeDate" (click)="picker.open()">
                    <input title="date" matInput formControlName="start" [matDatepicker]="picker"
                      (dateChange)="setRangeFrom()" disabled>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                    <mat-error *ngIf="rangeDate.controls.start.hasError('matStartDateInvalid')">Invalid start date
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

            </h1>
          </div>
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showNextDay()"><img class="header-page-user" src="assets/icon_06.png" alt="" title=""></a>
          </div>
        </div>
      </div>

      <div class="section-page-mobile-content">


        <div class="page-mobile-container container" style="padding: 0px 15px;">

          <!-- hotel status -->

          <div class="row mt-3">
            <h2 class="section-reservation-mobile-header">Hotel status</h2>
          </div>

          <div class="row">

            <div
              class="col-12 mb-1 page-mobile-container-section-light d-flex align-content-center justify-content-between p-3"
              style="background-color: #f6f6f6; border-radius: 10px;">
              <div class="col70">Przyjazdy</div>
              <div class="col30">{{ occupancyReportDay?.Checkins }}</div>
            </div>

            <div
              class="col-12 mb-1 page-mobile-container-section-light d-flex align-content-center justify-content-between p-3"
              style="background-color: #f6f6f6; border-radius: 10px;">
              <div class="col70">Wyjazdy</div>
              <div class="col30">{{ occupancyReportDay?.Checkouts }}</div>
            </div>

            <div
              class="col-12 mb-1 page-mobile-container-section-light d-flex align-content-center justify-content-between p-3"
              style="background-color: #f6f6f6; border-radius: 10px;">
              <div class="col70">Pobyty</div>
              <div class="col30">{{ occupancyReportDay?.InHotelGuestsCount }}</div>
            </div>
          </div>

          <div class="row mt-2 d-flex align-items-center mb-4">
            <div class="col">
              <h2 class="m-0 p-0">Konferencje</h2>
            </div>
            <div class="col">
              <button class="show-more-btn mt-1" (click)="showConferences()">
                Pokaż więcej
              </button>
            </div>
          </div>

          <div class="row">


            <div class="col-12 mb-1 page-mobile-container-section-lights p-3"
              style="background-color: #f6f6f6; border-radius: 10px;" *ngFor="let item of conferences">
              <div style="display: flex; width: 100%; ">
                <p style="display: inline; ">{{ item.ConferenceRoom }}<br></p>
              </div>
              <p style="font-weight: 600; display: inline;" *ngIf="item.ReservingCompany !== ''">{{
                item.ReservingCompany }}<br></p>
              <p *ngIf="item.ReservingPerson !== ''">{{ item.ReservingPerson }}</p>
              <p>Liczba gości: {{ item.AdultGuestCount }}</p>
            </div>

          </div>

        </div>

        <div class="container-mobile">
          <div class="window-mobile-page-content-main">

            <div class="page-mobile-container flex">
              <div class="page-mobile-container-col2 " (click)="showHouseKeeping()">
                <div
                  class="page-mobile-container page-mobile-container-section-dark my-tile my-tile--only-title button">
                  <div class="page-mobile-container-col">
                    <img src="assets/icon_21.png">
                  </div>
                  <div>
                    <h2 class="page-mobile-container-section-dark-header">Status pokoi</h2>
                  </div>
                </div>
              </div>
              <div class="page-mobile-container-col2 " (click)="showActivitLog()">

                <div
                  class="page-mobile-container page-mobile-container-section-dark my-tile my-tile--only-title button">
                  <div class="page-mobile-container-col">
                    <img src="assets/icon_21.png">
                  </div>
                  <div>
                    <h2 class="page-mobile-container-section-dark-header">Dziennik działań</h2>
                  </div>
                </div>
              </div>
            </div>

            <div class="page-mobile-container flex">
              <div class="page-mobile-container-col2 " (click)="addFault()">
                <div
                  class="page-mobile-container page-mobile-container-section-dark my-tile my-tile--only-title button">
                  <div class="page-mobile-container-col">
                    <img src="assets/22_panel_glowny-–-TECH-(1)_03.png">
                  </div>
                  <div>
                    <h2 class="page-mobile-container-section-dark-header">Zgłoś usterke</h2>
                  </div>
                </div>
              </div>
              <div class="page-mobile-container-col2 " (click)="addLostAndFound()">

                <div
                  class="page-mobile-container page-mobile-container-section-dark my-tile my-tile--only-title button">
                  <div class="page-mobile-container-col">
                    <img src="assets/22_panel_glowny-–-TECH-(1)_06.png">
                  </div>
                  <div>
                    <h2 class="page-mobile-container-section-dark-header">Rzeczy znalezione</h2>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </section>
  </div>
  <app-footer></app-footer>
</div>