import { Injectable } from '@angular/core';
import { TokenAuthService } from '../shared/token-auth.service';
import { PropertyService } from '../services/property.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

import { UserDTO } from '../dto/user.DTO';
import { AuthRespDTO } from '../dto/auth.resp.DTO';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationStateService {

  private userCurrentState = new BehaviorSubject<boolean>(this.tokenAuthService.isSignedin());
  userAuthState = this.userCurrentState.asObservable();

  private propertyCurrentState = new BehaviorSubject<string>(this.propertyService.getPropertyId());
  propertyState = this.propertyCurrentState.asObservable();

  constructor(
    public tokenAuthService: TokenAuthService,
    public propertyService: PropertyService
  ) { }

  setAuthState(value: boolean) {

    this.userCurrentState.next(value);

    if (!value) {
      this.tokenAuthService.destroyToken();
    }
  }

  setPropertyState(id: string) {
    this.propertyCurrentState.next(id);
  }

  public getUserAuthStateObservable() {
    return this.userAuthState;
  }

  public getUserAuthState() {
    return this.userCurrentState.value;
  }

  public getPropertyState() {
    return this.propertyState;
  }

  public getUser(): AuthRespDTO {

    let authRespDTO = this.tokenAuthService.getJwtUser();
    return authRespDTO;
  }
}
