<div class="reservation-form">
  <app-header></app-header>
  <div class="main-page">

    <section class="section-page-mobile">
      <div class="section-reservation-mobile-top">
        <div class="container-mobile-2">
          <div class="section-reservation-mobile-header-icon-1">
            <a (click)="goBack()"><img class="header-page-user" src="../../../../../assets/left.png" alt=""
                title=""></a>
          </div>
          <div class="section-reservation-mobile-header-icon">
            <h1 class="section-reservation-mobile-header-1">Minibar </h1>
          </div>
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showInfo('')"><img class="header-page-user" src="../../../../../assets/icon-info.svg" alt=""
                title=""></a>
          </div>
        </div>
      </div>

      <div class="section-page-mobile-content" (scroll)="showTopBtn()">
        <div class="container-mobile">
          <div class="window-mobile-page-content-main">
            <div class="page-mobile-container" #top>

              <div style="display: flex; width: 100%;">
                <!--<select [(ngModel)]="roomId">
                  <option></option>
                  <option *ngFor="let item of rooms" [ngValue]="item.Guid">Pokój {{ item.Number }}</option>
                </select>-->
                <div style="width: 100%;" [ngClass]="{
                   'room-status-clean': houseKeepingDataDTO?.RoomStatusKind === 'Clean',
                   'room-status-clean-oc': houseKeepingDataDTO?.RoomStatusKind === 'OccupiedClean',
                   'room-status-service': houseKeepingDataDTO?.RoomStatusKind === 'OutOfService',
                   'room-status-active': houseKeepingDataDTO?.RoomStatusKind === 'CleanUnchecked',
                   'room-status-neutral': houseKeepingDataDTO?.RoomStatusKind === 'Deleted',
                   'room-status-deactive': houseKeepingDataDTO?.RoomStatusKind === 'Dirty' || houseKeepingDataDTO?.RoomStatusKind === 'OccupiedDirty'
                     || houseKeepingDataDTO?.RoomStatusKind === 'OccupiedForceCleaning' || houseKeepingDataDTO?.RoomStatusKind === 'OccupiedToCheck'
                   }">
                  <div class="col20 number">{{ houseKeepingDataDTO?.Room?.Number }}</div>
                  <div class="col20">{{ houseKeepingDataDTO?.Room?.Kind?.Name }}</div>
                  <div class="col50 status">{{ houseKeepingDataDTO?.RoomStatusKind }}</div>
                  <div class="col10 dots">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                      <path
                        d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="page-mobile-container-section-light searcher">
                <a (click)="filterItems()">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.33 23.33">
                    <defs>
                      <style>
                        .cls-1 {
                          fill: none;
                          stroke: #bdb9c0;
                          stroke-linecap: round;
                          stroke-linejoin: round;
                          stroke-width: 2px;
                        }
                      </style>
                    </defs>
                    <g id="Warstwa_2" data-name="Warstwa 2">
                      <g id="Warstwa_1-2" data-name="Warstwa 1">
                        <g id="Group_164" data-name="Group 164">
                          <circle id="Ellipse_35" data-name="Ellipse 35" class="cls-1" cx="10.28" cy="10.28" r="9.28" />
                          <line id="Line_85" data-name="Line 85" class="cls-1" x1="22.33" y1="22.33" x2="16.83"
                            y2="16.83" />
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
                <input type="text" [(ngModel)]="itemName" placeholder="Wyszukaj" #ctrl="ngModel"
                  (keydown.enter)="filterItems()" />
                <a>
                  <img src="../../../assets/8_Goście_03.png">
                </a>
              </div>

              <ul class="list-minibar">

                <li *ngFor="let item of serviceKindDTOs; let i = index">
                  <input *ngFor="let c of createArrayFromCount(item.MinibarItemsCount); let j = index"
                    [(ngModel)]="item.Count" type="radio" [name]="'ia' + i"
                    [id]="j === 0 ? 'ia' + i : 'ia' + i + '-' + c" [value]="c + 1" [checked]="item['selected'] === c"
                    (click)="item['selected'] === c ? item['selected'] = null : item['selected'] = c">
                  <label [for]="item['selected'] ? 'ia' + i+ '-' + item['selected'] : 'ia' + i ">{{ item.Name }}</label>
                  <span class="buttons-wrapper">
                    <button *ngFor="let c of createArrayFromCount(item.MinibarItemsCount); let j = index"
                      (click)="inputCheckedHandler(i, j, c)" [class]="item['selected'] === c ? 'checked' : ''">{{ c + 1
                      }}</button>
                  </span>
                  <!--<p>
                    <label class="hide" for="sa">Ilość</label>
                    <select name="sa" id="sa" [(ngModel)]="item.Count" style="padding: 11px;">

                      <option *ngFor="let countValue of countList" [ngValue]="countValue">{{ countValue }}</option>
                    </select>
                  </p>-->
                </li>
                <!--<li>
                  <p>
                    <input type="checkbox" name="ia" id="ia">
                    <label for="ia">Orzeszki</label>
                  </p>
                  <p>
                    <label class="hide" for="sa">Ilość</label>
                    <select name="sa" id="sa">
                      <option value="0">0</option>
                      <option value="1">1</option>
                    </select>
                  </p>
                </li>
                <li>
                  <p>
                    <input type="checkbox" name="ib" id="ib">
                    <label for="ib">Paluszki</label>
                  </p>
                  <p>
                    <label class="hide" for="sb">Ilość</label>
                    <select name="sb" id="sb">
                      <option value="0">0</option>
                      <option value="1">1</option>
                    </select>
                  </p>
                </li>-->
              </ul>
              <p class="submit-btn"><button (click)="save()">Zapisz uzupełnienie</button></p>
            </div>
          </div>
        </div>
      </div>
      <!--<div class="section-page-mobile-content">
              <div class="container-mobile">
                <div class="window-mobile-page-content-main">
                  <div class="page-mobile-container">
                    <div class="page-mobile-container-section-light">
                      <label>{{ department.name
                              }}</label>
      </div>
                    <div class="page-mobile-container-section-light">

                      <select [(ngModel)]="roomId">
                        <option></option>
                        <option *ngFor="let item of rooms" [ngValue]="item.Guid">Pokój {{ item.Number }}</option>
                      </select>
                    </div>
                    <p>Opis usterki:</p>
                    <div class="page-mobile-container-section-light">
                      <textarea [(ngModel)]="content" #ctrl="ngModel">Treść zgłoszenia</textarea>
                    </div>
                    <div class="flex">
                      <button (click)="cancel()" class="btn-light" [disabled]="content !== ''">Anuluj</button>
                      <button (click)="send()" class="btn-dark" [disabled]="content === ''">Wyślij zgłoszenie</button>
                    </div>
                  </div>


                </div>
              </div>
            </div>-->

    </section>

  </div>
  <app-footer></app-footer>
</div>
