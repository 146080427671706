import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe, Location } from '@angular/common';
import { DateAdapter } from '@angular/material/core';

import { BaseComponent } from '../../shared/base/base.component';

import { UserService } from '../../../services/user.service';
import { LostAndFoundService } from '../../../services/lost-and-found.service';
import { LostFoundItemDTO } from '../../../dto/lost-found-item.DTO';
import { LostAndFoundReqDTO } from '../../../dto/lost-and-found-resp.DTO';
import { DepartmentDTO } from '../../../dto/department.DTO';
import { RoomService } from '../../../services/room.service';
import { RoomDTO } from '../../../dto/room.DTO';
import { ModalService } from '../../../services/modal.service';
import { AuthenticationStateService } from '../../../shared/authentication-state.service';

@Component({
  templateUrl: './lost-and-found-add.component.html',
  styleUrls: ['./lost-and-found-add.component.scss']
})

export class LostAndFoundAddComponent extends BaseComponent implements OnInit {

  //public userDTO: UserDTO;
  //public campaigns: CampaignDTO[];
  public departments: DepartmentDTO[] = [];
  public rooms: RoomDTO[] = [];
  public content = '';
/*  public roomNo: string = null;*/
  public department: any = null;
  public roomId = '';
  public otherFindingPlace = '';

  public loading: boolean = false;

  constructor(private route: ActivatedRoute, protected router: Router, protected datePipe: DatePipe,
    protected authenticationStateService: AuthenticationStateService, protected userService: UserService,
    private lostAndFoundService: LostAndFoundService, protected dateAdapter: DateAdapter<Date>, private roomService: RoomService,
    protected location: Location, protected modalService: ModalService) {
    super(router, datePipe, userService, dateAdapter, location, modalService, authenticationStateService);
  }

  ngOnInit() {
    super.ngOnInit();
    debugger;
    let roomIdParam = this.route.snapshot.queryParamMap.get("id");
    if (roomIdParam != null) {
      this.roomId = roomIdParam;
    }

    debugger;
    this.departments = this.userService.getDepartments();

    if (this.departments != null && this.departments.length > 0) {

      let department = this.departments.filter(item => {
        return item.name === this.department;
      });

      if (department != null && department.length > 0) {
        this.department = department[0];
      }
    }

    this.loadData();
  }

  loadData() {

    this.roomService.get(this.propertyGuid).subscribe(res => {
      debugger;
      if (res.Success) {
        this.rooms = res.Rooms;
      }
    });
  }

  send() {
    debugger;
    let user = this.userService.getUser();
  
    let lostAndFoundReqDTO: LostAndFoundReqDTO = new LostAndFoundReqDTO();
    lostAndFoundReqDTO.LostFoundItem = new LostFoundItemDTO();
    lostAndFoundReqDTO.LostFoundItem.FindingDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    lostAndFoundReqDTO.LostFoundItem.ShortDescription = this.content;
    lostAndFoundReqDTO.LostFoundItem.FullDescription = this.content;
  //  lostAndFoundReqDTO.LostFoundItem.OwnerFirstName = "Ala";
  //  lostAndFoundReqDTO.LostFoundItem.OwnerLastName = "Dąbrowska";
    lostAndFoundReqDTO.LostFoundItem.FinderFirstName = user.FirstName; // "Maria";
    lostAndFoundReqDTO.LostFoundItem.FinderLastName = user.LastName; // "Kowalska";
    if (this.roomId !== '') {
      lostAndFoundReqDTO.LostFoundItem.FindingRoomGuid = this.roomId; // "b15bf80b-218c-4bf3-b362-dcdfd9854914";
    }
    else {
      lostAndFoundReqDTO.LostFoundItem.OtherFindingPlaceName = this.otherFindingPlace;
    }
    
    let dateNow = new Date();
    lostAndFoundReqDTO.LostFoundItem.FindingDate = dateNow.toISOString();

    this.lostAndFoundService.add(this.propertyGuid, lostAndFoundReqDTO).subscribe(res => {
      debugger;
      if (res.Success) {
        this.content = '';
        this.roomId = '';
        this.otherFindingPlace = '';
      }
    });
    //this.router.navigate(['/add-campaign']);
  }

  cancel() {
    //let mainPanelUrl = this.userService.getMainPanelUrl();
    //this.router.navigate([mainPanelUrl]);
    super.goBack();
  }

}
