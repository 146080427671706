import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe, Location } from '@angular/common';
import { DateAdapter } from '@angular/material/core';

import { BaseComponent } from '../shared/base/base.component';

//import { CompanyService } from '../../../services/campaign.service';
import { OccupancyService } from '../../services/occupancy.service';
import { ReportService } from '../../services/report.service';
import { UserService } from '../../services/user.service';
import { FormControl } from '@angular/forms';
import { ReportDTO } from '../../dto/report.DTO';
import { ModalService } from '../../services/modal.service';
import { AuthenticationStateService } from '../../shared/authentication-state.service';


@Component({
  selector: 'app-revenue',
  templateUrl: './revenue.component.html',
  styleUrls: ['./revenue.component.scss']
})

export class RevenueComponent extends BaseComponent implements OnInit {

  //public userDTO: UserDTO;
  //public campaigns: CampaignDTO[];
  public reports: ReportDTO[];
  public loading: boolean = false;
  private loadingDelay = null;

  public rangeDate = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  constructor(private route: ActivatedRoute, protected router: Router, protected datePipe: DatePipe,
    protected authenticationStateService: AuthenticationStateService,
    protected userService: UserService, private occupancyService: OccupancyService, protected dateAdapter: DateAdapter<Date>,
    protected location: Location, private reportService: ReportService, protected modalService: ModalService) {
    super(router, datePipe, userService, dateAdapter, location, modalService, authenticationStateService);
  }

  ngOnInit() {

    let dateFromParam = this.route.snapshot.queryParamMap.get("dateFrom");
    if (dateFromParam != null && dateFromParam !== '') {
      this.dateFrom = new Date(parseInt(dateFromParam));
    }

    super.ngOnInit();
    this.setDateControl();
    this.loadData();
  }

  loadData() {

    this.reportService.get(this.propertyGuid).subscribe(res => {
     // debugger;
      if (res.Success) {
        this.reports = res.Reports;
      }
    });
  }

  loadDataDelayed() {
    if (this.loadingDelay != null) {
      clearTimeout(this.loadingDelay);
    }

    this.loadingDelay = setTimeout(() => {
      this.loadData();
    }, 1000);
  }

  showNextDay() {
    this.setNextDay();
    this.setDateControl();

    this.loadDataDelayed();
  }

  showPreviousDay() {
  //  debugger;
    super.setPreviousDay();
    this.setDateControl();

    this.loadDataDelayed();
  }

  //public changeDateFrom() {
  //  super.changeDateFrom();

  //  this.loadData();
  //}

  //public changeDateTo() {
  //  super.changeDateTo();

  //  this.loadData();
  //}

  public setRangeFrom() {
    let startDate = this.rangeDate.value.start;
    if (startDate != null) {
      this.dateFrom = new Date(startDate);
    }
  }

  public setRangeTo() {
    let endDate = this.rangeDate.value.end;
    if (endDate != null) {
      let newEndDate = new Date(endDate);
      if (newEndDate !== this.dateTo) {
        this.dateTo = newEndDate;
        this.loadData();
      }
    }
  }

  openReport(guid: string) {
    this.router.navigate(['/report'], { queryParams: { id: guid } })
  }

  private setDateControl() {
    this.rangeDate.patchValue({ start: new Date(this.dateFrom.toISOString()) });
    this.rangeDate.patchValue({ end: new Date(this.dateTo.toISOString()) });
  }
}
