import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConfig } from '../config/app.config';

import { TokenAuthService } from '../shared/token-auth.service';

import { CompanyDTO } from '../dto/company.DTO';
import { AdrRespDTO } from '../dto/adr.resp.DTO';


@Injectable({
  providedIn: 'root'
})

export class ConferenceService {

  constructor(private http: HttpClient, private tokenAuthService: TokenAuthService) { }

  //get(propertyGuid: string): Observable<AdrRespDTO> {
  get(propertyGuid: string): Observable<AdrRespDTO> {
    debugger;
    //let jwtJson = this.tokenAuthService.getJwtToken();
    //let jwt = JSON.parse(jwtJson);

    //var reqHeader = new HttpHeaders({
    //  'Content-Type': 'application/json',
    //  'Authorization': jwt.access_token
    //});

    return this.http.get<AdrRespDTO>(`${AppConfig.apiUrl}/Statistics/ADR/${propertyGuid}/2021-01-10/2021-10-10`);
  }
}
