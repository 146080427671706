import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe, Location } from "@angular/common";

import { Guid } from "guid-typescript";

import { ChatService } from "../../../services/chat.service";
import { UserService } from "../../../services/user.service";
import { ChatMessageDTO } from "../../../dto/chat-message.DTO";
import { UserDTO } from "../../../dto/user.DTO";
import { ChatDTO } from "../../../dto/chat.DTO";

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnInit {
  //public userDTO: UserDTO;
  //public campaigns: CampaignDTO[];
  public chatMessages: ChatMessageDTO[] = [];
  public newMessage: string = null;

  private chatId = "";
  private user: UserDTO = null;
  private propertyGuid = "";
  public department = "";
  public userRole2 = '';
  public userGroupName = '';

  public loading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private chatService: ChatService,
    protected location: Location
  ) {}

  ngOnInit() {

    this.userRole2 = this.route.snapshot.queryParamMap.get('userRole2');
    debugger;
    if (this.userRole2 === 'Technician') {
      this.userGroupName = 'Technicy';
    }
    else if (this.userRole2 === 'Manager') {
      this.userGroupName = 'Manager';
    }
    else if (this.userRole2 === 'Housekeeper') {
      this.userGroupName = 'Pokojówki';
    }
    else if (this.userRole2 === 'Receptionist') {
      this.userGroupName = 'Recepcja';
    }

    let chatIdParam = this.route.snapshot.queryParamMap.get("id");
    if (chatIdParam != null && chatIdParam !== "") {
      this.propertyGuid = this.userService.getProperty();
      this.user = this.userService.getUser();
      this.department = this.userService.getDepartment();

      this.chatId = chatIdParam;
      this.loadData();
    }

    console.log(this);
  }

  loadData() {
    let dateFrom: Date = new Date();
    let dateTo: Date = new Date();
    dateFrom.setDate(dateTo.getDate() - 7);
    debugger;
    this.chatService
      .getMessagesForChat(this.chatId, dateFrom, dateTo)
      .subscribe(
        (res) => {
          debugger;
          if (res.Success) {
            this.chatMessages = res.ChatMessages;
          }
        },
        (error) => {}
      );
  }

  send() {
    debugger;
    if (this.newMessage === "") {
      return;
    }
    //let propertyGuid = '92b94e93-339f-4f0a-b4f8-26a54c3e4d24';
    //let propertyGuid = this.userService.getProperty();
    let chatMessageDTO: ChatMessageDTO = new ChatMessageDTO();
    chatMessageDTO.Guid = Guid.create().toString();
    chatMessageDTO.IsDeleted = false;
    chatMessageDTO.CreationDateTime = new Date();
    chatMessageDTO.Text = this.newMessage;
    chatMessageDTO.Source = this.department;
    chatMessageDTO.User = new UserDTO();
    chatMessageDTO.User.FirstName = this.user.FirstName;
    chatMessageDTO.User.LastName = this.user.LastName;
    chatMessageDTO.IsVisibleToGuest = true;

    this.chatService.add(this.chatId, chatMessageDTO).subscribe((res) => {
      debugger;
      if (res.Success) {
        this.newMessage = "";
        this.loadData();
      }
    });
    //this.router.navigate(['/add-campaign']);
  }

  cancel() {}

  refreshData() {
    this.loadData();
  }

  closeChat() {
    this.router.navigate(["/chat-messages"]);
  }

  showInfo(modalId: string) {

  }
}
