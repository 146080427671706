import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConfig } from '../config/app.config';

import { TokenAuthService } from '../shared/token-auth.service';

import { LostAndFoundRespDTO } from '../dto/lost-and-found.resp.DTO';
import { LostFoundItemDTO } from '../dto/lost-found-item.DTO';
import { LostAndFoundReqDTO } from '../dto/lost-and-found-resp.DTO';


@Injectable({
  providedIn: 'root'
})

export class LostAndFoundService {

  constructor(private http: HttpClient, private tokenAuthService: TokenAuthService) { }

  get(propertyGuid: string): Observable<LostAndFoundRespDTO> {
    debugger;
    //let jwtJson = this.tokenAuthService.getJwtToken();
    //let jwt = JSON.parse(jwtJson);

    //var reqHeader = new HttpHeaders({
    //  'Content-Type': 'application/json',
    //  'Authorization': jwt.access_token
    //});

    return this.http.get<LostAndFoundRespDTO>(`${AppConfig.apiUrl}/LostAndFound/${propertyGuid}`);
  }

  add(propertyGuid: string, lostFoundItemReqDTO: LostAndFoundReqDTO) {

    //let jwtJson = this.tokenAuthService.getJwtToken();
    //let jwt = JSON.parse(jwtJson);

    //var reqHeader = new HttpHeaders({
    //  'Content-Type': 'application/json',
    //  'Authorization': jwt.access_token
    //});

    return this.http.post<LostAndFoundRespDTO>(`${AppConfig.apiUrl}/LostAndFound/${propertyGuid}`, lostFoundItemReqDTO);
  }
}
