<div class="reservation-form">
  <app-header></app-header>
  <div class="main-page">

    <section class="section-page-mobile">
      <div class="section-reservation-mobile-top">
        <div class="container-mobile-2">
          <div class="section-reservation-mobile-header-icon-1">
            <a (click)="goBack()"><img class="header-page-user" src="../../../../assets/left.png" alt="" title=""></a>
          </div>
          <div class="section-reservation-mobile-header-icon">
            <h1 class="section-reservation-mobile-header-1">Raporty</h1>
          </div>
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showInfo('')"><img class="header-page-user" src="../../../../assets/icon-info.svg" alt="" title=""></a>
          </div>
        </div>
      </div>
      <!--<div class="section-reservation-mobile-top">
        <div class="container-mobile-2 day">
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showPreviousDay()"><img class="header-page-user" src="assets/prev.png" alt="" title=""></a>
          </div>
          <div class="section-reservation-mobile-header-icon">
            <h1 class="section-reservation-mobile-header-3">
              <mat-form-field>
                <mat-date-range-input [formGroup]="rangeDate" [rangePicker]="picker" (click)="picker.open()">
                  <input matStartDate formControlName="start" (dateChange)="setRangeFrom()" disabled>
                  <input matEndDate formControlName="end" (dateChange)="setRangeTo()" disabled>
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
                <mat-error *ngIf="rangeDate.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                <mat-error *ngIf="rangeDate.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
              </mat-form-field>
            </h1>
          </div>
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showNextDay()"><img class="header-page-user" src="../../../../assets/icon_06.png" alt="" title=""></a>
          </div>
        </div>
      </div>-->


      <div class="section-page-mobile-content">
        <div class="container-mobile">
          <div class="window-mobile-page-content-main">
            <div class="page-mobile-container">

              <div class="page-mobile-container-section-dark-rev flex" *ngFor="let item of reports" (click)="openReport(item.Guid)">
                <div class="col30">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.13 29.13">
                    <defs>
                      <style>
                        .cls-1 {
                          fill: none;
                          stroke: #b9b5bc;
                          stroke-linecap: round;
                          stroke-linejoin: round;
                          stroke-width: 2px;
                        }
                      </style>
                    </defs>
                    <g id="Warstwa_2" data-name="Warstwa 2"><g id="Warstwa_1-2" data-name="Warstwa 1"><g id="Group_152-2" data-name="Group 152-2"><line id="Line_78-2" data-name="Line 78-2" class="cls-1" x1="1" y1="10" x2="28" y2="10" /><line id="Line_79-2" data-name="Line 79-2" class="cls-1" x1="17.58" y1="6.43" x2="17.58" y2="1" /><line id="Line_80-2" data-name="Line 80-2" class="cls-1" x1="11.55" y1="6.43" x2="11.55" y2="1" /><line id="Line_81-2" data-name="Line 81-2" class="cls-1" x1="23.61" y1="6.43" x2="23.61" y2="1" /><line id="Line_82-2" data-name="Line 82-2" class="cls-1" x1="5.52" y1="6.43" x2="5.52" y2="1" /><line id="Line_83-2" data-name="Line 83-2" class="cls-1" x1="5.52" y1="15.92" x2="23.61" y2="15.92" /><line id="Line_84-2" data-name="Line 84-2" class="cls-1" x1="5.52" y1="21.61" x2="23.61" y2="21.61" /><path id="Path_1384-2" data-name="Path 1384-2" class="cls-1" d="M28.13,26.33V3.71H1V26.33a1.81,1.81,0,0,0,1.81,1.8H26.33a1.8,1.8,0,0,0,1.8-1.8Z" /></g></g></g>
                  </svg>
                </div>
                <div class="col70">{{ item.Name }}</div>
              </div>

              <!--<div class="page-mobile-container-section-dark-rev flex">
                <div class="col30">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.09 24.09">
                    <defs>
                      <style>
                        .cls-1 {
                          fill: none;
                          stroke: #b9b5bc;
                          stroke-linecap: round;
                          stroke-linejoin: round;
                          stroke-width: 2px;
                        }
                      </style>
                    </defs>
                    <g id="Warstwa_2" data-name="Warstwa 2"><g id="Warstwa_1-2" data-name="Warstwa 1"><g id="Group_151-2" data-name="Group 151-2"><ellipse id="Ellipse_32-2" data-name="Ellipse 32-2" class="cls-1" cx="5.77" cy="3.65" rx="3.32" ry="2.65" /><path id="Path_1381-2" data-name="Path 1381-2" class="cls-1" d="M9,21.08a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2H3v-6a2,2,0,0,1-2-2H1v-4A2,2,0,0,1,3,7H9a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2H9Z" /><ellipse id="Ellipse_33-2" data-name="Ellipse 33-2" class="cls-1" cx="25.68" cy="3.65" rx="3.32" ry="2.65" /><path id="Path_1382-2" data-name="Path 1382-2" class="cls-1" d="M29.08,21.08a2,2,0,0,1-2,2h-2a2,2,0,0,1-2-2h0v-6a2,2,0,0,1-2-2h0v-4a2,2,0,0,1,2-2h6a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2h0Z" /><circle id="Ellipse_34-2" data-name="Ellipse 34-2" class="cls-1" cx="16.04" cy="11.55" r="2.51" /><path id="Path_1383-2" data-name="Path 1383-2" class="cls-1" d="M17.05,14.06H15a2,2,0,0,0-2,2v6a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1v-6a2,2,0,0,0-2-2Z" /></g></g></g>
                  </svg>
                </div>
                <div class="col70">Raport obłożenia</div>
              </div>
              <div class="page-mobile-container-section-dark-rev flex">
                <div class="col30">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
                    <defs>
                      <style>
                        .cls-1 {
                          fill: none;
                          stroke: #b9b5bc;
                          stroke-linecap: round;
                          stroke-linejoin: round;
                          stroke-width: 2px;
                        }
                      </style>
                    </defs>
                    <g id="Warstwa_2" data-name="Warstwa 2"><g id="Warstwa_1-2" data-name="Warstwa 1"><path id="Path_1404" data-name="Path 1404" class="cls-1" d="M32.42,16.3A5.53,5.53,0,0,0,27.3,8.71H21.35L15.16.46A1.16,1.16,0,0,0,14.23,0H9.87A1.16,1.16,0,0,0,8.71,1.16a1.06,1.06,0,0,0,.13.52l3.51,7H8.18L6.34,6.88a1.15,1.15,0,0,0-.82-.34H1.16A1.15,1.15,0,0,0,0,7.69a1.21,1.21,0,0,0,.12.53c1.55,3.08,1.93,3.85,2,4.85h-1A1.16,1.16,0,0,0,0,14.23H0V31.66a1.16,1.16,0,0,0,1.16,1.16H5a4.44,4.44,0,0,0,7.63,0h9.8a4.43,4.43,0,0,0,6.06,1.57A4.51,4.51,0,0,0,30,32.82h3.82A1.16,1.16,0,0,0,35,31.66h0V22.94A9.82,9.82,0,0,0,32.42,16.3Zm-6.21,9.84a4.43,4.43,0,0,0-4.42,4.36H13.21a4.43,4.43,0,0,0-8.85,0h-2V24.11H18.59a3.35,3.35,0,0,0,3.34-3.34V15.4h2v5.37a3.35,3.35,0,0,0,3.34,3.34h5.38V30.5h-2a4.43,4.43,0,0,0-4.43-4.36ZM9.8,15.39v6.39H2.32V15.39Zm9.8,0v5.37a1,1,0,0,1-1,1H12.12V15.39Zm13,6.39H27.3a1,1,0,0,1-1-1V15.48a7.58,7.58,0,0,1,6.31,6.3ZM3,8.86H5l1.84,1.83a1.15,1.15,0,0,0,.82.34h6.54a1.15,1.15,0,0,0,1.16-1.16,1.17,1.17,0,0,0-.12-.52l-3.52-7h1.9l6.19,8.25a1.16,1.16,0,0,0,.93.46H27.3a3.2,3.2,0,0,1,3.2,3.19,3.11,3.11,0,0,1,0,.43,9.85,9.85,0,0,0-5.35-1.58H4.48c-.07-1.23-.34-2-1.44-4.21ZM8.78,32.67a2.11,2.11,0,1,1,2.11-2.1h0a2.1,2.1,0,0,1-2.1,2.1Zm17.43,0a2.11,2.11,0,1,1,2.1-2.1h0a2.1,2.1,0,0,1-2.1,2.1Z" /></g></g>
                  </svg>
                </div>

                <div class="col70">Raport wyjazdów</div>
              </div>
              <div class="page-mobile-container-section-dark-rev flex">
                <div class="col30">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.22 32.22">
                    <defs>
                      <style>
                        .cls-1 {
                          fill: none;
                          stroke: #b9b5bc;
                          stroke-linecap: round;
                          stroke-linejoin: round;
                          stroke-width: 2px;
                        }
                      </style>
                    </defs>
                    <g id="Warstwa_2" data-name="Warstwa 2"><g id="Warstwa_1-2" data-name="Warstwa 1"><g id="Group_162" data-name="Group 162"><path id="Path_1405" data-name="Path 1405" class="cls-1" d="M16.11,32.22A16.11,16.11,0,1,1,32.22,16.11h0A16.13,16.13,0,0,1,16.11,32.22Zm0-30.08a14,14,0,1,0,14,14h0A14,14,0,0,0,16.11,2.14Z" /><path id="Path_1406" data-name="Path 1406" class="cls-1" d="M11.85,15.67a3.83,3.83,0,1,1,3.83-3.82h0A3.83,3.83,0,0,1,11.85,15.67Zm0-5.51a1.69,1.69,0,1,0,1.69,1.69h0A1.7,1.7,0,0,0,11.85,10.16Z" /><path id="Path_1407" data-name="Path 1407" class="cls-1" d="M20.37,24.19a3.83,3.83,0,1,1,3.83-3.82h0A3.83,3.83,0,0,1,20.37,24.19Zm0-5.51a1.69,1.69,0,1,0,1.69,1.69h0a1.69,1.69,0,0,0-1.69-1.69Z" /><path id="Path_1408" data-name="Path 1408" class="cls-1" d="M11.1,24.2A1.07,1.07,0,0,1,10,23.13a1,1,0,0,1,.2-.62l10-14a1.08,1.08,0,0,1,1.49-.29A1.06,1.06,0,0,1,22,9.66l0,.05-10,14A1.08,1.08,0,0,1,11.1,24.2Z" /></g></g></g>
                  </svg>
                </div>
                <div class="col70">Statystyki</div>
              </div>
              <div class="page-mobile-container-section-dark-rev flex">
                <div class="col30">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.6 33.6">
                    <defs>
                      <style>
                        .cls-1 {
                          fill: none;
                          stroke: #b9b5bc;
                          stroke-linecap: round;
                          stroke-linejoin: round;
                          stroke-width: 2px;
                        }
                      </style>
                    </defs>
                    <g id="Warstwa_2" data-name="Warstwa 2"><g id="Warstwa_1-2" data-name="Warstwa 1"><g id="Group_163" data-name="Group 163"><path id="Path_1409" data-name="Path 1409" class="cls-1" d="M16.8,33.6A16.8,16.8,0,1,1,33.6,16.8,16.8,16.8,0,0,1,16.8,33.6Zm0-31.38A14.58,14.58,0,1,0,31.37,16.79h0A14.59,14.59,0,0,0,16.8,2.22Z" /><path id="Path_1410" data-name="Path 1410" class="cls-1" d="M15.24,22.1a1.11,1.11,0,0,1-.79-.33L9.22,16.54A1.12,1.12,0,0,1,10.8,15l4.44,4.44,7.58-7.58A1.12,1.12,0,0,1,24.4,13.4L16,21.77A1.11,1.11,0,0,1,15.24,22.1Z" /></g></g></g>
                  </svg>
                </div>
                <div class="col70">Raport 1</div>
              </div>-->

            </div>
          </div>
        </div>
      </div>

    </section>
  </div>
  <app-footer></app-footer>
</div>
