<div class="reservation-form">
  <app-header></app-header>
  <div class="main-page">

    <section class="section-page-mobile">
      <div class="section-reservation-mobile-top">
        <div class="container-mobile-2">
          <div class="section-reservation-mobile-header-icon-1">
            <a (click)="goBack()"><img class="header-page-user" src="../../../../assets/left.png" alt="" title=""></a>
          </div>
          <div class="section-reservation-mobile-header-icon">
            <h1 class="section-reservation-mobile-header-1">Obłożenie</h1>
          </div>
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showInfo('')"><img class="header-page-user" src="../../../../assets/icon-info.svg" alt="" title=""></a>
          </div>
        </div>
      </div>
      <div class="section-reservation-mobile-top">
        <div class="container-mobile-2 day">
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showPreviousDay()"><img class="header-page-user" src="assets/prev.png" alt="" title=""></a>
          </div>
          <!--<div class="section-reservation-mobile-header-icon-1">
            <a (click)="showCalendar()"><img class="header-page-user" src="../../../../assets/icon_03.png" alt="" title=""></a>
          </div>-->
          <div class="section-reservation-mobile-header-icon">
            <mat-form-field>
              <mat-date-range-input [formGroup]="rangeDate" [rangePicker]="picker" (click)="picker.open()">
                <input matStartDate formControlName="start" (dateChange)="setRangeFrom()" disabled>
                <input matEndDate formControlName="end" (dateChange)="setRangeTo()" disabled>
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
              <mat-error *ngIf="rangeDate.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
              <mat-error *ngIf="rangeDate.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>
            <h1 class="section-reservation-mobile-header-2">{{ formatPercentageValue(occupancyForPeriod) }} <span>%</span></h1>
          </div>
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showNextDay()"><img class="header-page-user" src="../../../../assets/icon_06.png" alt="" title=""></a>
          </div>
        </div>
      </div>

      <div class="section-page-mobile-content">
        <div class="container-mobile">
          <div class="window-mobile-page-content-main">
            <div class="page-mobile-container">

              <div *ngFor="let occupancyReportDay of occupancyReportDays" class="page-mobile-container-section-light-adr flex">
                <div class="col40">{{ occupancyReportDay.Date | date:'yyyy-MM-dd' }}</div>
                <div class="col30">{{ parseValueKind(occupancyReportDay.ValueKind) }}</div>
                <div class="col30r">{{ formatPercentageValue(occupancyReportDay.OccupiedPercentage) }} %</div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </section>
  </div>
  <app-footer></app-footer>
</div>
