import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from './services/user.service';
import { LoginService } from './services/login.service';
import { TokenAuthService } from './shared/token-auth.service';
import { AuthenticationStateService } from './shared/authentication-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements AfterViewInit  {
  title = 'app';

  constructor(public router: Router, private authenticationStateService: AuthenticationStateService, private userService: UserService,
    private loginService: LoginService, private tokenAuthService: TokenAuthService) {
    
  }

  ngAfterViewInit() {

  }

  
}
