import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe, Location } from '@angular/common';
import { DateAdapter } from '@angular/material/core';

import { BaseComponent } from '../shared/base/base.component';

import { UserService } from '../../services/user.service';
import { ConferenceService } from '../../services/conference.service';
import { ReservationService } from '../../services/reservation.service';
//import { AuthenticationStateService } from '../../../shared/authentication-state.service';

import { GetHousekeepingStatusResponseDTO } from '../../dto/get-housekeeping-status-response.DTO';
import { ReservationDTO } from '../../dto/reservation.DTO';
import { ConferenceDTO } from '../../dto/conference.DTO';
import { FormControl } from '@angular/forms';
import { ModalService } from '../../services/modal.service';
import { DescriptionService } from '../../services/description.service';
import { AuthenticationStateService } from '../../shared/authentication-state.service';


@Component({
  selector: 'app-conferences',
  templateUrl: './conferences.component.html',
  styleUrls: ['./conferences.component.scss']
})

export class ConferencesComponent extends BaseComponent implements OnInit, OnDestroy {

  //public userDTO: UserDTO;
  public housekeepingStatusResponseDTO: GetHousekeepingStatusResponseDTO = null;
  /*  public dateFilter: Date = null;*/
  public loading: boolean = false;
  private loadingDelay = null;
  public reservations: ReservationDTO[] = [];
  public conferences: ConferenceDTO[] = [];
  public conference: ConferenceDTO = null;

  public description: any = null;

  public rangeDate = new FormGroup({
    start: new FormControl()
  });

  constructor(private route: ActivatedRoute, protected router: Router, protected datePipe: DatePipe,
    protected authenticationStateService: AuthenticationStateService,
    protected userService: UserService, protected location: Location,
    private conferenceService: ConferenceService, private reservationService: ReservationService, protected dateAdapter: DateAdapter<Date>,
    protected modalService: ModalService, protected descriptionService: DescriptionService) {
    super(router, datePipe, userService, dateAdapter, location, modalService, authenticationStateService)
  }

  ngOnInit() {
    let dateFromParam = this.route.snapshot.queryParamMap.get("dateFrom");
    if (dateFromParam != null && dateFromParam !== '') {
      this.dateFrom = new Date(parseInt(dateFromParam));
      this.dateTo = new Date(parseInt(dateFromParam));
    }

    super.ngOnInit();

    this.setDateControl();
    this.loadData();

    this.descriptionService.get().subscribe(res => {
      this.description = res.Views.PersonInfo;
    });
  }

  ngOnDestroy() {
    this.modalService.remove('conference-info-modal');
  }

  loadData() {

    //  s
    //- Sala: Reservations[].Services[].ConferenceRoom.Name + VariantName
    //     - Firma: Reservations[].ReservingCompany.Name
    //       - jeśli firma pusta: Reservations[].ReservingPerson.LastName + FirstName
    //         - Liczba osób: Reservations[].Services[].AdultGuestCount

    //   Mam świadomość, że metoda zwraca za dużo danych, jednak API mamy przygotowane do różnych celów i nie możemy dla każdego widoku przygotować
    //oddzielnej metody, zwracającej tylko i wyłącznie potrzebne dane.Będziemy jednak pracować nad sposobem określenia przez klienta, które dane
    //mają zostać zwrócone, żeby odciążyć łącze internetowe i deserializację po stronie klienta.

    //let userDTO = this.authenticationStateService.getUser();
    //this.dateFilter = new Date();
    //let dateTo: Date = new Date();
    //dateTo.setDate(this.dateFilter.getDay() + 1);

    //let propertyGuid = 'fa11a7b5-8f20-41be-a400-c472ce0dd56f';
    //let propertyGuid = '92b94e93-339f-4f0a-b4f8-26a54c3e4d24';
    // let propertyGuid = this.userService.getProperty();

    this.reservationService.getConferenceRoom(this.propertyGuid, this.dateFrom, this.dateTo).subscribe(res => {
      if (res.Success) {
        this.reservations = res.Reservations;

        this.conferences = [];

        let conferenceIndex = 0;

        for (let i = 0; i < this.reservations.length; i++) {
          for (let j = 0; j < this.reservations[i].Services.length; j++) {

            let conferenceDTO: ConferenceDTO = new ConferenceDTO();
            conferenceDTO.ReservationDTO = this.reservations[i];
            conferenceDTO.ServiceDTO = this.reservations[i].Services[j];
            conferenceDTO.ConferenceRoom = this.reservations[i].Services[j].ConferenceRoom?.Name + ' ' + this.reservations[i].Services[j].ConferenceRoom?.VariantName;
            conferenceDTO.ReservingCompany = this.reservations[i].ReservingCompany?.Name;
            if (conferenceDTO.ReservingCompany === '' || conferenceDTO.ReservingCompany == null) {
              conferenceDTO.ReservingPerson = this.reservations[i].ReservingPerson?.FirstName + ' ' + this.reservations[i].ReservingPerson?.LastName;
            }
            conferenceDTO.AdultGuestCount = this.reservations[i].Services[j].AdultGuestCount;

            conferenceDTO.ConferenceIndex = conferenceIndex;

            this.conferences.push(conferenceDTO);
            conferenceIndex++;
          }
        }
      }
    });

    //this.conferenceService.get(propertyGuid).subscribe(res => {
    //  debugger;
    //  if (res.Success) {
    //    //this.companies = res.Companies;
    //    //this.housekeepingStatusResponseDTO = res;
    //  }

    //});

  }

  loadDataDelayed() {
    if (this.loadingDelay != null) {
      clearTimeout(this.loadingDelay);
    }

    this.loadingDelay = setTimeout(() => {
      this.loadData();
    }, 1000);
  }

  showNextDay() {
    this.setNextDay();
    this.setDateControl();
    this.loadDataDelayed();
  }

  showPreviousDay() {
    debugger;
    super.setPreviousDay();
    this.setDateControl();
    this.loadDataDelayed();
  }

  public setRangeFrom() {
    // debugger;
    let startDate = this.rangeDate.value.start;
    if (startDate != null) {
      this.dateFrom = new Date(startDate);
      this.dateTo = new Date(startDate);
     // this.dateTo.setDate(this.dateTo.getDate() + 1);
      this.loadData();
    }
  }

  private setDateControl() {
    this.rangeDate.patchValue({ start: new Date(this.dateFrom.toISOString()) });
  }

  dateTimeFormatToDate(dateTime: string) {
    if (dateTime != null && dateTime !== '') {
      let result = this.datePipe.transform(new Date(dateTime), 'yyyy-MM-dd');
      return result;
    }
    else {
      return '';
    }
  }

  dateTimeFormatToTime(dateTime: string) {
    if (dateTime != null && dateTime !== '') {
      let result = this.datePipe.transform(new Date(dateTime), 'HH:mm');
      return result;
    }
    else {
      return '';
    }
  }
  showConferenceInfo(modalId: string, conferenceIndex: number) {
    this.conference = this.conferences.filter(item => {
      return item.ConferenceIndex === conferenceIndex;
    })[0];
    debugger;
    this.modalService.open(modalId);
  }

  closeModal(modalId: string) {
    this.modalService.close(modalId);
  }

  //showNextDay() {

  //  this.setNextDay();
  //  setTimeout(() => {
  //    this.loadData();
  //  }, 1000);

  //}

  //showPreviousDay() {
  //  super.setPreviousDay();
  //  setTimeout(() => {
  //    this.loadData();
  //  }, 1000);
  //}

  //public changeDateFrom() {
  //  super.changeDateFrom();
  //  this.loadData();
  //}

}
