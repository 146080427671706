import { Component, OnInit, ViewEncapsulation, ElementRef, Input } from '@angular/core';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit {
  @Input() id: string;

  private element: any;
  public modalOpen = true;

  constructor(protected modalService: ModalService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {

    //this.modalService.getModal().subscribe((isOpen) => {
    //  debugger;
    //  this.modalOpen = isOpen as boolean;
    //});
   // document.body.appendChild(this.element);

    this.modalService.add(this);

  }

  public openModal() {
    this.element.style.display = 'block';
    //document.body.classList.add('jw-modal-open');
  }

  public closeModal() {
    //this.modalService.closeModal();
    debugger;
    this.element.style.display = 'none';
   // document.body.classList.remove('jw-modal-open');
  }

}
