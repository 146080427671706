import { DatePipe, Location } from "@angular/common";
import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ChartOptions } from "chart.js";
/*import { Color, Label } from 'ng2-charts';*/
import { DateAdapter } from "@angular/material/core";

import { ReportService } from "../../services/report.service";
import { UserService } from "../../services/user.service";
import { BaseComponent } from "../shared/base/base.component";
import { ReportDTO } from "../../dto/report.DTO";
import { ReportParameterValueDTO } from "../../dto/report-parameter-value.DTO";
import { ModalService } from "../../services/modal.service";
import { AuthenticationStateService } from "../../shared/authentication-state.service";

@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.scss"],
})
export class ReportComponent extends BaseComponent implements OnInit, OnDestroy {
  public loading = false;
  private reportGuid = "";
  public report: ReportDTO = null;
  public reportParameterValues: ReportParameterValueDTO[] = [];
  public listValues: string[];
  //public lineChartData: ChartDataSets[] = [
  //  { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
  //];
  //public lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  //public lineChartOptions: (ChartOptions & { annotation: any }) = {
  //  responsive: true,
  //};
  //public lineChartColors: Color[] = [
  //  {
  //    borderColor: 'black',
  //    backgroundColor: 'rgba(255,0,0,0.3)',
  //  },
  //];
  //public lineChartLegend = true;
  //public lineChartType = 'line';
  //public lineChartPlugins = [];

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    private reportService: ReportService,
    protected datePipe: DatePipe,
    protected authenticationStateService: AuthenticationStateService,
    protected userService: UserService,
    protected dateAdapter: DateAdapter<Date>,
    protected location: Location,
    protected modalService: ModalService
  ) {
    super(router, datePipe, userService, dateAdapter, location, modalService, authenticationStateService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.reportGuid = this.route.snapshot.queryParamMap.get("id");

    this.loadData();
  }

  ngOnDestroy() {
    this.modalService.remove('report-description-modal');
  }

  private loadData() {
    this.report = null;

    if (this.reportGuid != null && this.reportGuid !== "") {
      this.reportService
        .getReport(this.propertyGuid, this.reportGuid)
        .subscribe((res) => {
          debugger;
          if (res.Success) {
            if (res.Reports != null && res.Reports.length > 0) {
              this.report = res.Reports[0];

              for (let i = 0; i < this.report.Parameters.length; i++) {
                let reportParamValue: ReportParameterValueDTO =
                  new ReportParameterValueDTO();
                reportParamValue.ParameterDTO = this.report.Parameters[i];
                reportParamValue.ParamValue = null;

                this.reportParameterValues.push(reportParamValue);
              }

              debugger;
            }
          }
        });
    }
  }

  public showReport() {
    debugger;
    this.loading = true;
    // let aa = this.reportParameterValues;
    // this.reportParameterValues
    console.log(this);

    this.reportService
      .generatePdf(
        this.propertyGuid,
        this.reportGuid,
        this.reportParameterValues
      )
      .subscribe(
        (res) => {
          debugger;
          this.loading = false;
          var file = new Blob([res], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        },
        (e) => {
          this.loading = true;
          console.error(e);
        }
      );
    //this.reportService.generatePdf(this.propertyGuid, this.reportGuid, this.reportParameterValues).subscribe(res => {
    //  debugger;
    //  if (res.Success) {

    //  }
    //});
  }
}
