<div class="reservation-header">
  <div class="menu close" #divMenu id="menu">
    <div class="menu-wrapper flex">
      <section class="section-page-mobile">
        <div class="section-reservation-mobile-top">

          <div class="container-mobile-2">
            <div class="section-reservation-mobile-header-icon-close">
              <a (click)="closeMenu()"><img class="header-page-user" src="assets/2_02.png" alt="" title=""></a>
            </div>
          </div>
          <!--<div class="container-mobile-2">
          <div class="section-reservation-mobile-header-icon-menu-icon">
            <a [routerLink]="['/housekeeping']"><img class="header-page-user" src="assets/12_03.png" alt="" title=""></a>
          </div>
          <div class="section-reservation-mobile-header-icon-menu-link">
            <a [routerLink]="['/housekeeping']">Status pokoi</a>
          </div>
        </div>-->
          <div class="container-mobile-2" *ngIf="isManager">
            <div class="section-reservation-mobile-header-icon-menu-icon">
              <a [routerLink]="['/adr']"><img class="header-page-user" src="assets/2_05.png" alt="" title=""></a>
            </div>
            <div class="section-reservation-mobile-header-icon-menu-link">
              <a [routerLink]="['/adr']">ADR</a>
            </div>
          </div>
          <div class="container-mobile-2" *ngIf="isManager || isHsk">
            <div class="section-reservation-mobile-header-icon-menu-icon">
              <a [routerLink]="['/occupancy']"><img class="header-page-user" src="assets/2_07.png" alt="" title=""></a>
            </div>
            <div class="section-reservation-mobile-header-icon-menu-link">
              <a [routerLink]="['/occupancy']">Obłożenie</a>
            </div>
          </div>
          <div class="container-mobile-2" *ngIf="isManager">
            <div class="section-reservation-mobile-header-icon-menu-icon">
              <a [routerLink]="['/revenue']"><img class="header-page-user" src="assets/2_08.png" alt="" title=""></a>
            </div>
            <div class="section-reservation-mobile-header-icon-menu-link">
              <a [routerLink]="['/revenue']">Raporty</a>
            </div>
          </div>
          <div class="container-mobile-2 padding30" *ngIf="isManager">
            <div class="section-reservation-mobile-header-icon-menu-icon">
              <a [routerLink]="['/companies']"><img class="header-page-user" src="assets/2_10.png" alt="" title=""></a>
            </div>
            <div class="section-reservation-mobile-header-icon-menu-link">
              <a [routerLink]="['/companies']">Kartoteka firm</a>
            </div>
          </div>
          <div class="container-mobile-2" *ngIf="isManager">
            <div class="section-reservation-mobile-header-icon-menu-icon">
              <a [routerLink]="['/guests']"><img class="header-page-user" src="assets/2_11.png" alt="" title=""></a>
            </div>
            <div class="section-reservation-mobile-header-icon-menu-link">
              <a [routerLink]="['/guests']">Kartoteka gości</a>
            </div>
          </div>
          <div class="container-mobile-2" *ngIf="isManager">
            <div class="section-reservation-mobile-header-icon-menu-icon">
              <a [routerLink]="['/conferences']"><img class="header-page-user" src="assets/2_12.png" alt=""
                  title=""></a>
            </div>
            <div class="section-reservation-mobile-header-icon-menu-link">
              <a [routerLink]="['/conferences']">Konferencje</a>
            </div>
          </div>

          <div class="container-mobile-2" *ngIf="isHsk || isTech">
            <div class="section-reservation-mobile-header-icon-menu-icon">
              <a [routerLink]="['/housekeeping']"><img class="header-page-user" src="assets/2_10.png" alt=""
                  title=""></a>
            </div>
            <div class="section-reservation-mobile-header-icon-menu-link">
              <a [routerLink]="['/housekeeping']">Status pokoi</a>
            </div>
          </div>
          <div class="container-mobile-2 padding30" *ngIf="isManager || isHsk || isTech">
            <div class="section-reservation-mobile-header-icon-menu-icon">
              <a [routerLink]="['/activity-log']"><img class="header-page-user" src="assets/2_14.png" alt=""
                  title=""></a>
            </div>
            <div class="section-reservation-mobile-header-icon-menu-link">
              <a [routerLink]="['/activity-log']">Dziennik zadań</a>
            </div>
          </div>

          <div class="container-mobile-2 padding30" *ngIf="isHsk || isTech">
            <div class="section-reservation-mobile-header-icon-menu-icon">
              <a [routerLink]="['/maintenance-add']"><img class="header-page-user" src="assets/12_07.png" alt=""
                  title=""></a>
            </div>
            <div class="section-reservation-mobile-header-icon-menu-link">
              <a [routerLink]="['/maintenance-add']">Zgłoś usterke</a>
            </div>
          </div>
          <div class="container-mobile-2" *ngIf="isHsk || isTech">
            <div class="section-reservation-mobile-header-icon-menu-icon">
              <a [routerLink]="['/lost-and-found-add']"><img class="header-page-user"
                  src="assets/14_Status-pokoi-–-rozszerzenie_09.png" alt="" title=""></a>
            </div>
            <div class="section-reservation-mobile-header-icon-menu-link">
              <a [routerLink]="['/lost-and-found-add']">Rzeczy znalezione</a>
            </div>
          </div>

          <div class="container-mobile-2" *ngIf="isManager || isHsk || isTech">
            <div class="section-reservation-mobile-header-icon-menu-icon">
              <a [routerLink]="['/chat-messages']"><img class="header-page-user" src="assets/2_15.png" alt=""
                  title=""></a>
            </div>
            <div class="section-reservation-mobile-header-icon-menu-link">
              <a [routerLink]="['/chat-messages']">Wiadomości</a>
            </div>
          </div>

          <div class="container-mobile-2 padding50" *ngIf="isManager || isHsk || isTech">
            <div class="section-reservation-mobile-header-icon-menu-icon">
              <a (click)="logout()"><img class="header-page-user" src="assets/2_17.png" alt="" title=""></a>
            </div>
            <div class="section-reservation-mobile-header-icon-menu-link">
              <a (click)="logout()">Wyloguj</a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div class="header-page-all">
    <div class="container-mobile">
      <a (click)="showMainPanel()"><img class="header-page-logo" src="assets/logo.svg" alt="" title=""></a>
      <div class="header-page-icon-right">
        <div class="d-flex">

          <select name="currentObject" id="currentObject" title="current-object" [(ngModel)]="propertyId"
            (change)="onPropertyChange()">
            <option value="" disabled selected hidden>Wybierz obiekt</option>
            <option value="item.Guid" *ngFor="let item of properties" [ngValue]="item.Guid">{{ item.Name }}
            </option>
          </select>

          <a (click)="showMenu()" class="header-page-icon-right-link">
            <img class="header-page-button-menu" src="assets/icon-menu.svg" id="open" alt="" title="">
          </a>
        </div>

      </div>
    </div>
  </div>
</div>