<div class="reservation-form">
  <app-header></app-header>
  <div class="main-page">

    <app-modal style="z-index: 3; position: relative" id="guest-info-modal">
      <p class="close"><a (click)="closeModal('guest-info-modal')"><img src="../../../../assets/icon-close-two.svg"></a>
      </p>
      <h3 class="name">{{ guestFirstLastName }}<span>Informacje o gościu</span></h3>
      <h3>{{ roomNo }}</h3>
      <div class="date">
        <h4>Data zameldowania</h4>
        <ul>
          <li><img src="../../../../assets/icon-calendar.svg" alt="Placeholder"> {{ startDate }}</li>
          <li><img src="../../../../assets/icon-clock.svg" alt="Placeholder"> {{ startTime }}</li>
          <li><img src="../../../../assets/icon-calendar.svg" alt="Placeholder"> {{ endDate }}</li>
          <li><img src="../../../../assets/icon-clock.svg" alt="Placeholder"> {{ endTime }}</li>
          <li class="a"><img src="../../../../assets/icon-user2.svg" alt="Placeholder"> Ilość osób <span>{{
              adultGuestCount }}</span></li>
        </ul>
      </div>
      <div class="description">
        <h4>Pobyt</h4>
        <p>{{ serviceKind }}</p>
      </div>
      <div class="price">
        <p class="name"><img src="../../../../assets/icon-card.svg" alt="Placeholder"> Planowany<br> przychód</p>
        <p>{{ amount }} <span>Pln</span></p>
      </div>
      <div class="info">
        <div>
          <h4>Organizator:</h4>
          <p>PMS</p>
          <p>+48 612 345 675</p>
        </div>
        <div>
          <h4>Os. odpowiedzialna:</h4>
          <p>login HTLL</p>
          <p>+48 612 345 675</p>
        </div>
      </div>
    </app-modal>

    <section class="section-page-mobile">
      <div class="section-reservation-mobile-top">
        <div class="container-mobile-2">
          <div class="section-reservation-mobile-header-icon-1">
            <a (click)="goBack()"><img class="header-page-user" src="../../../../assets/left.png" alt="" title=""></a>
          </div>
          <div class="section-reservation-mobile-header-icon">
            <h1 class="section-reservation-mobile-header-1">Goście</h1>
          </div>
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showInfo(null)"><img class="header-page-user" src="../../../../assets/icon-info.svg" alt=""
                title=""></a>
          </div>
        </div>
      </div>

      <div class="section-reservation-mobile-top">
        <div class="container-mobile-2 day">
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showPreviousDay()"><img class="header-page-user" src="assets/prev.png" alt="" title=""></a>
          </div>
          <!--<div class="section-reservation-mobile-header-icon-1">
            <a (click)="showCalendar()"><img class="header-page-user" src="../../../../assets/icon_03.png" alt="" title=""></a>
          </div>-->
          <div class="section-reservation-mobile-header-icon">
            <h1 class="section-reservation-mobile-header-1">
              <div class="d-flex flex-column">
                <span class="d-block">{{ currentDayName}},</span>
                <div class="d-flex">
                  <mat-form-field [formGroup]="rangeDate" (click)="picker.open()">
                    <input matInput formControlName="start" [matDatepicker]="picker" (dateChange)="setRangeFrom()"
                      disabled>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                    <mat-error *ngIf="rangeDate.controls.start.hasError('matStartDateInvalid')">Invalid start date
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>


            </h1>
            <!--<h1 class="section-reservation-mobile-header-3">21.10.2021</h1>-->
            <!-- <input type="date" class="section-reservation-mobile-input" [(ngModel)]="dateFromFilter" value="{{ dateFromFilter | date:'yyyy-MM-dd' }}" #ctrl="ngModel" required (ngModelChange)="changeDateFrom()" /> -->
          </div>
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showNextDay()"><img class="header-page-user" src="../../../../assets/icon_06.png" alt=""
                title=""></a>
          </div>
        </div>
      </div>
      <div class="section-page-mobile-content" (scroll)="showTopBtn()">
        <div class="container-mobile">
          <div class="window-mobile-page-content-main">
            <div class="page-mobile-container" #top>
              <div class="page-mobile-container-section-light searcher">
                <a>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.33 23.33">
                    <defs>
                      <style>
                        .cls-1 {
                          fill: none;
                          stroke: #bdb9c0;
                          stroke-linecap: round;
                          stroke-linejoin: round;
                          stroke-width: 2px;
                        }
                      </style>
                    </defs>
                    <g id="Warstwa_2" data-name="Warstwa 2">
                      <g id="Warstwa_1-2" data-name="Warstwa 1">
                        <g id="Group_164" data-name="Group 164">
                          <circle id="Ellipse_35" data-name="Ellipse 35" class="cls-1" cx="10.28" cy="10.28" r="9.28" />
                          <line id="Line_85" data-name="Line 85" class="cls-1" x1="22.33" y1="22.33" x2="16.83"
                            y2="16.83" />
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
                <input type="text" [(ngModel)]="guestName" placeholder="Wyszukaj" #ctrl="ngModel"
                  (keydown.enter)="filterGuests()" />
                <a (click)="filterGuests()">
                  <img src="../../../../assets/8_Goście_03.png" />
                </a>
              </div>

              <div id="guest-list" class="guest">
                <div class="page-mobile-container-section-dark-rev flex on-focus-opacity-90"
                  (click)="showGuestInfo('guest-info-modal', item.Guid, item.ServiceGuid)" *ngFor="let item of guests">

                  <div class="single-guest container ">
                    <div class="single-guest--left">
                      <div class="single-guest--name">{{ item.Person?.FirstName }} {{ item.Person?.LastName }}
                      </div>
                    </div>
                    <div class="single-guest--right">
                      <div class="single-guest--room">p. {{ item.Room?.Number }}</div>
                      <div><a><img alt="arrow-right-icons" src="../../../../assets/icon_18.png"></a>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </section>
  </div>
  <app-footer></app-footer>
</div>
