import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class DateHelper {

  static weekdays = ['poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota', 'niedziela'];

  public static getDayName(indexDay: number) {
    
    if (indexDay < this.weekdays.length) {
      
      return this.weekdays[indexDay];
    }

    return '';
  }
}
