import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DescriptionDTO } from '../dto/description-dto';
import { TokenAuthService } from '../shared/token-auth.service';

import { AppConfig } from '../config/app.config';

@Injectable({
  providedIn: 'root'
})
export class DescriptionService {

  constructor(private http: HttpClient, private datePipe: DatePipe, private tokenAuthService: TokenAuthService) { }

  get(): Observable<DescriptionDTO> {

    return this.http.get<DescriptionDTO>(`${AppConfig.apiUrl}/Description`);
  }
}
