<div class="reservation-form">
  <app-header></app-header>
  <div class="main-page">

    <section class="section-page-mobile">
      <div class="section-reservation-mobile-top">
        <div class="container-mobile-2">
          <div class="section-reservation-mobile-header-icon-1">
            <a (click)="goBack()"><img class="header-page-user" src="../../../../../assets/left.png" alt=""
                title=""></a>
          </div>
          <div class="section-reservation-mobile-header-icon">
            <h1 class="section-reservation-mobile-header-1">Zgłoś usterke</h1>
          </div>
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showInfo('')"><img class="header-page-user" src="../../../../../assets/icon-info.svg" alt=""
                title=""></a>
          </div>
        </div>
      </div>

      <div class="section-page-mobile-content">
        <div class="container-mobile">
          <div class="window-mobile-page-content-main">
            <div class="page-mobile-container">
              <div class="page-mobile-container-section-light">
                <!--<select [(ngModel)]="department">
                  <option *ngFor="let item of departments" [ngValue]="item">{{item.name}}</option>
                </select>-->
                <label>{{ department.name }}</label>
              </div>
              <div class="page-mobile-container-section-light">
                <!--<input value="Pokój 007">-->
                <!--<input type="text" [(ngModel)]="roomNo" #ctrl="ngModel" />-->
                <select [(ngModel)]="roomId">
                  <option></option>
                  <option *ngFor="let item of rooms" [ngValue]="item.Guid">Pokój {{ item.Number }}</option>
                </select>
              </div>
              <p>Opis usterki:</p>
              <div class="page-mobile-container-section-light">
                <textarea [(ngModel)]="content" #ctrl="ngModel">Treść zgłoszenia</textarea>
              </div>
              <div class="flex">
                <button (click)="cancel()" class="btn-light" [disabled]="content !== ''">Anuluj</button>
                <button (click)="send()" class="btn-dark" [disabled]="content === ''">Wyślij zgłoszenie</button>
              </div>
            </div>


          </div>
        </div>
      </div>

    </section>

  </div>
  <app-footer></app-footer>
</div>
