import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { DatePipe, Location } from '@angular/common';
import { DateAdapter } from '@angular/material/core';


import { BaseComponent } from '../shared/base/base.component';

import { AdrService } from '../../services/adr.service';
import { AdrRespDTO } from '../../dto/adr.resp.DTO';
import { UserService } from '../../services/user.service';
import { DescriptionService } from '../../services/description.service';
import { ModalService } from '../../services/modal.service';
import { AuthenticationStateService } from '../../shared/authentication-state.service';


@Component({
  selector: 'app-companies',
  templateUrl: './adr.component.html',
  styleUrls: ['./adr.component.scss']
})

export class AdrComponent extends BaseComponent implements OnInit {

  public adrRespDTO: AdrRespDTO = null;
  public loading: boolean = false;
  private loadingDelay = null;
  public description: any = null;

  public rangeDate = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  constructor(private route: ActivatedRoute, protected router: Router, protected datePipe: DatePipe,
    protected authenticationStateService: AuthenticationStateService,
    protected userService: UserService, private adrService: AdrService, protected dateAdapter: DateAdapter<Date>,
    protected location: Location, protected descriptionService: DescriptionService, protected modalService: ModalService) {
    super(router, datePipe, userService, dateAdapter, location, modalService, authenticationStateService);
  }

  ngOnInit() {
    debugger;
    let dateFromParam = this.route.snapshot.queryParamMap.get("dateFrom");
    if (dateFromParam != null && dateFromParam !== '') {
      this.dateFrom = new Date(parseInt(dateFromParam));
      this.setDateTo(this.dateFrom.getDate() + 1);
    }

    super.ngOnInit();
    super.initDate(true);
    this.setDateControl();
    this.loadData();

    this.descriptionService.get().subscribe(res => {
      this.description = res.Views.ADRInfo;
    });
  }

  loadData() {

    this.adrService.get(this.propertyGuid, this.dateFrom, this.dateTo).subscribe(res => {
      debugger;
      this.loading = false;
      if (res.Success) {
        this.adrRespDTO = res;
      }
    });
  }

  loadDataDelayed() {
    if (this.loadingDelay != null) {
      clearTimeout(this.loadingDelay);
    }

    this.loadingDelay = setTimeout(() => {
      this.loadData();
    }, 1000);
  }

  showNextDay() {
    this.setNextDay();
    this.setDateControl();

    this.loadDataDelayed();
  }

  showPreviousDay() {
   // debugger;
    super.setPreviousDay();
    this.setDateControl();

    this.loadDataDelayed();
  }

  //public changeDateFrom() {
  //  super.changeDateFrom();
  //  this.setDateControl();
  //  this.loadData();
  //}

  //public changeDateTo() {
  //  super.changeDateTo();

  //  this.loadData();
  //}

  public setRangeFrom() {
   // debugger;
    let startDate = this.rangeDate.value.start;
    if (startDate != null) {
      this.dateFrom = new Date(startDate);
    }
  }

  public setRangeTo() {
   // debugger;
    let endDate = this.rangeDate.value.end;
    if (endDate != null) {
      let newEndDate = new Date(endDate);
      if (newEndDate !== this.dateTo) {
        this.dateTo = newEndDate;
        this.loadData();
      }
    }
  }

  public setRange() {
    debugger;
  }

  private setDateControl() {
    this.rangeDate.patchValue({ start: new Date(this.dateFrom.toISOString()) });
    this.rangeDate.patchValue({ end: new Date(this.dateTo.toISOString()) });
  }

  public parseValueKind(valueKind: string) {
    if (valueKind === 'Planned') {
      return 'planowane';
    }
    else if (valueKind === 'Actual') {
      return 'zrealizowane';
    }
    else if (valueKind === 'Unknown') {
      return 'nieznane';
    }
  }
}
