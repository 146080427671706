import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConfig } from '../config/app.config';

import { TokenAuthService } from '../shared/token-auth.service';

import { LostAndFoundRespDTO } from '../dto/lost-and-found.resp.DTO';
import { GetHousekeepingStatusResponseDTO } from '../dto/get-housekeeping-status-response.DTO';
import { MaintenanceRespDTO } from '../dto/maintenance.resp.DTO';
import { MaintenanceDTO } from '../dto/maintenance.DTO';
import { MaintenanceReqDTO } from '../dto/maintenane.req.DTO';


@Injectable({
  providedIn: 'root'
})

export class MaintenanceService {

  constructor(private http: HttpClient, private tokenAuthService: TokenAuthService) { }

  get(propertyGuid: string): Observable<MaintenanceRespDTO> {

    return this.http.get<MaintenanceRespDTO>(`${AppConfig.apiUrl}/Maintenance/${propertyGuid}`);
  }

  /*
   * Zgłoszenie usterki
   */
  add(propertyGuid: string, maintenanceReqDTO: MaintenanceReqDTO): Observable<any> {

    return this.http.post<any>(`${AppConfig.apiUrl}/Maintenance/${propertyGuid}`, maintenanceReqDTO);
  }
}
