import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from '@angular/common';

import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {

  department = '';

  constructor(private route: ActivatedRoute, private router: Router, private userService: UserService) {
  
  }

  ngOnInit() {
    this.department = this.userService.getDepartment();
  }

  showMainPanel() {

    if (this.department === 'Manager') {
      this.router.navigate(['/main-panel-director']);
    }
    else if (this.department === 'HSK') {
      this.router.navigate(['/main-panel-hsk']);
    }
    else if (this.department === 'TECH') {
      this.router.navigate(['/main-panel-tech']);
    }
  }

  showActivitLog() {
    this.router.navigate(['/activity-log']);
  }

  showChatMessages() {
    this.router.navigate(['/chat-messages']);
  }
}
