import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe, Location } from '@angular/common';
import { DateAdapter } from '@angular/material/core';

import { BaseComponent } from '../../../shared/base/base.component';

import { UserService } from '../../../../services/user.service';
import { MaintenanceService } from '../../../../services/maintenance.service';
import { LostAndFoundService } from '../../../../services/lost-and-found.service';

import { MaintenanceDTO } from '../../../../dto/maintenance.DTO';
import { FormControl } from '@angular/forms';
import { LostFoundItemDTO } from '../../../../dto/lost-found-item.DTO';
import { ModalService } from '../../../../services/modal.service';
import { AuthenticationStateService } from '../../../../shared/authentication-state.service';


/*
 * 20_Dziennik działań – lista usterek
 */
@Component({
  selector: 'app-activity-log-maintenance',
  templateUrl: './activity-log-maintenance.component.html',
  styleUrls: ['./activity-log-maintenance.component.scss']
})

export class ActivityLogMaintenanceComponent extends BaseComponent implements OnInit {
  @ViewChild('top') top: ElementRef;
  @ViewChild('topBtn') topBtn: ElementRef;

  public maintenances: MaintenanceDTO[] = [];
  public lostAndFoundItems: LostFoundItemDTO[] = [];
  public itemName = '';
  public loading: boolean = false;
  private loadingDelay = null;

  public rangeDate = new FormGroup({
    start: new FormControl()
  });

  constructor(private route: ActivatedRoute, protected router: Router, protected datePipe: DatePipe,
    protected authenticationStateService: AuthenticationStateService,
    protected userService: UserService, private maintenanceService: MaintenanceService, private lostAndFoundService: LostAndFoundService,
    protected dateAdapter: DateAdapter<Date>,
    protected location: Location, protected modalService: ModalService) {
    super(router, datePipe, userService, dateAdapter, location, modalService, authenticationStateService)
  }

  ngOnInit() {

    let dateFromParam = this.route.snapshot.queryParamMap.get("dateFrom");
    if (dateFromParam != null && dateFromParam !== '') {
      this.dateFrom = new Date(parseInt(dateFromParam));
      this.dateTo = new Date(parseInt(dateFromParam));
    }

    super.ngOnInit();
    this.setDateControl();
    this.loadData();
  }

  loadData() {

    //this.lostAndFoundService.get(this.propertyGuid).subscribe(res => {
    // // debugger;
    //  if (res.Success) {
    //    this.lostAndFoundItems = res.LostFoundItems;
    //  }
    //})
    this.maintenanceService.get(this.propertyGuid).subscribe(res => {
      if (res.Success) {
        debugger;
        this.maintenances = res.Maintenances;
      }
    });
  }

  loadDataDelayed() {
    if (this.loadingDelay != null) {
      clearTimeout(this.loadingDelay);
    }

    this.loadingDelay = setTimeout(() => {
      this.loadData();
    }, 1000);
  }

  showNextDay() {
    this.setNextDay();
    this.setDateControl();
    this.loadDataDelayed();
  }

  showPreviousDay() {
    debugger;
    super.setPreviousDay();
    this.setDateControl();
    this.loadDataDelayed();
  }

  scrollToTop() {
    this.top.nativeElement.scrollIntoView();
  }

  showTopBtn() {
    const rect = this.top.nativeElement.getBoundingClientRect();

    if (rect.top < 120) {
      this.topBtn.nativeElement.classList.remove('inactive');
    } else {
      this.topBtn.nativeElement.classList.add('inactive');
    }
  }

  public setRangeFrom() {
    // debugger;
    let startDate = this.rangeDate.value.start;
    if (startDate != null) {
      this.dateFrom = new Date(startDate);
      this.dateTo = new Date(startDate);
     // this.dateTo.setDate(this.dateTo.getDate() + 1);
      this.loadData();
    }
  }

  private setDateControl() {
    this.rangeDate.patchValue({ start: new Date(this.dateFrom.toISOString()) });
  }

  //showNextDay() {
  //  this.setNextDay();
  //  setTimeout(() => {
  //    this.loadData();
  //  }, 1000);
  //}

  //public changeDateFrom() {
  //  super.changeDateFrom();
  //  this.loadData();
  //}

  filterItems() {
    this.loadData();
  }

  sortItems() {

  }
}
