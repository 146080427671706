import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConfig } from '../config/app.config';

import { TokenAuthService } from '../shared/token-auth.service';

import { LostAndFoundRespDTO } from '../dto/lost-and-found.resp.DTO';
import { GetHousekeepingStatusResponseDTO } from '../dto/get-housekeeping-status-response.DTO';
import { ServiceKindRespDTO } from '../dto/service-kind-resp.DTO';
import { MinibarDTO } from '../dto/minibar.DTO';


@Injectable({
  providedIn: 'root'
})
  /*
   * Statusy pokoi
   */
export class HousekeepingService {

  constructor(private http: HttpClient, private tokenAuthService: TokenAuthService) { }

  /*
   * Zwraca informacje o wszystkich pokojach i ich statusach
   */
  get(propertyGuid: string): Observable<GetHousekeepingStatusResponseDTO> {
   // debugger;
    //let jwtJson = this.tokenAuthService.getJwtToken();
    //let jwt = JSON.parse(jwtJson);

    //var reqHeader = new HttpHeaders({
    //  'Content-Type': 'application/json',
    //  'Authorization': jwt.access_token
    //});

    return this.http.get<GetHousekeepingStatusResponseDTO>(`${AppConfig.apiUrl}/Housekeeping/${propertyGuid}`);
  }

  getByRoom(propertyGuid: string, roomGuid: string): Observable<GetHousekeepingStatusResponseDTO> {
    return this.http.get<GetHousekeepingStatusResponseDTO>(`${AppConfig.apiUrl}/Housekeeping/${propertyGuid}?searchFilters.roomGuids=${roomGuid}`);
  }

  getByRoomNumber(propertyGuid: string, roomNumber: string): Observable<GetHousekeepingStatusResponseDTO> {
    return this.http.get<GetHousekeepingStatusResponseDTO>(`${AppConfig.apiUrl}/Housekeeping/${propertyGuid}?searchFilters.roomNumber=${roomNumber}`);
  }

  /*
   * Zwraca informację o pokojach do posprzątania
   */
  getCleaning(propertyGuid: string): Observable<GetHousekeepingStatusResponseDTO> {
    

    return this.http.get<GetHousekeepingStatusResponseDTO>(`${AppConfig.apiUrl}/Housekeeping/Cleaning/${propertyGuid}`);
  }

  /*
   * Zwraca informację o pokojach do posprzątania dla aktualnie zalogowanego użytkownika
   */
  getCleaningForUser(propertyGuid: string): Observable<GetHousekeepingStatusResponseDTO> {
   
    return this.http.get<GetHousekeepingStatusResponseDTO>(`${AppConfig.apiUrl}/Housekeeping/Cleaning/ForUser/${propertyGuid}`);
  }

  getMinibar(propertyGuid: string): Observable<ServiceKindRespDTO> {

    return this.http.get<ServiceKindRespDTO>(`${AppConfig.apiUrl}/Housekeeping/ServiceKind/Minibar/${propertyGuid}`);
  }

  addMinibar(propertyGuid: string, minibarDTO: MinibarDTO) {

    return this.http.post<any>(`${AppConfig.apiUrl}/Housekeeping/Service/Minibar/${propertyGuid}`, minibarDTO);
  }

  setHouseKeepingStatus(propertyGuid: string, roomGuid: string, status: string) {
    const body = { };
    return this.http.put<any>(`${AppConfig.apiUrl}/Housekeeping/${propertyGuid}/${roomGuid}/${status}`, body);
  }
}
