<div class="reservation-form">
  <app-header></app-header>
  <div class="main-page">

    <app-modal id="report-description-modal">
      <p class="close">
        <a (click)="closeModal('report-description-modal')">
          <img src="../../../../assets/icon-close-two.svg">
        </a>
      </p>
      <div class="description">
        {{ report?.Description }}
      </div>

    </app-modal>

    <section class="section-page-mobile">
      <div class="section-reservation-mobile-top">
        <div class="container-mobile-2">
          <div class="section-reservation-mobile-header-icon-1">
            <a (click)="goBack()"><img class="header-page-user" src="assets/left.png" alt="" title=""></a>
          </div>
          <div class="section-reservation-mobile-header-icon">
            <h1 class="section-reservation-mobile-header-1">{{ report?.Name }}</h1>
          </div>
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showInfo('report-description-modal')"><img class="header-page-user" src="assets/icon-info.svg"
                alt="" title=""></a>
          </div>
        </div>
      </div>
      <!--<div class="section-reservation-mobile-top">
      <div class="container-mobile-2 day">
        <div class="section-reservation-mobile-header-icon-2">
          <a href=""><img class="header-page-user" src="assets/icon_03.png" alt="" title=""></a>
        </div>
        <div class="section-reservation-mobile-header-icon negative-left">
          <h1 class="section-reservation-mobile-header-3">21.10.2021</h1>
        </div>
        <div class="section-reservation-mobile-header-icon-2">
          <a href=""><img class="header-page-user" src="assets/icon_06.png" alt="" title=""></a>
        </div>
      </div>
    </div>-->



      <div class="section-page-mobile-content">
        <div class="container-mobile">
          <div class="window-mobile-page-content-main">
            <div class="page-mobile-container">
              <!--<div class="page-mobile-container-section-light-report flex">
              <div>
                {{ report?.Name }}<br>
              </div>

            </div>
            <div class="page-mobile-container-section-light-report flex">

              <div>
                {{ report?.Description }}
              </div>
            </div>-->
              <div class="page-mobile-container-section-light-report" *ngFor="let item of reportParameterValues">

                <!--<div class="col50 text-center">{{ item.ParameterDTO.Kind }}</div>-->
                <!--'String', 'Integer', 'Decimal', 'Date', 'Time', 'DateTime', 'Boolean', 'List', 'ListMultiselect']-->

                <div class="flex" *ngIf="item.ParameterDTO.Kind === 'String'">
                  <label for="paramText">{{ item.ParameterDTO.Name }}</label>
                  <input type="text" class="col50" id="paramText" [(ngModel)]="item.ParamValue" #ctrl="ngModel"
                    [disabled]="loading" />
                </div>

                <div class="flex" *ngIf="item.ParameterDTO.Kind === 'Integer'">
                  <label for="paramText">{{ item.ParameterDTO.Name }}</label>
                  <input type="text" class="col50" id="paramText" appIntegerNumber [(ngModel)]="item.ParamValue"
                    #ctrl="ngModel" [disabled]="loading" />
                </div>

                <div class="flex" *ngIf="item.ParameterDTO.Kind === 'Decimal'">
                  <label for="paramText">{{ item.ParameterDTO.Name }}</label>
                  <input type="text" class="col50" id="paramText" appTwoDigitDecimalNumber [(ngModel)]="item.ParamValue"
                    #ctrl="ngModel" [disabled]="loading" />
                </div>

                <!--date-->
                <div class="flex" *ngIf="item.ParameterDTO.Kind === 'Date'">
                  <label for="paramDate">{{ item.ParameterDTO.Name }}</label>
                  <input type="date" id="paramDate" class="col50" [(ngModel)]="item.ParamValue" #ctrl="ngModel"
                    [disabled]="loading" />
                </div>

                <!--time-->
                <div class="flex" *ngIf="item.ParameterDTO.Kind === 'Time'">
                  <label for="paramTime">{{ item.ParameterDTO.Name }}</label>
                  <input type="time" id="paramTime" class="col50" [(ngModel)]="item.ParamValue" #ctrl="ngModel"
                    [disabled]="loading" />
                </div>

                <!--datetime-->
                <div class="flex" *ngIf="item.ParameterDTO.Kind === 'DateTime'">
                  <label for="paramDateTime">{{ item.ParameterDTO.Name }}</label>
                  <input type="datetime-local" class="col50" id="paramDateTime" [(ngModel)]="item.ParamValue"
                    #ctrl="ngModel" [disabled]="loading" />
                </div>

                <!--checkbox-->
                <div class="d-flex align-items-center mb-2" *ngIf="item.ParameterDTO.Kind === 'Boolean'">
                  <label for="paramCheckbox">{{ item.ParameterDTO.Name }}</label>
                  <input type="checkbox" class="checkbox__report-flag" id="paramCheckbox" [(ngModel)]="item.ParamValue"
                    #ctrl="ngModel" [disabled]="loading" />
                </div>

                <!--list-->
                <div class="flex" *ngIf="item.ParameterDTO.Kind === 'List'">
                  <label for="paramList">{{ item.ParameterDTO.Name }}</label>
                  <select id="paramList" class="col50" [(ngModel)]="item.ParamValue" #ctrl="ngModel"
                    [disabled]="loading">
                    <option *ngFor="let itemOption of item.ParameterDTO.List.Choices"
                      [ngValue]="itemOption[item.ParameterDTO.List.ValueFieldName]">
                      {{
                      itemOption[item.ParameterDTO.List.DisplayFieldName]
                      }}
                    </option>
                  </select>
                </div>

                <!--list multiselect-->
                <div class="flex" *ngIf="item.ParameterDTO.Kind === 'ListMultiselect'">
                  <label for="paramListMultiSelect">{{ item.ParameterDTO.Name }}</label>
                  <select id="paramListMultiSelect" class="col50" multiple [(ngModel)]="item.ParamValue" #ctrl="ngModel"
                    [disabled]="loading">
                    <option *ngFor="let itemOption of item.ParameterDTO.List.Choices"
                      [ngValue]="itemOption[item.ParameterDTO.List.ValueFieldName]">
                      {{
                      itemOption[item.ParameterDTO.List.DisplayFieldName]
                      }}
                    </option>
                  </select>
                </div>

              </div>
              <div class="page-mobile-container-section-light-report flex">
                <button class="btn position-relative" (click)="showReport()" [disabled]="loading">
                  Wyświetl
                  <app-spinner *ngIf="this.loading"></app-spinner>
                </button>

              </div>
              <!--<div class="page-mobile-container-section-light-report flex">
              <div class="col25 strong">000000</div>
              <div class="col50">Lorem ipsum</div>
              <div class="col25 strong text-right">000000</div>
            </div>
            <div class="page-mobile-container-section-light-report flex">
              <div class="col75">Lorem ipsum</div>
              <div class="col25 strong text-right">000000</div>
            </div>
            <div class="page-mobile-container-section-light-report flex">
              <div class="col25 strong">000000</div>
              <div class="col75">{{ report?.Name }}</div>
            </div>-->
              <div class="module-chart">
                <!--<img src="assets/chart1.png" alt="Placeholder">-->
                <!--<canvas baseChart width="400" height="400"
                      [datasets]="lineChartData"
                      [labels]="lineChartLabels"
                      [options]="lineChartOptions"
                      [colors]="lineChartColors"
                      [legend]="lineChartLegend"
                      [chartType]="lineChartType"
                      [plugins]="lineChartPlugins">
              </canvas>-->

              </div>
            </div>
          </div>
        </div>
      </div>

    </section>

  </div>
</div>