import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from '@angular/common';

import { GuestService } from '../../../services/guest.service';
import { ServiceService } from '../../../services/service.service';
import { UserService } from '../../../services/user.service';

import { GetHousekeepingStatusResponseDTO } from '../../../dto/get-housekeeping-status-response.DTO';



@Component({
  selector: 'app-guest-info',
  templateUrl: './guest-info.component.html',
  styleUrls: ['./guest-info.component.scss']
})

export class GuestInfoComponent implements OnInit {

  //public userDTO: UserDTO;
  public housekeepingStatusResponseDTO: GetHousekeepingStatusResponseDTO = null;
  public companyName: string = null;
  public loading: boolean = false;

  public roomNo = '';
  public startDateTime: Date = null;
  public endDateTime: Date = null;
  public adultGuestCount = 0;
  public amount = 0;
  public serviceKind = '';

  constructor(private route: ActivatedRoute, private router: Router, private guestService: GuestService,
    private serviceService: ServiceService, private userService: UserService) {
    // private authenticationStateService: AuthenticationStateService) {
    //this.campaigns = [];
    //this.userDTO = null;
  }

  ngOnInit() {


    //let propertyGuid = '92b94e93-339f-4f0a-b4f8-26a54c3e4d24';
    let propertyGuid = this.userService.getProperty();

    let serviceIdParam = this.route.snapshot.queryParamMap.get("id");
  //  serviceIdParam = 'ddb5415c-1724-4792-ac83-85252736dfa2';

    if (serviceIdParam !== '') {
      this.serviceService.getById(propertyGuid, serviceIdParam).subscribe(res => {
        debugger;
        if (res.Success && res.Services !== null && res.Services.length > 0) {
          this.roomNo = res.Services[0].Room.Number;
          this.startDateTime = res.Services[0].StartDateTime;
          this.endDateTime = res.Services[0].EndDateTime;
          this.adultGuestCount = res.Services[0].AdultGuestCount + res.Services[0].SchoolChildGuestCount;
          this.amount = res.Services[0].OriginalAmount = res.Services[0].DiscountAmount;
          //this.serviceKind = res.Services[0].AdditionalServices[].ServiceKind.Name;
          let additionalServices = res.Services[0].AdditionalServices.map(item => {
            return item.ServiceKind.Name;
          });
          this.serviceKind = additionalServices.join();
          //Pobyt: Services[].AdditionalServices[].ServiceKind.Name(należy złączyć, oddzielając przecinkami)
        }
      })
    }

  }
}
