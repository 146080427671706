<div class="reservation-form">
  <app-header></app-header>
  <div class="main-page">

    <app-modal id="company-info-modal">
      <p class="close"><a (click)="closeModal('company-info-modal')"><img
            src="../../../../assets/icon-close-two.svg"></a></p>
      <h3 class="name pr-5 mr-3">{{ company?.Name }}<span>Informacje o
          firmie</span></h3>
      <h3>{{ company?.NIP}}</h3>
      <div class="description">
        <h4>Ulica</h4>
        {{ company?.Address?.Street}}
        <h4>Miejscowość</h4>
        {{ company?.Address?.PostalCode}} {{ company?.Address?.City}}
        <h4>Kraj</h4>
        {{ company?.Address?.Country?.Name}}
      </div>
      <div class="description">
        <h4>Numery telefonów</h4>
        <div *ngFor="let item of company?.PhoneNumbers">
          {{ item.Number }} ({{ item.Kind }})
        </div>
      </div>
      <div class="description">
        <h4>Adresy email</h4>
        <div *ngFor="let item of company?.EmailAddresses">
          {{ item.Email }} ({{ item.Kind }})
        </div>
      </div>
      <!--<div class="date">
        <h4>Data wydarzenia</h4>
        <ul>
          <li><img src="../../../../assets/icon-calendar.svg" alt="Placeholder"> 12/10/2020</li>
          <li><img src="../../../../assets/icon-clock.svg" alt="Placeholder"> 10:00 - 18.00</li>
          <li class="a"><img src="../../../../assets/icon-user2.svg" alt="Placeholder"> Ilość osób <span>121</span></li>
        </ul>
      </div>
      <div class="description">
        <h4>Opis</h4>
        <p>teatralnie, flipchart, rzutnik, HDMI, 2 przerwy kawowe - uzupelnienie</p>
      </div>
      <div class="price">
        <p class="name"><img src="../../../../assets/icon-card.svg" alt="Placeholder"> Planowany<br> przychód</p>
        <p>1500,00 <span>Pln</span></p>
      </div>
      <div class="info">
        <div>
          <h4>Organizator:</h4>
          <p>PMS</p>
          <p>+48 612 345 675</p>
        </div>
        <div>
          <h4>Os. odpowiedzialna:</h4>
          <p>login HTLL</p>
          <p>+48 612 345 675</p>
        </div>
      </div>-->
    </app-modal>



    <section class="section-page-mobile">

      <div class="section-reservation-mobile-top">
        <div class="container-mobile-2">

          <div class="section-reservation-mobile-header-icon-1">
            <a (click)="goBack()"><img class="header-page-user" src="../../../../assets/left.png" alt="" title=""></a>
          </div>
          <div class="section-reservation-mobile-header-icon">
            <h1 class="section-reservation-mobile-header-1">Firmy</h1>
          </div>
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showInfo('')"><img class="header-page-user" src="../../../../assets/icon-info.svg" alt=""
                title=""></a>
          </div>

        </div>

        <!-- top bar search box -->
        <div class="container-mobile">
          <div class="window-mobile-page-content-main">
            <div class="page-mobile-container">

              <div class="page-mobile-container-section-light searcher">
                <a>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.33 23.33">
                    <defs>
                      <style>
                        .cls-1 {
                          fill: none;
                          stroke: #bdb9c0;
                          stroke-linecap: round;
                          stroke-linejoin: round;
                          stroke-width: 2px;
                        }
                      </style>
                    </defs>
                    <g id="Warstwa_2" data-name="Warstwa 2">
                      <g id="Warstwa_1-2" data-name="Warstwa 1">
                        <g id="Group_164" data-name="Group 164">
                          <circle id="Ellipse_35" data-name="Ellipse 35" class="cls-1" cx="10.28" cy="10.28" r="9.28" />
                          <line id="Line_85" data-name="Line 85" class="cls-1" x1="22.33" y1="22.33" x2="16.83"
                            y2="16.83" />
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
                <input style="position: sticky; top: 0;" type="text" [(ngModel)]="companyName" placeholder="Wyszukaj"
                  #ctrl="ngModel" (keydown.enter)="filterCompanies()" />
              </div>

            </div>
          </div>
        </div>
        <!-- END top bar search box -->



      </div>
      <!--<div class="section-reservation-mobile-top">
        <div class="container-mobile-2 day">
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showPreviousDay()"><img class="header-page-user" src="assets/prev.png" alt="" title=""></a>
          </div>
          <div class="section-reservation-mobile-header-icon">
            <h1 class="section-reservation-mobile-header-1">
              <span>{{ currentDayName }}, </span>
              <mat-form-field [formGroup]="rangeDate" (click)="picker.open()">
                <input matInput formControlName="start" [matDatepicker]="picker" (dateChange)="setRangeFrom()" disabled>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker disabled="false"></mat-datepicker>
                <mat-error *ngIf="rangeDate.controls.start.hasError('matStartDateInvalid')">Invalid start date
                </mat-error>
              </mat-form-field>
            </h1>
          </div>
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showNextDay()"><img class="header-page-user" src="../../../../assets/icon_06.png" alt=""
                title=""></a>
          </div>
        </div>
      </div>-->

      <div class="section-page-mobile-content" (scroll)="showTopBtn()">
        <div class="container-mobile">
          <div class="window-mobile-page-content-main">
            <div class="page-mobile-container" #top>

              <div *ngFor="let company of companies" (click)="showCompanyInfo('company-info-modal', company.Guid)"
                class="page-mobile-container-section-dark-rev flex">
                <div class="col70">{{ company.Name }}</div>
                <div class="col30 right">
                  <a><img src="../../../../assets/icon_18.png"></a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </section>

  </div>
  <app-footer></app-footer>
</div>
