import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

import { AppConfig } from '../config/app.config';

import { TokenAuthService } from '../shared/token-auth.service';

import { CompanyDTO } from '../dto/company.DTO';
import { AdrRespDTO } from '../dto/adr.resp.DTO';
import { ReservationRespDTO } from '../dto/reservation-resp.DTO';


@Injectable({
  providedIn: 'root'
})

export class ReservationService {

  constructor(private http: HttpClient, private datePipe: DatePipe, private tokenAuthService: TokenAuthService) { }

  //get(propertyGuid: string): Observable<AdrRespDTO> {
  get(propertyGuid: string): Observable<any> {

   // https://info.elliteq.pl:23088/hotellion.API/REST/v1/Reservation/92b94e93-339f-4f0a-b4f8-26a54c3e4d24?searchFilters.serviceResourceKinds=Accommodation&searchFilters.serviceResourceKinds=ExtraBed&searchFilters.serviceResourceKinds=ExtraPerson&searchFilters.serviceMinDateTime=2021-10-27&searchFilters.serviceMaxDateTime=2021-10-27

    return this.http.get<any>(`${AppConfig.apiUrl}/Reservation/${propertyGuid}?searchFilters.serviceResourceKinds=Accommodation&searchFilters.serviceResourceKinds=ExtraBed&searchFilters.serviceResourceKinds=ExtraPerson&searchFilters.serviceMinDateTime=2021-10-27&searchFilters.serviceMaxDateTime=2021-10-27`);
  }

//  Metoda v1 / Reservation / Service / ConferenceRoom / { propertyGuid } ? searchFilters.serviceMinDateTime = { dateFrom } & searchFilters.serviceMaxDateTime={ dateTo } zwraca listę rezerwacji z usługami konferencyjnymi.
  getConferenceRoom(propertyGuid: string, dateFrom: Date, dateTo: Date): Observable<ReservationRespDTO> {
    debugger;
    let dateFromStr = this.datePipe.transform(dateFrom, 'yyyy-MM-dd');
    let dateToStr = this.datePipe.transform(dateTo, 'yyyy-MM-dd');

    return this.http.get<ReservationRespDTO>(`${AppConfig.apiUrl}/Reservation/Service/ConferenceRoom/${propertyGuid}?searchFilters.serviceMinDateTime=${dateFromStr}&searchFilters.serviceMaxDateTime=${dateToStr}`);
  }

}
