<div class="reservation-form">
  <app-header></app-header>
  <div class="main-page">

    <app-modal id="conference-info-modal">
      <p class="close">
        <a (click)="closeModal('conference-info-modal')">
          <img src="../../../../assets/icon-close-two.svg">
        </a>
      </p>
      <h3 *ngIf="conference?.ReservingCompany !== '' && conference?.ReservingCompany != null">
        {{
        conference?.ReservingCompany
        }}
      </h3>
      <h3 *ngIf="conference?.ReservingCompany === '' && conference?.ReservingCompany == null">
        {{
        conference?.ReservingPerson
        }}
      </h3>
      <h3>
        <span>Konferencja /</span> {{ conference?.ServiceDTO.ConferenceRoom?.Name }} {{
        conference?.ServiceDTO.ConferenceRoom?.VariantName
        }}
      </h3>
      <div class="date">
        <h4>Data wydarzenia</h4>
        <ul>
          <li>
            <img src="../../../../assets/icon-calendar.svg" alt="Placeholder"> {{
            dateTimeFormatToDate(conference?.ServiceDTO.StartDateTime)
            }}
          </li>
          <li>
            <img src="../../../../assets/icon-clock.svg" alt="Placeholder">{{
            dateTimeFormatToTime(conference?.ServiceDTO.StartDateTime)
            }} - {{
            dateTimeFormatToTime(conference?.ServiceDTO.EndDateTime)
            }}
          </li>
          <li class="a">
            <img src="../../../../assets/icon-user2.svg" alt="Placeholder"> Ilość osób <span>
              {{
              conference?.AdultGuestCount
              }}
            </span>
          </li>
        </ul>
      </div>
      <div class="description">
        <h4>Opis</h4>
        <p>{{ conference?.ServiceDTO.Details }}</p>
      </div>
      <div class="price">
        <p class="name"><img src="../../../../assets/icon-card.svg" alt="Placeholder"> Planowany<br> przychód</p>
        <p>{{ conference?.ServiceDTO.OriginalAmount }} <span>Pln</span></p>
      </div>
      <div class="info">
        <div>
          <h4>Organizator:</h4>
          <p *ngIf="conference?.ReservationDTO?.ReservingPerson !== null">
            {{
            conference?.ReservationDTO?.ReservingPerson?.LastName
            }} {{
            conference?.ReservationDTO?.ReservingPerson?.FirstName
            }}
          </p>
          <p *ngIf="conference?.ReservationDTO?.ReservingCompany !== null">
            {{
            conference?.ReservationDTO?.ReservingCompany?.Name
            }}
          </p>
          <p *ngIf="conference?.ReservationDTO?.ReservingPerson.PhoneNumbers !== null && conference?.ReservationDTO?.ReservingPerson.PhoneNumbers.length > 0">
            {{ conference?.ReservationDTO?.ReservingPerson.PhoneNumbers[0].Number }}
          </p>
          <p *ngIf="conference?.ReservationDTO?.ReservingCompany.PhoneNumbers !== null && conference?.ReservationDTO?.ReservingCompany.PhoneNumbers.length > 0">
            {{ conference?.ReservationDTO?.ReservingCompany.PhoneNumbers[0].Number }}
          </p>
        </div>
        <div>
          <h4>Os. odpowiedzialna:</h4>
          <p>
            {{ conference?.ReservationDTO?.ResponsibleUser?.LastName }} {{
            conference?.ReservationDTO?.ResponsibleUser?.FirstName
            }}
          </p>
          <p>{{ conference?.ReservationDTO?.ResponsibleUser?.PhoneNumber }}</p>
        </div>
      </div>

    </app-modal>

    <section class="section-page-mobile">
      <div class="section-reservation-mobile-top">
        <div class="container-mobile-2">
          <div class="section-reservation-mobile-header-icon-1">
            <a (click)="goBack()"><img class="header-page-user" src="../../../assets/left.png" alt="" title=""></a>
          </div>
          <div class="section-reservation-mobile-header-icon">
            <h1 class="section-reservation-mobile-header-1">Konferencje</h1>
          </div>
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showInfo('')">
              <img class="header-page-user" src="../../../assets/icon-info.svg" alt=""
                   title="">
            </a>
          </div>
        </div>
      </div>
      <div class="section-reservation-mobile-top">
        <div class="container-mobile-2 day">
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showPreviousDay()"><img class="header-page-user" src="assets/prev.png" alt="" title=""></a>
          </div>
          <!--<div class="section-reservation-mobile-header-icon-1">
            <a (click)="showCalendar()"><img class="header-page-user" src="../../../assets/icon_03.png" alt="" title=""></a>
          </div>-->
          <div class="section-reservation-mobile-header-icon">
            <h1 class="section-reservation-mobile-header-1">
              <span class="d-block">
                {{
 currentDayName
                }},
              </span>
              <div class="d-flex">
                <mat-form-field [formGroup]="rangeDate" (click)="picker.open()">
                  <input matInput formControlName="start" [matDatepicker]="picker" (dateChange)="setRangeFrom()"
                         disabled>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker disabled="false"></mat-datepicker>
                  <mat-error *ngIf="rangeDate.controls.start.hasError('matStartDateInvalid')">
                    Invalid start date
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- <input type="date" class="section-reservation-mobile-input" [(ngModel)]="dateFromFilter" value="{{ dateFromFilter | date:'yyyy-MM-dd' }}" #ctrl="ngModel" required (ngModelChange)="changeDateFrom()" /> -->
            </h1>
          </div>
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showNextDay()">
              <img class="header-page-user" src="../../../assets/icon_06.png" alt=""
                   title="">
            </a>
          </div>
        </div>
      </div>

      <div class="section-page-mobile-content">
        <div class="container-mobile">
          <div class="window-mobile-page-content-main">
            <div class="page-mobile-container">

              <div class="page-mobile-container-section-dark-rev conference"
                   (click)="showConferenceInfo('conference-info-modal', item.ConferenceIndex)"
                   *ngFor="let item of conferences">
                <div class="conference__room">{{ item.ConferenceRoom }}</div>
                <div class="conference__booker" *ngIf="item.ReservingCompany && item.ReservingCompany !== ''"
                     title="{{ item.ReservingCompany }}">{{ item.ReservingCompany }}</div>
                <div class="conference__booker" *ngIf="item.ReservingPerson && item.ReservingPerson !== ''"
                     title="{{ item.ReservingPerson }}">{{ item.ReservingPerson }}</div>
                <div class="conference__peoples">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.37 18.58">
                    <defs>
                      <style>
                        .cls-1 {
                          fill: none;
                          stroke: #fdfdfa;
                          stroke-linecap: round;
                          stroke-linejoin: round;
                          stroke-width: 2px;
                        }
                      </style>
                    </defs>
                    <g id="Warstwa_2" data-name="Warstwa 2">
                      <g id="Warstwa_1-2" data-name="Warstwa 1">
                        <g id="Group_172" data-name="Group 172">
                          <circle id="Ellipse_25" data-name="Ellipse 25" class="cls-1" cx="8.18" cy="5.42" r="4.42" />
                          <path id="Path_110" data-name="Path 110" class="cls-1"
                                d="M1,17.58c0-4.42,1.1-7.74,5.68-7.74h3c4.57,0,5.68,3.32,5.68,7.74" />
                        </g>
                      </g>
                    </g>
                  </svg> {{ item.AdultGuestCount }}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </section>

  </div>
  <app-footer></app-footer>
</div>