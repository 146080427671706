<div class="reservation-form">
  <app-header></app-header>
  <div class="main-page">

    <section class="section-page-mobile">
      <div class="section-reservation-mobile-top">
        <div class="container-mobile-2 day">
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showPreviousDay()"><img class="header-page-user" src="assets/prev.png" alt="" title=""></a>
          </div>
          <!--<div class="section-reservation-mobile-header-icon-1">
            <a ><img class="header-page-user" src="assets/icon_03.png" alt="" title=""></a>
          </div>-->
          <div class="section-reservation-mobile-header-icon">
            <h1 class="section-reservation-mobile-header-1">
              <div class="d-flex flex-column">
                <span class="d-block">{{ currentDayName }}, </span>
                <div class="d-flex">
                  <mat-form-field [formGroup]="rangeDate" (click)="picker.open()">
                    <input matInput formControlName="start" [matDatepicker]="picker" (dateChange)="setRangeFrom()"
                      disabled>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                    <mat-error *ngIf="rangeDate.controls.start.hasError('matStartDateInvalid')">Invalid start date
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <!-- <input type="date" [(ngModel)]="dateFromFilter" class="section-reservation-mobile-input" value="{{ dateFromFilter | date:'yyyy-MM-dd' }}" #ctrl="ngModel" required (ngModelChange)="changeDateFrom()" /> -->
            </h1>
          </div>
          <div class="section-reservation-mobile-header-icon-2">
            <a (click)="showNextDay()"><img class="header-page-user" src="assets/icon_06.png" alt="" title=""></a>
          </div>
        </div>
      </div>

      <div class="section-reservation-mobile-tops">
        <div class="container-mobile-2 page-mobile-container-section-light-oc">
          <button class="button-as-container" (click)="showOccupancy()">
            <div class="section-reservation-mobile-header-icon-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.794" height="28.23" viewBox="0 0 26.794 28.23">
                <path id="Path_10" data-name="Path 10"
                  d="M264.2-78.22a11.892,11.892,0,0,0-7.559-11.069,7.739,7.739,0,0,0,3.407-6.416,7.745,7.745,0,0,0-7.745-7.745,7.745,7.745,0,0,0-7.745,7.745,7.737,7.737,0,0,0,3.407,6.416A11.892,11.892,0,0,0,240.4-78.22"
                  transform="translate(-238.901 104.95)" fill="none" stroke="#c2bfc5" stroke-linecap="round"
                  stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" />
              </svg>
            </div>
            <div class="section-reservation-mobile-header-icon short">
              <h1 class="section-reservation-mobile-header-1">Obłożenie<span> {{
                  formatPercentageValue(occupiedPercentage) }}% - {{ accommodationsCount }}/{{ availableRoomsCount
                  }}</span></h1>
            </div>
          </button>
        </div>
      </div>


      <div class="section-page-mobile-content">
        <div class="container-mobile">
          <div class="window-mobile-page-content-main">



            <div class="page-mobile-container container">

              <!-- hotel status -->

              <div class="row">
                <h2 class="section-reservation-mobile-header">Hotel status</h2>
              </div>

              <div class="row">

                <div
                  class="col-12 mb-1 page-mobile-container-section-light d-flex align-content-center justify-content-between p-3"
                  style="background-color: #f6f6f6; border-radius: 10px;">
                  <div class="col70">Przyjazdy</div>
                  <div class="col30">{{ checkins }}</div>
                </div>

                <div
                  class="col-12 mb-1 page-mobile-container-section-light d-flex align-content-center justify-content-between p-3"
                  style="background-color: #f6f6f6; border-radius: 10px;">
                  <div class="col70">Wyjazdy</div>
                  <div class="col30">{{ checkouts }}</div>
                </div>

                <div
                  class="col-12 mb-1 page-mobile-container-section-light d-flex align-content-center justify-content-between p-3"
                  style="background-color: #f6f6f6; border-radius: 10px;">
                  <div class="col70">Pobyty</div>
                  <div class="col30">{{ inHotelGuestsCount }}</div>
                </div>
              </div>

              <div class="row mt-2 d-flex align-items-center mb-4">
                <div class="col">
                  <h2 class="m-0 p-0">Konferencje</h2>
                </div>
                <div class="col">
                  <button class="show-more-btn mt-1" (click)="showConferences()">
                    Pokaż więcej
                  </button>
                </div>
              </div>


              <div class="row">


                <div class="col-12 mb-1 page-mobile-container-section-lights p-3"
                  style="background-color: #f6f6f6; border-radius: 10px;" *ngFor="let item of conferences">
                  <div style="display: flex; width: 100%; ">
                    <p style="display: inline; ">{{ item.ConferenceRoom }}<br></p>
                  </div>
                  <p style="font-weight: 600; display: inline;" *ngIf="item.ReservingCompany !== ''">{{
                    item.ReservingCompany }}<br></p>
                  <p *ngIf="item.ReservingPerson !== ''">{{ item.ReservingPerson }}</p>
                  <p>Liczba gości: {{ item.AdultGuestCount }}</p>
                </div>



              </div>

            </div>


            <div class="page-mobile-container flex">
              <div class="page-mobile-container-col2 ">
                <div class="page-mobile-container page-mobile-container-section-dark my-tile my-tile--only-title button"
                  (click)="showHouseKeeping()">
                  <div class="page-mobile-container-col">
                    <img src="assets/icon_21.png">
                  </div>
                  <div>
                    <h2 class="page-mobile-container-section-dark-header">Status pokoi</h2>
                  </div>
                </div>
              </div>
              <div class="page-mobile-container-col2 ">

                <div class="page-mobile-container page-mobile-container-section-dark my-tile my-tile--only-title button"
                  (click)="showActivitLog()">
                  <div class="page-mobile-container-col">
                    <img src="assets/icon_21.png">
                  </div>
                  <div>
                    <h2 class="page-mobile-container-section-dark-header">Dziennik działań</h2>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </section>
  </div>
  <app-footer></app-footer>
</div>