import { ReservationDTO } from "./reservation.DTO";
import { ServiceDTO } from "./service.DTO";

export class ConferenceDTO {
  ConferenceRoom: string;
  ReservingCompany: string;
  ReservingPerson: string;
  AdultGuestCount: number;

  ConferenceIndex: number;

  ReservationDTO: ReservationDTO;
  ServiceDTO: ServiceDTO;
}
