import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe, Location } from '@angular/common';
import { DateAdapter } from '@angular/material/core';

//import { CompanyService } from '../../../services/campaign.service';
import { OccupancyService } from '../../services/occupancy.service';

import { OccupancyReportDayDTO } from '../../dto/occupancy-report-day.DTO';
import { BaseComponent } from '../shared/base/base.component';
import { UserService } from '../../services/user.service';
import { FormControl } from '@angular/forms';
import { DescriptionService } from '../../services/description.service';
import { ModalService } from '../../services/modal.service';
import { AuthenticationStateService } from '../../shared/authentication-state.service';

@Component({
  selector: 'app-occupancy',
  templateUrl: './occupancy.component.html',
  styleUrls: ['./occupancy.component.scss']
})

export class OccupancyComponent extends BaseComponent implements OnInit {

  public occupancyReportDays: OccupancyReportDayDTO[] = [];
  public occupancyForPeriod = 0;
  public loading: boolean = false;
  private loadingDelay = null;
  public description: any = null;

  public rangeDate = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  constructor(private route: ActivatedRoute, protected router: Router, protected datePipe: DatePipe,
    protected authenticationStateService: AuthenticationStateService,
    protected userService: UserService, private occupancyService: OccupancyService, protected dateAdapter: DateAdapter<Date>,
    protected location: Location, protected descriptionService: DescriptionService, protected modalService: ModalService) {
    super(router, datePipe, userService, dateAdapter, location, modalService, authenticationStateService)
  }

  ngOnInit() {

    let dateFromParam = this.route.snapshot.queryParamMap.get("dateFrom");
    if (dateFromParam != null && dateFromParam !== '') {
      this.dateFrom = new Date(parseInt(dateFromParam));
      this.setDateTo(this.dateFrom.getDate() + 1);
    }

    super.ngOnInit();
    super.initDate(true);
    this.setDateControl();

    this.loadData();

    this.descriptionService.get().subscribe(res => {
      this.description = res.Views.OccupancyInfo;
    });
  }

  loadData() {
   // debugger;

    this.occupancyService.get(this.propertyGuid, this.dateFrom, this.dateTo).subscribe(res => {
    //  debugger;
      if (res.Success) {
        this.occupancyReportDays = res.OccupancyReportDays;
        this.occupancyForPeriod = res.OccupancyForPeriod;
      }
    }, error => {
      console.error(error);
    });
  }

  loadDataDelayed() {
    if (this.loadingDelay != null) {
      clearTimeout(this.loadingDelay);
    }

    this.loadingDelay = setTimeout(() => {
      this.loadData();
    }, 1000);
  }

  showNextDay() {
    this.setNextDay();
    this.setDateControl();

    this.loadDataDelayed();
  }

  showPreviousDay() {
   // debugger;
    super.setPreviousDay();
    this.setDateControl();

    this.loadDataDelayed();
  }

  //public changeDateFrom() {
  //  super.changeDateFrom();

  //  this.loadData();
  //}

  //public changeDateTo() {
  //  super.changeDateTo();

  //  this.loadData();
  //}

  public setRangeFrom() {
    let startDate = this.rangeDate.value.start;
    if (startDate != null) {
      this.dateFrom = new Date(startDate);
    }
  }

  public setRangeTo() {
    let endDate = this.rangeDate.value.end;
    if (endDate != null) {
      let newEndDate = new Date(endDate);
      if (newEndDate !== this.dateTo) {
        this.dateTo = newEndDate;
        this.loadData();
      }
    }
  }

  public parseValueKind(valueKind: string) {
    if (valueKind === 'Planned') {
      return 'planowane';
    }
    else if (valueKind === 'Actual') {
      return 'zrealizowane';
    }
    else if (valueKind === 'Unknown') {
      return 'nieznane';
    }
  }

  private setDateControl() {
    this.rangeDate.patchValue({ start: new Date(this.dateFrom.toISOString()) });
    this.rangeDate.patchValue({ end: new Date(this.dateTo.toISOString()) });
  }
}
