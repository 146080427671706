import { Injectable } from '@angular/core';
import { AppConfig } from '../config/app.config';

import { AuthRespDTO } from '../dto/auth.resp.DTO';
import { StorageUserRolesDTO } from '../dto/storage.user-roles.DTO';

@Injectable({
  providedIn: 'root'
})

export class TokenAuthService {

  private tokenIssuer = {
   // login: 'http://127.0.0.1:8082/systemr-api/public/api/auth/signin',
    login: AppConfig.apiUrl + '/Authentication/Token'
   // register: 'http://127.0.0.1:8082/systemr-api/public/api/auth/signup'
  }

  constructor() { }

  setTokenStorage(jwt) { //} token){
   // debugger;
    let jwtJson = JSON.stringify(jwt);
    //localStorage.setItem('jwt', jwtJson);

    this.setWithExpiry('jwt', jwtJson, jwt.expires_in);
    //localStorage.setItem('role_department', jwt.)

    //let userJson = JSON.stringify(jwt.user);
    //localStorage.setItem('auth_user', userJson);
  }

  setUserRolesStorage(userRoles, expires_in) {
    let userRolesJson = JSON.stringify(userRoles);
    //localStorage.setItem('userRoles', userRolesJson);
    this.setWithExpiry('userRoles', userRolesJson, expires_in);
  }

  setProperty(propertyId) {
    localStorage.setItem('propertyId', propertyId);
  }

  getJwtToken() {
   // debugger;
    //let jwt = localStorage.getItem('jwt');
    let jwt = this.getWithExpiry('jwt');
    if (jwt !== null) {
      let authRespDTO = JSON.parse(jwt);
      return authRespDTO;
    }
    return null;
  }

  getJwtUser(): AuthRespDTO {
    //let userJson = localStorage.getItem('jwt');
    let userJson = this.getWithExpiry('jwt');
    if (userJson !== null && userJson !== ''){
      let userDTO = JSON.parse(userJson);
      return userDTO;
    }

    return null;
  }

  getUserRoles(): StorageUserRolesDTO {

   // let userRolesJson = localStorage.getItem('userRoles');
    let userRolesJson = this.getWithExpiry('userRoles');
   // debugger;
    if (userRolesJson !== '' && userRolesJson !== null) {
      let userRolesDTO = JSON.parse(userRolesJson);
      return userRolesDTO;
    }

    return null;
  }

  getPropertyId(): string {
    const propertyId = localStorage.getItem('propertyId');
    return propertyId;
  }

  // Validate token
  validateToken() {

    const token = this.getJwtToken();
    if (token !== null) {
      return true;
    }
    return false;
    //const token = this.getJwtToken();
    //  debugger;
    //if (token) {
    //  const payload = this.payload(token.access_token);
    //  if (payload) {
    //    return Object.values(this.tokenIssuer).indexOf(payload.iss) > -1 ? true : false;
    //  }
    //} else {
    //  return false;
    //}
    //debugger;
    //return true;
  }

  payload(token) {
   // debugger;
    const jwtPayload = token.split('.')[1];
    return JSON.parse(atob(jwtPayload));
  }

  // User state
  isSignedin() {
    return this.validateToken();
  }

  // Destroy token
  destroyToken(){
  //  localStorage.removeItem('auth_token');
    localStorage.removeItem('jwt');
    localStorage.removeItem('userRoles');
    localStorage.removeItem('propertyId');
  }

  setWithExpiry(key, value, ttl) {

    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl * 1000,
    }

    localStorage.setItem(key, JSON.stringify(item))
  }

  getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key)
      return null
    }
    return item.value
  }
}
